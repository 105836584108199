import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import msg from '../../texts/msg';
import './css/MessageDialog.css';
import downloadUploadFilesService from '../../middleware/download-upload-files.service';

class MessageDialog extends Component {

    render() {
        let header = <span>{this.props.title}</span>;
        let footer = (this.props.confirm && this.props.confirm === true) ?
            (
                <div>
                    <Button label={this.props.button_yes ? msg('message.dialog.button.yes') : msg('message.dialog.button.confirm')} icon="pi pi-check" onClick={this.props.onConfirm} />
                    <Button label={this.props.button_no ? msg('message.dialog.button.no') : msg('message.dialog.button.cancel')} icon="pi pi-times" onClick={this.props.onCancel} />
                </div>
            ) :
            (<div><Button label={msg('message.dialog.button.close')} icon="pi pi-check" onClick={this.props.onHide} /></div>);
        return (
        <Dialog className={this.props.className} visible={this.props.visible} modal={false} closable={false}
                onHide={()=>{}} header={header} footer={footer}>
                <h3>{msg(this.props.message, this.props.messageParams)}</h3>               
                {this.props.messagesArray ? this.props.messagesArray.map(e => <h4>{e}</h4>): ''}
                {this.props.loadFile &&
                 <p>    
                    <em style = {{ fontSize:'16px'}}>{msg('rejected.users.download.file.label')}</em>
                    <input id="image" type="image" src={"/assets/ultima/layout/images/cartadis/excel.svg"} 
                        style={{ marginBottom: '-5px', height: '25px' }}
                        alt={msg('event.type.target')}
                        onClick={() => {
                            let userFilter = {};
                            userFilter.date = this.props.lastChangeDateFile;
                            let filename =  msg('rejected.users.import.filename') + '.xlsx';
                            let exportUrl =  "server/download-rejected-users-xlsx/" + this.props.lastChangeDateFile;                        
                            downloadUploadFilesService.getFileData(exportUrl, filename, userFilter);}}/>
                </p>
                }               
        </Dialog>
        );
    }
}

export default MessageDialog;