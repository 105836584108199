import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const BlueTooltip = withStyles({
    arrow: { color: 'rgb(75, 153, 211)' },
    tooltip: { backgroundColor: 'rgb(75, 153, 211)', zIndex: 99 }
})(Tooltip);
export const DarkBlueTooltip = withStyles({
    arrow: { color: 'rgb(35, 68, 148)' },
    tooltip: { backgroundColor: 'rgb(35, 68, 148)', zIndex: 99 }
})(Tooltip);
