import React, { Component } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import MapContainer from '../../common/MapContainer';
import { formatDateTime } from '../../../utils/dateTimeUtils';
import msg from '../../../texts/msg';
import { progressSpinnerHandler } from '../../../utils/ProgressSpinnerHandler.js';

class Dashboard extends Component {
    componentDidMount() {
        this.props.load();
        progressSpinnerHandler.show(false);
    }

    render() {
        return (
            <div className="p-grid p-fluid maint-dashboard">
                <DashboardPanel bornesStatus={this.props.data} stationsMap={this.props.stationsMap} load={this.props.load} />
            </div>
        );
    }

}

class DashboardItemHeader extends Component {
    render() {
        return (
            <div className="p-col-12 p-grid refresh">
                <div className="p-col-10 refresh-time">
                    <span className="time">{msg('maintenance.borne.status.refrechtime')} {this.props.refrechTime}</span>
                </div>
                <div className="p-col-2 refresh-button"><Button icon="pi-md-refresh" onClick={this.props.load} /></div>
            </div>
        )
    }
}

class DashboardItem extends Component {
    render() {
        return (
            <div className="p-col-12 maint-status-info">
                <div className={"p-col-12 p-grid " + this.props.itemClassName}>
                    <div className="p-col-4 borne-icone">
                        <span className="pi pi-md-dock"></span>
                    </div>
                    <div className="p-col-8 p-grid borne-info">
                        <div className="p-col-12 label"><h2>{this.props.label}</h2></div>
                        <div className="p-col-12 value"><h1><Link to={"/borne-status/" + this.props.linkParamKey}>{this.props.value}</Link></h1></div>
                    </div>
                </div>
            </div>
        )
    }
}

class DashboardPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.onRedirect = this.onRedirect.bind(this);
    }

    onRedirect(stationId) {
        this.setState({redirect : true, stationId: stationId});
    }

    getMapHeight() {
        let k = 0.45;       
        if( window.innerHeight > 900 && window.innerHeight <= 1000 ){
            k = 0.50;
        } else if( window.innerHeight > 800 && window.innerHeight <= 900 ){
            k = 0.60;
        } else if( window.innerHeight > 700 && window.innerHeight <= 800 ){
            k = 0.65;
        } else if( window.innerHeight <= 700 ){
            k = 0.80;
        }
        return (window.innerHeight * k) + 'px';
    }

    render() {
        if(this.state.redirect){
           return <Redirect to={'/station-map/' + this.state.stationId} push={true}/>;
         }
        let bornesStatus = this.props.bornesStatus;
        let stationsMap = this.props.stationsMap ? this.props.stationsMap.stationMaps : null;
        let coordinateDefaultMaps = this.props.stationsMap ? this.props.stationsMap.coordinateDefaultMaps : null;
        let height = this.getMapHeight();
        return (
            <div className="p-grid form-group p-col-12">
                <div className="p-col-4 p-sm-12 p-md-4 p-lg-4 p-large-screen p-smal-screen p-extra-small-screen maint-status" style={{minHeight: height}}>
                    <Fieldset className="p-col-12" legend={msg("maintenance.borne.status.legend.today")} toggleable={true}>
                        <div className="p-grid dashboard-panel">
                            <DashboardItemHeader load={this.props.load} refrechTime={formatDateTime(bornesStatus.refrechTime).time} />
                            <DashboardItem label={msg('maintenance.borne.status.label.hs')} value={bornesStatus.errors} itemClassName={"borne-hs"} linkParamKey={'error'} />
                            <DashboardItem label={msg('maintenance.borne.status.label.degraded')} value={bornesStatus.majorWarnings} itemClassName={"borne-degraded"} linkParamKey={'major_warning'} />
                            <DashboardItem label={msg('maintenance.borne.status.label.ok')} value={bornesStatus.runnings + bornesStatus.minorWarnings} itemClassName={"borne-ok"} linkParamKey={'info'} />
                        </div>
                    </Fieldset>
                </div>
                <div className="p-col-8 p-sm-12 p-md-8 p-lg-8" style={{minHeight: height}}>                    
                    <MapContainer stationsMap={stationsMap} coordinateDefaultMaps={coordinateDefaultMaps} onRedirect={this.onRedirect} width={'100%'} height={height} />
                </div>
            </div>
        );
    }
}

export default withRouter(Dashboard);