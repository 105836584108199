import { React,Component } from "react";
import { Chart } from 'primereact/chart';
import msg from "../../texts/msg";


export default class CommonAnalysisChart extends Component {

    getTypeOfPaiment(type) {
		let s = type.trim();
		let m = s;
		switch (s) {
			case 'PAID_CODE':
			case 'PAID_APP_CODE':
				m = msg('transaction.meanOfPayment.type.paid.code');
				break;
			case 'FREE_CODE':
				m = msg('transaction.meanOfPayment.type.free.code');
				break;
			case 'CASH':
				m = msg('transaction.meanOfPayment.type.cash');
				break;
			case 'LICENSE_PLATE':
				m = msg('transaction.meanOfPayment.type.license.plate');
				break;
			case 'MAINTENANCE':
				m = msg('transaction.meanOfPayment.type.maintenance');
				break;
			default:
				m = msg(s);
		}
		return m;
	}

    converterLabelLigend(label, value) {
		var width = (window.innerWidth);
		var sizelabel = 16 - value.length;
		if (label.length + value.length > 15 && width <= 1366) {
			return label = label.substring(0, sizelabel);
		} else return label;
    }
    
    render() {

        let options = {
			title: {display: this.props.title ? true : false, text: this.props.title},
			legend: {display: false, position: 'bottom', align: 'start', labels: {}},
			tooltips: {
				callbacks: {
					label: (tooltipItem, data) => (" " + this.getTypeOfPaiment(data.labels[tooltipItem.index].label) + " " + data.labels[tooltipItem.index].value)
				}
			},
			animation: {duration: 0},
			events: ['mousemove']
        };
        let className = this.props.className ? this.props.className : '';
		return (            
			<div className={className}>
				{<Chart type={this.props.type} data={this.props.chartModel} options={options} width="180" />}
				{this.props.chartModel.datasets.length && this.props.chartModel.datasets.map((item, index) => {
					const labels = this.props.chartModel.labels;
					const backgroundColor = item.backgroundColor;
					const hoverBackgroundColor = item.hoverBackgroundColor;
					let children = [];
					for (let j = labels.length; j < 6; j++) {
						children.push(
						<div key={j + 6} className="p-col-6 legend">
							<div className="chart-labels-legend" 
								style={{ backgroundColor: 'transparent', hoverBackgroundColor: 'transparent'}}/>
							<span className="labels-legend-item"/>
						</div>);
					}
					return (
						<div key={index} className="p-grid p-col-12 containerLegend"> 
						{labels.map((itemLabels, index) => {
							return (
								<div key={index} className="p-col-6 legend">
									<div className="chart-labels-legend"
										style={{
											backgroundColor: backgroundColor[index] ? backgroundColor[index] : " ",
											hoverBackgroundColor: hoverBackgroundColor[index] ? hoverBackgroundColor[index] : ""
										}}/>
									<span className="labels-legend-item">
										{this.converterLabelLigend(this.getTypeOfPaiment(itemLabels.label), itemLabels.value)}
									</span>
									<span className="labels-legend-item" style={{paddingLeft:'5px'}}>{itemLabels.value}</span>
								</div>
							)})}
							{children}
						</div>
					);
				})}
			</div>
		);
	}
}