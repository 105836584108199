import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import TableData from '../../../utils/TableData';
import { toLocaleDateTimeFormat } from '../../../utils/dateTimeUtils';
import UploadService from "../../../middleware/download-upload-files.service";
import { rolesValidator, FIRMWARE, CREATE, READ, UPDATE, DELETE } from '../../../utils/RolesValidator';
import { userSessionParamsHandler } from '../../../utils/UserSessionParamsHandler';
import msg from '../../../texts/msg';
import MessageDialog from '../../common/MessageDialog';
import '../../common/css/common.css';
import { progressSpinnerHandler } from '../../../utils/ProgressSpinnerHandler.js';

export class Firmware extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first: 0,
            buttonSelectd: false,
            display: false,
            closable: false,
            isLignSelected: false,
            filter: {},
            sortDirection: "ASC",
            searchObject: { idSearch: null, idValid: false },
            keyword: "",
        }
        this.onSelection = this.onSelection.bind(this);
        this.loadFirmwares = this.loadFirmwares.bind(this);
        this.update = this.update.bind(this);
        this.deleteFirmware = this.deleteFirmware.bind(this);
        this.onSort = this.onSort.bind(this);
        this.searchByKeyword = this.searchByKeyword.bind(this);
    }

    componentDidMount() {
        progressSpinnerHandler.show(true); 
        const page = 0;
        const pageSize = userSessionParamsHandler.getPageSize();
        this.loadFirmwares(page, pageSize);
        this.props.setDataInfoBar(null);
    }

    componentDidUpdate(prevProps) {
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            progressSpinnerHandler.show(false);
            let isError = this.props.error.message && this.props.error.message.startsWith('error.config.borne.') ? true : false;
            this.setState({ isError: isError, message: this.props.error.message});
        }
        if(this.props.firmwares  && this.props.firmwares  !== prevProps.firmwares){
            progressSpinnerHandler.show(false);
        }
        let prevDelete = prevProps.firmwareDeleteById && prevProps.firmwareDeleteById.lastChange;
        let lastDelete = this.props.firmwareDeleteById && this.props.firmwareDeleteById.lastChange;
        let reload = (lastDelete && (!prevDelete || prevDelete < lastDelete));
        if (reload) {
            const page = 0;
            const sizePage = userSessionParamsHandler.getPageSize();
            this.loadFirmwares(page, sizePage);
        }
    }

    onSelection(event) {
        if (event.value) {
            this.setState({ firmwareId: event.value.id });
            this.setState({ firmwareName: event.value.name });
            this.setState({ borneType: event.value.borne });
            this.setState({ config: event.value.config });
            this.setState({ uploadDate: event.value.uploadDate });
            this.setState({ sendingDate: event.value.sendingDate });
            this.setState({ buttonSelectd: true });
        }
    }

    loadFirmwares(page, sizePage) {
        progressSpinnerHandler.show(true); 
        this.props.loadFirmwares(page, sizePage, this.state.filter);
    }

    borneTypeBody(row, column) {
        return row.name ? row.name.split('_')[0] : "";
    }

    onFileChange = event => {
        this.setState({ selectedFile: event.files[0] });
        this.setState({ name: event.files[0].name });
        this.setState({ progress: 0 });

        let name = event.files[0].name ? event.files[0].name.toUpperCase().split('_') : "";
        if (name.length > 1 && (name.includes('TL16') || name.includes('TL17') || name.includes('TL18') || name.includes('TK2'))) {
            var yourRegex = /^[0-9]+$/g
            var atLeastOneMatches = name[1].split('.').some(e => yourRegex.test(e));
            if (atLeastOneMatches) {
                this.setState({ isNameValid: false });
            }
        } else {
            this.setState({ isNameValid: true });
            this.setState({ name: event.files[0].name });
        }
    };

    onFileUpload = () => {
        let valid = true;
        let currentFile = this.state.selectedFile;
        this.setState({
            progress: 0,
            currentFile: currentFile,
        });

        if (!this.state.selectedFile) {
            this.setState({ isNotFile: true });
            valid = false;
        } else {
            this.setState({ isNotFile: false });
        }

        if (valid)
            UploadService.upload(currentFile, this.state.description, null, null, (event) => {
                this.setState({ progress: Math.round((100 * event.loaded) / event.total), });
            }).then((response) => {
                this.setState({ currentFile: undefined, description: '' });
                this.setState({ isDone: true });
                this.setState({ progress: 0, currentFile: null });
            }).catch((error) => {
                if (!error.response) {
                    return;
                }
                if (error.response.status === "401" || error.response.status === 401) {
                    this.setState({ sessionExpired: true });
                } else {
                    this.setState({ isError: true, isPatternError: true, message: error.response.data.message });
                    this.setState({ progress: 0, currentFile: null });
                }
            });
    };

    uploadDateBody(row, column) {
        return (<span>{toLocaleDateTimeFormat(row.uploadDate)}</span>);
    }

    comment(rowData) {
        if (rowData.description) {
            let description = rowData.description ? rowData.description : "";
            if (description.length > 50)
                return <div className="hasTooltip p-ellipsis"> {description} <span> {description}  </span></div>;
            else
                return <div> {description} </div>
        }
    }

    deleteFirmware() {
        if (this.state.firmwareId) {
            if (this.state.config) {
                this.setState({ isConfig: true });
            } else {
                this.setState({ showConfirm: true });
            }
        } else {
            this.setState({ isLignSelected: true });
        }
    }

    confirmDeleteFirmware() {
        if (this.state.firmwareId) {
            this.props.deleteFirmware(this.state.firmwareId);
            this.setState({ buttonSelectd: false });
        } else {
            this.setState({ isLignSelected: true });
        }
        this.setState({ buttonSelectd: false });
    }

    update() {
        if (this.state.firmwareId && this.state.buttonSelectd) {
            this.props.loadFirmware(this.state.firmwareId);
            this.setState({ buttonSelectd: false });
            this.props.history.push('/firmware/update');
        } else {
            this.setState({ isLignSelected: true });
        }
    }

    resetMessage(e) {
        this.setState({ isDone: false, isError: false, selectedFile: null, message: null, isConfig: false })
        this.setState({ currentFile: undefined });
        if (this.state.sessionExpired) {
            this.setState({ sessionExpired: false });
            this.props.history.push('/');
            this.props.history.push('/firmwares');
        }
    }

    onSort = (e, page) => {
        progressSpinnerHandler.show(true); 
        let pageSize = userSessionParamsHandler.getPageSize()
        let filter = Object.assign({}, this.state.filter);
        let sortDirection = this.state.sortDirection === "ASC" ? "DESC" : "ASC";
        filter.sortProperty = { key: e.sortField, value: sortDirection };
        this.props.loadFirmwares(page, pageSize, filter);
        this.setState({ sortDirection: sortDirection });
        this.setState({ filter: filter });
    }

    searchByKeyword(searchObject) {
        let lFilter = Object.assign({}, this.state.filter);
        this.setState({ searchObject: searchObject })
        let keyword = searchObject.idSearch ? searchObject.idSearch : this.state.keyword;
        lFilter.label = keyword;
        this.props.loadFirmwares(0, userSessionParamsHandler.getPageSize(), lFilter)
    }

    render() {

        let authorized = rolesValidator.validate([FIRMWARE]);
        let cAuthorized = authorized.toString().includes(CREATE);
        let rAuthorized = authorized.toString().includes(READ);
        let uAuthorized = authorized.toString().includes(UPDATE);
        let dAuthorized = authorized.toString().includes(DELETE);

        let firmwareColumns = [
            { isColWithBody: false, field: "id", header: "id", sortable: true, className: "visibilityHidden" },
            { isColWithBody: false, field: "name", header: msg('firmware.name'), sortable: true, className: "p-col-2" },
            { isColWithBody: true, field: "comment", header: msg('firmware.description'), body: this.comment, sortable: true, className: "p-col-3" },
            { isColWithBody: true, field: "uploadDate", header: msg('firmware.uploadDate'), body: this.uploadDateBody, sortable: true, className: "p-col-3" },


        ];
        let firmwares = this.props.firmwares && this.props.firmwares.firmwares ? this.props.firmwares.firmwares : [];
        let totalPages = this.props.firmwares && this.props.firmwares.firmwares ? this.props.firmwares.totalPages : 0;

        let firmwareOutilsBar = [
            { filename: "Liste des firmwares.xlsx", iconSrc: "/assets/ultima/layout/images/cartadis/excel.svg", titleIcone: "Firmwares", tooltipTextIcone: msg('firmware.tooltip.export'), urlExport: "firmware/firmware-xls" }
        ]
        let ContextMenuContent = [
            {
              label: msg('firmware.update'),
              command: this.update
            },
            {
              label: msg('firmware.delete'),
              command: this.deleteFirmware
            }];
        let firmwaresTable = (
            <TableData data={firmwares} titleTable={msg('firmware.table.title')} totalPages={totalPages}
                ContextMenuContent={ContextMenuContent}
                selectionMode={"single"} selection={this.state.rowSelection}                
                onSelection={this.onSelection} outilsBar={firmwareOutilsBar}
                getDataFunction={this.loadFirmwares} paramnFunctionData={null}  filter={this.state.filter}            
                columns={firmwareColumns} searchInTable={false} searchByName={true}
                onSort={this.onSort}
                apply={this.searchByKeyword}
                nameTxtPlaceholder={msg('firmware.table.search.placeholder')} />);

        return rAuthorized && (
            <div className="card p-grid commonClass">
                <div className="p-col-12">
                    <div className="p-col-6 p-md-6 item">
                        <div className="p-col-12"><h2 className="titleAbonnement">{msg('firmware.title')}</h2></div>
                    </div>
                    <div className="p-col-6 item buttonPosition">
                        <div className={this.state.buttonSelectd ? "btnSelected item" : "btnNotSelected item "} style={{ marginLeft: '-5px' }}>
                            <Button label={(msg('firmware.update'))} title={(msg('firmware.update'))} onClick={this.update} key="update" disabled={!uAuthorized && !rAuthorized} />
                        </div>
                        <div className={this.state.buttonSelectd ? "btnSelected item " : "btnNotSelected item"} >
                            <Button label={(msg('firmware.delete'))} onClick={() => { this.deleteFirmware() }} title={(msg('firmware.delete'))} key="delete" disabled={!dAuthorized} />
                        </div>
                        <div className="item btnSelected">
                            <Link to="/firmware/create">
                                <Button label={(msg('firmware.create.firmware'))} title={(msg('firmware.create.firmware'))} className="button" key="create" disabled={!cAuthorized} />
                            </Link>
                        </div>
                    </div>
                    {firmwaresTable}
                </div>

                <MessageDialog className={'message-dialog error-message'} visible={this.state.sessionExpired || this.state.isError} title={msg('message.dialog.error.title')}
                    message={(this.state.sessionExpired && msg("login.unauthorized.internalauthenticationserviceexception")) || (this.state.isError &&  msg(this.state.message))}
                    onHide={e => this.resetMessage(e)} onCancel={e => this.resetMessage(e)} />

                <MessageDialog className={'message-dialog warn-message'} visible={this.state.isNotDownload || this.state.isConfig} title={msg('message.dialog.warn.title')}
                    message={(this.state.isNotDownload && msg('error.config.borne.target.configuration.cannot.be.build'))
                        || (this.state.isConfig && msg('error.config.borne.firmware.cant.delete'))}
                    onHide={() => this.setState({ isNotDownload: false, isConfig: false })} />

                <MessageDialog confirm={true} className={'message-dialog confirm-message'} visible={this.state.showConfirm && !this.state.isConfig} title={msg('message.dialog.confirm.title')}
                    message={this.state.showConfirm && msg('firmware.delete.confirm.message', { firmwareName: this.state.firmwareName })} key="confirm-delete"
                    onConfirm={() => { this.setState({ showConfirm: false }); this.confirmDeleteFirmware() }} onCancel={() => this.setState({ showConfirm: false })} />

            </div >);
    }
}