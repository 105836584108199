import React, {Component} from 'react';
import {rolesValidator, REPORTING, FINANCIALMANDATE} from '../../utils/RolesValidator';
import BorneFilter from '../common/BorneFilter';
import UploadService from "../../middleware/download-upload-files.service"
import { toDefaultDateFormat } from '../../utils/dateTimeUtils';
import msg from '../../texts/msg';
import './reporting.css';
import { progressSpinnerHandler } from '../../utils/ProgressSpinnerHandler.js';
import MessageDialog from '../common/MessageDialog';

let BORNE_EXPORT_URL = 'reporting/report';
let BORNE_EXPORT_TRANSACTION_URL='reporting/reporTransaction';
let BORNE_EXPORT_FISCAL_CLOSURE_URL='reporting/fiscalClosure';
let REPORT_PAYMENTS='reportPayments';
let REPORT_TRANSACTION = 'reportTransaction';
let REPORT_FISCAL_CLOSURE = 'fiscalClosure';
let REPORT_COMMISSION = 'prelevcommission';
let REPORT_PRESTATION = 'prelevprestation';
let REPORT_COMMISSION_URL = 'financialmandate/prelevcommission';
let REPORT_PRESTATION_URL = 'financialmandate/prelevprestation';

let monthPeriodType = {key: 'MONTH', value: msg('filter.period.type.month')};
export class Reporting extends Component {

	constructor(props) {
        super(props);        
        this.state = {
            submenu: this.props.match.params.submenu,
            reportType: REPORT_PAYMENTS,
            paimentButtonSelected: true,
            transactionButtonSelected : false,
            fiscalClosureButtonSelected: false,
            commissionButtonSelected: true,
            prestationButtonSelected: false,
            showPeriodType: false,
            periodType: null,
            showTime: true,
            showEquipments: true,
            showBatchNumbers: true,
            stationRequired: false,
            stationSingle: false,
            filterData: null,
        };
        this.loadFilter = this.loadFilter.bind(this);
        this.loadFilterStations = this.loadFilterStations.bind(this);
        this.loadReport = this.loadReport.bind(this);
        this.handlePaimentClick = this.handlePaimentClick.bind(this);
        this.handleTransactionClick = this.handleTransactionClick.bind(this);
        this.handleCommissionClick = this.handleCommissionClick.bind(this);
        this.handlePrestationClick = this.handlePrestationClick.bind(this);
        this.initDataInfoBarReport = this.initDataInfoBarReport.bind(this);
    }

    componentDidMount() {
        let lSubmenu = this.props.match.params.submenu;
        this.setState({
            submenu: lSubmenu,
            reportType: lSubmenu === 'mandate' ? REPORT_COMMISSION : REPORT_PAYMENTS,
            periodType: lSubmenu === 'mandate' ? monthPeriodType.key : null,
            showTime: lSubmenu === 'mandate' ? false :  true,
        });
        progressSpinnerHandler.show(false);
    }

    componentDidUpdate(prevProps) {
        let lSubmenu = this.props.match.params.submenu;
        if(lSubmenu !== prevProps.match.params.submenu) {
            let reportType = lSubmenu === 'mandate' ? REPORT_COMMISSION : REPORT_PAYMENTS;
            this.setState({
                    submenu: lSubmenu, 
                    reportType: reportType,
                    showPeriodType: false,
                    periodType: lSubmenu === 'mandate' ? monthPeriodType.key : null,
                    showTime: lSubmenu === 'mandate' ? false :  true,
                    showEquipments: reportType === REPORT_PAYMENTS ? false : true,
                    showBatchNumbers: reportType === REPORT_PAYMENTS ? true : false,
                    stationRequired: false,
                });
            this.setState(this.getStateByReportType(reportType));
        }
        if(prevProps.data !== this.props.data) {
            this.setState({
                filterData: this.props.filterData
            });          
        }
    }

    loadFilter(filter) {
        this.props.loadFilter(REPORTING, filter);
    }
    
    loadFilterStations(filter) {
        this.props.loadFilterStations(REPORTING, filter);
    }

    initDataInfoBarReport(filter){
        this.props.setDataInfoBar(this.dataInfobar(filter));
    }

    loadReport(filter) {
        let filename;  
        let exportUrl;
       exportUrl = BORNE_EXPORT_URL;
        switch (this.state.reportType ) { 
            case REPORT_PAYMENTS:
                filename = msg('report.payments.filename') + '.xlsx';
                exportUrl = BORNE_EXPORT_URL;
                break;
            case REPORT_TRANSACTION:
                filename = msg('report.transaction') + '.xlsx';
                exportUrl = BORNE_EXPORT_TRANSACTION_URL;
                break;
            case REPORT_FISCAL_CLOSURE:
                filename = msg('report.fiscalClosure') + '.xlsx';
                exportUrl = BORNE_EXPORT_FISCAL_CLOSURE_URL;
                break;
            case REPORT_COMMISSION:
                filename = msg('report.mandate.prelev.commission') + '.xlsx';
                exportUrl = REPORT_COMMISSION_URL;
                break;
            case REPORT_PRESTATION:
                filename = msg('report.mandate.prelev.prestation') + '.xlsx';
                exportUrl = REPORT_PRESTATION_URL;
                break;
            default:
                filename = msg('report.payments.filename') + '.xlsx';
                exportUrl = BORNE_EXPORT_URL;
        }
        this.props.setDataInfoBar(this.dataInfobar(filter));

        UploadService.getFileData(exportUrl, filename, filter)
        .then((response) => {
            if(response.status === 400) {
                response.json().then(json => {
                    this.setState({ isApiError: true, message: json.message });
                });
                UploadService.setProgressSpinnerVisible(false);
            }
            else {
                UploadService.downloadDataFile(response, filename)
            }
        }).catch((error) => {
            UploadService.setProgressSpinnerVisible(false);
        });
    }

    dataInfobar(filter){
        if(!filter) {
            return [{key :"titleFilter" ,value :  " " }];
        }
        let  equipmentName  =  filter.equipments && filter.equipments.length > 0 ? filter.equipments.map((data )=>{
                return data.value  ? data.value :  msg('filter.equipments.all') ;
            }) : msg('filter.equipments.all');
        return  [
            {key: "titleFilter", value: msg('filter.in.progress')  },
            {key: "startDate", value: msg('filter.date.from').toLowerCase() + "   " + toDefaultDateFormat(new Date(filter.startDate)) },
            {key: "endDate", value: msg('filter.date.to').toLowerCase() + "  " + toDefaultDateFormat(new Date(filter.endDate)) + ";"},
            {key: "equipmentName", value: equipmentName + (this.state.showPeriodType ? ";" : "") },
            this.state.showPeriodType ? {key: "periodType", value: filter.periodTypes.label} : ""                    
        ]
    }

    handlePaimentClick() {
        this.setState(this.getStateByReportType(REPORT_PAYMENTS));
    }

    handleTransactionClick(){
        this.setState(this.getStateByReportType(REPORT_TRANSACTION));  
    }

    handleFiscalClosureClick(){
        this.setState(this.getStateByReportType(REPORT_FISCAL_CLOSURE));
    }

    handleCommissionClick() {
        this.setState(this.getStateByReportType(REPORT_COMMISSION));  
    }

    handlePrestationClick() {
        this.setState(this.getStateByReportType(REPORT_PRESTATION));
    }

    getStateByReportType(reportType){
        switch(reportType) {
            case REPORT_PAYMENTS:
            return {
                reportType: REPORT_PAYMENTS,
                paimentButtonSelected: !this.state.paimentButtonSelected, 
                transactionButtonSelected : false,
                fiscalClosureButtonSelected: false,
                commissionButtonSelected: false,
                prestationButtonSelected: false,
                showPeriodType: false,
                periodType: null,
                showTime: true,
                showEquipments: true,
                showBatchNumbers: true,
                stationRequired: false,
                stationSingle: false
            };
            case REPORT_TRANSACTION:
            return {
                reportType: REPORT_TRANSACTION,
                transactionButtonSelected: !this.state.transactionButtonSelected, 
                paimentButtonSelected: false,
                fiscalClosureButtonSelected: false,
                commissionButtonSelected: false,
                prestationButtonSelected: false,
                showPeriodType: false,
                periodType: null,
                showTime: true,
                showEquipments: true,
                showBatchNumbers: false,
                stationRequired: false,
                stationSingle: false
            };
            case REPORT_FISCAL_CLOSURE:
                return {
                reportType: REPORT_FISCAL_CLOSURE,
                fiscalClosureButtonSelected: !this.state.fiscalClosureButtonSelected, 
                paimentButtonSelected: false,
                transactionButtonSelected: false,
                commissionButtonSelected: false,
                prestationButtonSelected: false,
                showPeriodType: true,
                showTime: false,
                showEquipments: true,
                showBatchNumbers: false,
                stationRequired: false,
                stationSingle: false
            };
            case REPORT_COMMISSION:
            return {
                reportType: REPORT_COMMISSION,
                commissionButtonSelected: !this.state.commissionButtonSelected, 
                prestationButtonSelected: false,
                paimentButtonSelected: false,
                transactionButtonSelected: false,
                fiscalClosureButtonSelected: false,
                showPeriodType: false,
                periodType: monthPeriodType.key,
                showTime: false,
                showEquipments: true,
                showBatchNumbers: false,
                stationRequired: false,
                stationSingle: false
            };
            case REPORT_PRESTATION:
            return {
                reportType: REPORT_PRESTATION,
                prestationButtonSelected: !this.state.prestationButtonSelected, 
                commissionButtonSelected: false,
                paimentButtonSelected: false,
                transactionButtonSelected: false,
                fiscalClosureButtonSelected: false,
                showPeriodType: false,
                periodType: monthPeriodType.key,
                showTime: false,
                showEquipments: false,
                showBatchNumbers: false,
                stationRequired: true,
                stationSingle: true
            };
            default:
                return this.state;
        }
    }

    render() {

        let reportingAuthorized = rolesValidator.validate(REPORTING) && (!this.state.submenu || this.state.submenu === 'excel');
        let financialmandateAuthorized  = rolesValidator.validate(FINANCIALMANDATE) && this.state.submenu === 'mandate';

        return (reportingAuthorized || financialmandateAuthorized) && 
        (<div className="card p-grid report">
            <div className="p-grid p-col-12">
                {reportingAuthorized && (
                <div className="p-grid p-col-12">
                    <div className="p-col-12"><h1>{msg('report.title')}</h1></div>  
                    <div className="p-grid p-col-12 p-md-2 export-button">
                        <div className="p-col-12" >
                            <a href="#reportFilterId">
                                <img id="imagePaiment" className={"payments " + (this.state.paimentButtonSelected? "buttonSelected": "buttonNotSelected")} type="image" alt="payments"
                                    src="/assets/ultima/layout/images/cartadis/Vente_bleu.svg"
                                        onClick={() => this.handlePaimentClick()} key='showbornefilter' />
                            </a>                                     
                        </div>
                        <div className="p-col-12" style={{height:'40px'}}><span>{msg('report.payments')}</span></div>
                    </div>
                    <div className="p-grid p-col-12 p-md-2 export-button">
                        <div className="p-col-12" >
                            <a href="#reportFilterId">
                                <div className={"transactions pi-md-account-balance-wallet " + (this.state.transactionButtonSelected ? "buttonSelected" : "buttonNotSelected")}                                                
                                    onClick={() => this.handleTransactionClick()} alt="Transactions" />
                            </a> 
                        </div>
                        <div className="p-col-12" style={{height:'40px'}}><span>{msg('report.transaction')}</span></div>
                    </div> 
                    <div className="p-grid p-col-12 p-md-2 export-button">
                        <div className="p-col-12" >
                            <a href="#reportFilterId">
                                <div className={"closure pi-md-account-balance " + (this.state.fiscalClosureButtonSelected ? "buttonSelected" : "buttonNotSelected")}  
                                    onClick={() => {this.handleFiscalClosureClick()}} alt="closure" />
                            </a>
                        </div>
                        <div className="p-col-12" style={{height:'40px'}}><span>{msg('report.fiscalClosure')}</span></div>
                    </div>
                </div>
                )}            
                {financialmandateAuthorized && (
                <div className="p-grid p-col-12">
                    <div className="p-col-12"><h1>{msg('report.mandate.title')}</h1></div>
                    <div className="p-grid p-col-12 p-md-2 export-button">
                        <div className="p-col-12" >
                            <a href="#reportFilterId">
                                <div id="prelevCommission" className={"mandat pi-md-sync " + (this.state.commissionButtonSelected ? "buttonSelected" : "buttonNotSelected")}
                                        alt={msg('report.mandate.prelev.commission')}
                                        onClick={ () => this.handleCommissionClick() } key='commission' />
                            </a>                                    
                        </div>
                        <div className="p-col-12" style={{height:'40px'}}><span>{msg('report.mandate.prelev.commission')}</span></div>
                    </div>
                    <div className="p-grid p-col-12 p-md-2 export-button">
                        <div className="p-col-12" >
                            <a href="#reportFilterId">
                                <div id="prelevPrestation" className={"mandat pi-md-rotate-90-degrees-ccw " + (this.state.prestationButtonSelected ? "buttonSelected" : "buttonNotSelected")}
                                     alt={msg('report.mandate.prelev.prestation')}
                                        onClick={ () => this.handlePrestationClick() } key='prestation' />
                            </a>                                    
                        </div>
                        <div className="p-col-12" style={{height:'40px'}}><span>{msg('report.mandate.prelev.prestation')}</span></div>
                    </div>
                </div>
                )}
                {<div className="p-grid p-col-12" id="reportFilterId">
                    <div className="p-col-12"><h2>{msg('report.payments.filter')}</h2></div>
                    <div className="p-col-12"><hr/></div>
                    <BorneFilter apply={this.loadReport}
                        loadFilter={filter => this.loadFilter(filter)}
                        loadFilterStations={filter => this.loadFilterStations(filter)}
                        filterData={this.props.filterData}
                        filterStations={this.props.filterStations}
                        applyOnMount={false}
                        showDate={true} showTime={this.state.showTime} showMonth={true} showPeriodType={this.state.showPeriodType}
                        periodType={this.state.periodType} showStation={true} stationRequired={this.state.stationRequired} stationSingle={this.state.stationSingle}
                        showEquipments={this.state.showEquipments} showBatchNumbers={this.state.showBatchNumbers}
                        showChannel={this.state.commissionButtonSelected && (this.state.submenu === 'mandate')}
                        initDataInfoBarReport={this.initDataInfoBarReport}
                        applyLabel={msg('report.payments.btnlabel')} title="" filterItemClassName={"p-md-4"}
                        styleStationInputAutocomplete={{ marginLeft:'-5px' }}
                    />
                </div>}
            </div>

            <MessageDialog className={'message-dialog error-message'} visible={this.state.isApiError} title={msg('message.dialog.error.title')}
                message={msg(this.state.message)}
                onHide={() => this.setState({ isApiError: false, message: '' })}/>
        </div>);
    }
}