import React, { Component } from 'react'
import GetAppIcon from '@material-ui/icons/GetApp';
import { Fieldset } from 'primereact/fieldset';
import { rolesValidator, EQUIPMENTS } from '../../../utils/RolesValidator.js';
import msg from '../../../texts/msg';
import MessageDialog from '../../common/MessageDialog';
import { toLocaleDateTimeFormat } from '../../../utils/dateTimeUtils';
import { progressSpinnerHandler } from '../../../utils/ProgressSpinnerHandler';
import UploadService from "../../../middleware/download-upload-files.service";
import '../../common/css/common.css';

export class BorneInformationReport extends Component {
    constructor(props) {
        super(props);
        let lDate = new Date();
        lDate.setHours(23);
        lDate.setMinutes(59);
        lDate.setSeconds(59);
        this.state = {
            date: lDate,
            test: false
        }
        this.handleDownloadClick = this.handleDownloadClick.bind(this);
        this.fillOpenHours = this.fillOpenHours.bind(this);
    }

    componentDidMount() {
        this.setState({ equipmentId: this.props.equipmentId, equipmentName: this.props.equipmentName, contenPath: this.props.contenPath })
    }

    handleDownloadClick() {
        let values = this.state.contenPath.split("/");
        const fileName = values[values.length - 1];


        let uri = "equipments/" + this.state.equipmentId + "/GET_STARTUP_REPORT/commandeResponse";
        UploadService.getFileCommandData(uri, "GET_STARTUP_REPORT", this.props.authorization).then((response) => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                if (response.status === 401) {
                    alert(msg('login.unauthorized.internalauthenticationserviceexception'));
                } else if (response.status === 400) {
                    this.setState({ isNotDownload: true, buttonSelectd: false });
                }
                else {
                    this.setState({ buttonSelectd: false });
                    a.href = url;
                    a.download = fileName;
                    a.click();                    
                }
                progressSpinnerHandler.show(false);
            });
        });
    }

    fillOpenHours() {
        let openHours = Array(7).fill("");
        let timetable = this.props.timetable;
        const CLOSE = 'close';
        const OPEN = 'open';
        if (timetable.ALL) {
            if (timetable.ALL.at(0).at(0) === OPEN) {
                openHours.fill(msg('equipment.opening.hours.open'))
            } else if (timetable.ALL.at(0).at(0) === CLOSE) {
                openHours.fill(msg('equipment.opening.hours.close'))
            } else {
                openHours.fill(msg('equipment.opening.hours.from') + " " + timetable.ALL.at(0).at(0) + " " + msg('equipment.opening.hours.to') + " " + timetable.ALL.at(0).at(1));
            }
        } else {
            if (timetable.Monday.flat().length > 1) {
                for (let [from, to] of timetable.Monday) {
                    openHours[0] += msg('equipment.opening.hours.from') + " " + from + " " + msg('equipment.opening.hours.to') + " " + to + " ";
                }
            } else {
                if (timetable.Monday.at(0).at(0) === CLOSE) {
                    openHours[0] = msg('equipment.opening.hours.close')
                } else {
                    openHours[0] = msg('equipment.opening.hours.open')
                }

            }
            if (timetable.Tuesday.flat().length > 1) {
                for (let [from, to] of timetable.Tuesday) {
                    openHours[1] += msg('equipment.opening.hours.from') + " " + from + " " + msg('equipment.opening.hours.to') + " " + to + " ";
                }
            } else {
                if (timetable.Tuesday.at(0).at(0) === CLOSE) {
                    openHours[1] = msg('equipment.opening.hours.close')
                } else {
                    openHours[1] = msg('equipment.opening.hours.open')
                }

            }
            if (timetable.Wednesday.flat().length > 1) {
                for (let [from, to] of timetable.Wednesday) {
                    openHours[2] += msg('equipment.opening.hours.from') + " " + from + " " + msg('equipment.opening.hours.to') + " " + to + " ";
                }
            } else {
                if (timetable.Wednesday.at(0).at(0) === CLOSE) {
                    openHours[2] = msg('equipment.opening.hours.close')
                } else {
                    openHours[2] = msg('equipment.opening.hours.open')
                }
            }
            if (timetable.Thursday.flat().length > 1) {
                for (let [from, to] of timetable.Thursday) {
                    openHours[3] += msg('equipment.opening.hours.from') + " " + from + " " + msg('equipment.opening.hours.to') + " " + to + " ";
                }
            } else {
                if (timetable.Thursday.at(0).at(0) === CLOSE) {
                    openHours[3] = msg('equipment.opening.hours.close')
                } else {
                    openHours[3] = msg('equipment.opening.hours.open')
                }
            }
            if (timetable.Friday.flat().length > 1) {
                for (let [from, to] of timetable.Friday) {
                    openHours[4] += msg('equipment.opening.hours.from') + " " + from + " " + msg('equipment.opening.hours.to') + " " + to + " ";
                }
            } else {
                if (timetable.Friday.at(0).at(0) === CLOSE) {
                    openHours[4] = msg('equipment.opening.hours.close')
                } else {
                    openHours[4] = msg('equipment.opening.hours.open')
                }
            }
            if (timetable.Saturday.flat().length > 1) {
                for (let [from, to] of timetable.Saturday) {
                    openHours[5] += msg('equipment.opening.hours.from') + " " + from + " " + msg('equipment.opening.hours.to') + " " + to + " ";
                }
            } else {
                if (timetable.Saturday.at(0).at(0) === CLOSE) {
                    openHours[5] = msg('equipment.opening.hours.close')
                } else {
                    openHours[5] = msg('equipment.opening.hours.open')
                }
            }
            if (timetable.Sunday.flat().length > 1) {
                for (let [from, to] of timetable.Sunday) {
                    openHours[6] += msg('equipment.opening.hours.from') + " " + from + " " + msg('equipment.opening.hours.to') + " " + to + " ";
                }
            } else {
                if (timetable.Sunday.at(0).at(0) === CLOSE) {
                    openHours[6] = msg('equipment.opening.hours.close')
                } else {
                    openHours[6] = msg('equipment.opening.hours.open')
                }
            }
        }
        return openHours;
    }
    render() {
        let authorized = rolesValidator.validate([EQUIPMENTS]);
        let serialNumber = this.props.serialNumber ? this.props.serialNumber : null;
        let borneCharacteristics = this.props.borneCharacteristics ? this.props.borneCharacteristics : null;
        let dateCommissioning = toLocaleDateTimeFormat(this.props.lastReportStartup);
        let days = [
            msg('equipment.opening.hours.monday'),
            msg('equipment.opening.hours.tuesday'),
            msg('equipment.opening.hours.wednesday'),
            msg('equipment.opening.hours.thursday'),
            msg('equipment.opening.hours.friday'),
            msg('equipment.opening.hours.saturday'),
            msg('equipment.opening.hours.sunday'),
        ];
        let openHours = this.props.timetable ? this.fillOpenHours(): null;
        return authorized && (
            <div className="p-grid p-col-12">
                <div className="p-grid p-col-12" style={{ marginLeft: "15px", marginTop: "-30px" }}>
                    <Fieldset legend={msg('equipement.Characteristics')} toggleable={true} className="p-col-12">
                        {serialNumber && borneCharacteristics && borneCharacteristics.characteristicsUpdateDate ?
                            <div>
                                <div className="p-grid p-col-12" style={{ marginLeft: "15px", marginTop: "-40px" }}>
                                    <h3 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }} className="p-col-6">{msg('equipement.Characteristics.information')}</h3>
                                    <h3 style={{ fontSize: '15px' }} className="p-col-5">{msg('equipement.Characteristics.update.date')} 
                                    {borneCharacteristics.characteristicsUpdateDate}</h3>
                                    <label style={{ fontSize: '15px', marginLeft: "15px", marginTop: "-10px" }} className="p-col-12">{msg('equipement.Characteristics.information.implant')} 
                                    : {borneCharacteristics.implant}</label>
                                    <label style={{ fontSize: '15px', marginLeft: "15px", marginTop: "-10px" }} className="p-col-12">{msg('equipement.Characteristics.information.client')}
                                    : {borneCharacteristics.clientCode}</label>   
                                </div>
                                <div className="p-grid p-col-12" style={{ marginLeft: "15px", marginTop: "-40px" }}>
                                    <h3 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }} className="p-col-12">{msg('equipement.Characteristics.serialNumber')}</h3>
                                    <label style={{ fontSize: '15px', marginLeft: "15px", marginTop: "-10px" }} className="p-col-12">{msg('equipement.Characteristics.serialNumber.borne')} : {borneCharacteristics.borneName}</label>
                                    {borneCharacteristics.data
                                    .sort((a, b) => msg('equipement.Characteristics.serialNumber.' + a.name).localeCompare(msg('equipement.Characteristics.serialNumber.' + b.name)))
                                    .filter(e => e.type === "SERIAL")
                                    .map(e => {
                                        return (<label style={{ fontSize: '15px', marginLeft: "15px", marginTop: "-10px" }} className="p-col-12" key={e.name}>
                                            {msg('equipement.Characteristics.serialNumber.' + e.name)} : {e.value}
                                        </label>);
                                    })}                      
                                </div>
                                <div className="p-grid p-col-12" style={{ marginLeft: "15px", marginTop: "-40px" }}>
                                    <h3 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }} className="p-col-12">{msg('equipement.Characteristics.version')}</h3>
                                    {borneCharacteristics.data
                                    .sort((a, b) => msg('equipement.Characteristics.version.' + a.name).localeCompare(msg('equipement.Characteristics.version.' + b.name)))
                                    .filter(e => e.type === "VERSION")
                                    .map(e => {
                                        return (<label style={{ fontSize: '15px', marginLeft: "15px", marginTop: "-10px" }} className="p-col-12" key={e.name}>
                                            {msg('equipement.Characteristics.version.' + e.name)} : {e.value}
                                        </label>);
                                    })}
                                </div>
                                <div className="p-grid p-col-12" style={{ marginLeft: "15px", marginTop: "-40px" }}>
                                    <h3 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }} className="p-col-12">{msg('equipement.Characteristics.network')}</h3>
                                    <label style={{ fontSize: '15px', marginLeft: "15px", marginTop: "-10px" }} className="p-col-12">{msg('equipement.Characteristics.network.local')} : {borneCharacteristics.localIp}</label>
                                    {borneCharacteristics.data
                                    .sort((a, b) => msg('equipement.Characteristics.network.' + a.name).localeCompare(msg('equipement.Characteristics.network.' + b.name)))
                                    .filter(e => e.type === "NETWORK")
                                    .map(e => {
                                        return (<label style={{ fontSize: '15px', marginLeft: "15px", marginTop: "-10px" }} className="p-col-12" key={e.name}>
                                            {msg('equipement.Characteristics.network.' + e.name)} : {e.value}
                                        </label>);
                                    })}
                                </div>
                                <div className="p-grid p-col-12" style={{ marginLeft: "15px", marginTop: "-40px" }}>
                                    <h3 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }} className="p-col-12">{msg('equipement.Characteristics.neftis')}</h3>
                                    <label style={{ fontSize: '15px', marginLeft: "15px", marginTop: "-10px" }} className="p-col-12">{msg('equipement.Characteristics.neftis.ip')} : {borneCharacteristics.neftisIp}</label>
                                    <label style={{ fontSize: '15px', marginLeft: "15px", marginTop: "-10px" }} className="p-col-12">{msg('equipement.Characteristics.neftis.station')} : {borneCharacteristics.neftisStation}</label>
                                </div>
                            </div>
                            :
                            <div className="p-col-10" style={{ marginLeft: "20px", marginTop: "-15px" }}>
                                <label>{msg('equipements.Characteristics.not.infos')}</label>
                            </div>
                        }
                    </Fieldset>
                </div>
                <div className="p-grid p-col-12" style={{ marginLeft: "15px", marginTop: "-30px" }}>

                    <Fieldset legend={msg('equipement.service.information')} toggleable={true} className="p-col-12">
                        {borneCharacteristics && <div className="p-grid p-col-12"
                            style={{ marginLeft: "20px", marginTop: "-20px", 'paddingBottom': '20px', fontSize: '15px' }}>
                            <label>
                                {msg("equipement.Characteristics.borne.is.guaranteed")}
                                {borneCharacteristics.borneIsGuaranteed != null ?
                                    borneCharacteristics.borneIsGuaranteed === true ?
                                        msg("equipement.Characteristics.borne.is.guaranteed.yes") :
                                        msg("equipement.Characteristics.borne.is.guaranteed.no") :
                                    ""}
                            </label>
                        </div>}
                    {borneCharacteristics && borneCharacteristics.installationEqDate && <div className="p-grid p-col-12"
                    style={{ marginLeft: "20px", marginTop: "-20px", 'paddingBottom': '20px', fontSize: '15px'} }>
                  <label>{msg("equipement.Characteristics.installation.date")}{borneCharacteristics.installationEqDate}</label>
                  </div>}
                        {serialNumber && dateCommissioning && this.state.contenPath &&
                            <div className="p-col-6" style={{ marginLeft: "20px", marginTop: "-20px", fontSize: '15px'}}>
                                <label>{msg('equipements.activity.commissioning.date')}{dateCommissioning}</label>
                            </div>
                        }
                        {serialNumber && dateCommissioning && this.state.contenPath ?
                            <div className="p-col-8" style={{ marginLeft: "20px", marginTop: "-15px" }}>
                                <div style={{ marginTop: '5px' }}>
                                    <GetAppIcon fontSize="small" style={{ marginBottom: '-4px', color: "#1565C0", cursor: "pointer" }} 
                                    onClick={() => this.handleDownloadClick()} />
                                    {<label style={{ fontStyle: 'italic', marginLeft: '5px', color: "#1565C0", cursor: "pointer" }} onClick={() => this.handleDownloadClick()}>{msg('equipements.activity.commissioning.download')}</label>}
                                    <MessageDialog className={'message-dialog warn-message'} visible={this.state.isNotDownload} title={msg('message.dialog.warn.title')}
                                    message={msg('equipements.commissioning.file.not.found') }
                                    onHide={() => this.setState({ isNotDownload: false })} />
                                </div>
                            </div>
                            :
                            <div className="p-col-10" style={{ marginLeft: "20px", marginTop: "-15px" , fontSize: '15px'}}>
                                <label>{msg('equipements.activity.not.commissioning')}</label>
                            </div>
                        }
                    </Fieldset>
                </div>
                <div className="p-grid p-col-12" style={{ marginLeft: "15px", marginTop: "-30px" }}>
                    <Fieldset legend={msg('equipment.current.configuration')} toggleable={true} className="p-col-12">
                        <div>
                            <div className="p-grid p-col-12" style={{ marginLeft: "15px", marginTop: "-40px" }}>
                                <h3 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }} className="p-col-6">{msg('equipment.available.services')}</h3>
                                {this.props.services && this.props.services
                                    .sort((a, b) => (a.serviceId === null) - (b.serviceId === null) || a.name.localeCompare(b.name))
                                    .filter(e => e.available === true)
                                    .map(e => {
                                        if (e.eancode && e.price.length > 0) {
                                            return (<label style={{ fontSize: '15px', marginLeft: "15px", marginTop: "-10px" }} className="p-col-12" key={e.name}>
                                            {e.name} ({msg('equipment.Characteristics.eancode')} {e.eancode}) : {(e.price[0] / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })} {msg('equipment.Characteristics.euros')} ({e.price[1]} {e.price[1] < 2? msg('equipment.Characteristics.unit'): msg('equipment.Characteristics.units')})
                                        </label>);
                                        } else if (e.serviceId === null) {
                                            return (<label style={{ fontSize: '15px', marginLeft: "35px", marginTop: "-10px" }} className="p-col-12" key={e.name}>
                                                {msg('equipment.' + e.name)}
                                            </label>);
                                        } else {
                                            return (<label style={{ fontSize: '15px', marginLeft: "15px", marginTop: "-10px" }} className="p-col-12" key={e.name}>
                                            {msg('equipment.' + e.name)}
                                        </label>);
                                        }

                                    })}
                                {this.props.services && this.props.services.filter(e => e.available).length === 0 &&
                                    <label style={{ fontSize: '15px', marginLeft: "15px", marginTop: "-10px" }} className="p-col-12">{msg('equipment.no.information')}</label>
                                }
                            </div>
                            <div className="p-grid p-col-12" style={{ marginLeft: "15px", marginTop: "-40px" }}>
                                <h3 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }} className="p-col-6">{msg('equipment.unavailable.services')}</h3>
                                {this.props.services && this.props.services
                                    .sort((a, b) => (a.serviceId === null) - (b.serviceId === null) || a.name.localeCompare(b.name))
                                    .filter(e => !e.available)
                                    .map(e => {
                                        if (e.eancode && e.price.length > 0) {
                                            return (<label style={{ fontSize: '15px', marginLeft: "15px", marginTop: "-10px" }} className="p-col-12" key={e.name}>
                                            {e.name} ({msg('equipment.Characteristics.eancode')} {e.eancode}) : {(e.price[0] / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })} {msg('equipment.Characteristics.euros')} ({e.price[1]} {e.price[1] < 2? msg('equipment.Characteristics.unit'): msg('equipment.Characteristics.units')})
                                        </label>);
                                        } else if (e.serviceId === null) {
                                            return (<label style={{ fontSize: '15px', marginLeft: "35px", marginTop: "-10px" }} className="p-col-12" key={e.name}>
                                                {msg('equipment.' + e.name)}
                                            </label>);
                                        } else {
                                            return (<label style={{ fontSize: '15px', marginLeft: "15px", marginTop: "-10px" }} className="p-col-12" key={e.name}>
                                            {msg('equipment.' + e.name)}
                                        </label>);
                                        }

                                    })}
                                {this.props.services && this.props.services.filter(e => !e.available).length === 0 &&
                                    <label style={{ fontSize: '15px', marginLeft: "15px", marginTop: "-10px" }} className="p-col-12">{msg('equipment.no.information')}</label>
                                }
                            </div>
                            <div className="p-grid p-col-12" style={{ marginLeft: "15px", marginTop: "-40px" }}>
                                <h3 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }} className="p-col-6">{msg('equipment.configuration.timetable')}</h3>
                                {
                                    openHours && [0, 1, 2, 3, 4, 5, 6]
                                        .map(i => {
                                            return (<label style={{ fontSize: '15px', marginLeft: "15px", marginTop: "-10px" }} className="p-col-12">{days.at(i)} {openHours.at(i)}</label>);
                                        })
                                }
                                {
                                    !openHours && <label style={{ fontSize: '15px', marginLeft: "15px", marginTop: "-10px" }} className="p-col-12">{msg('equipment.no.information')}</label>
                                }
                            </div>
                        </div>
                    </Fieldset>
                </div>
                <div className="p-col-12">
                </div>
                <MessageDialog className={'message-dialog info-message'} visible={this.state.test} title={msg('equipment.borne.info')}
                    message="le service sera implémenter prochainement"
                    onHide={() => this.setState({ test: false })} />
            </div >
        )
    }
}

export default BorneInformationReport
