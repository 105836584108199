import msg from "../../../texts/msg";

export const LIST_MODE = 0;
export const CREATION_MODE = 1;
export const DUPLICATE_MODE = 2;
export const CONSULT_MODE = 3;
export const PLANNING_MODE = 4;

export const emtyChar = '';
export const EMPTY_TYPE_KEY = 0;
export const EQUIPMENT_TYPE_KEY = 1;
export const STATION_TYPE_KEY = 2;
export const EMPTY_TYPE = { key: EMPTY_TYPE_KEY, value: EMPTY_TYPE_KEY, label: msg('campaign.form.input.type.select') };
export const EMPTY_BORNE_TYPE = { key: '', label: msg('campaign.form.input.borne.type.select') };
export const EQUIPMENT_TYPE = { key: EQUIPMENT_TYPE_KEY, value: EQUIPMENT_TYPE_KEY, label: msg('campaign.form.input.type.equipment') };
export const STATION_TYPE = { key: STATION_TYPE_KEY, value: STATION_TYPE_KEY, label: msg('campaign.form.input.type.station') };
export const EMPTY_FIRMWARE = { key: emtyChar, value: msg('campaign.form.firmware.placeholder'), label: msg('campaign.form.firmware.placeholder') };
export const EMPTY_HARDWARE = { key: emtyChar, value: msg('campaign.form.hardware.placeholder'), label: msg('campaign.form.hardware.placeholder') };
export const EMPTY_PRICEZONE = { key: emtyChar, name: emtyChar, value: msg('campaign.form.pricezone.placeholder'), label: msg('campaign.form.pricezone.placeholder') };
export const BORNES_SELECTION_MODE = [
	{ key: msg('campaign.form.select.all.bornes'), value: true },
	{ key: msg('campaign.form.select.no.borne'), value: false }
]