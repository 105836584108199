import { ofType} from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError} from  'rxjs/operators';
import { notificationsLoadSuccess, notificationLoadByIdSuccess, notificationSendSuccess, notificationCreateSuccess,
		notificationUpdateSuccess, notificationDeleteByIdSuccess, loadCampaignsSuccess,
		createCampaignSuccess, loadFirmwaresSuccess, loadHardwaresSuccess, loadPriceZonesSuccess,
		loadStationEquipmentsSuccess, loadCampaignSuccess, updateCampaignSuccess, launchCampaignSuccess,
		deleteCampaignSuccess, loadBornesStatusSuccess, loadBornesAnalysisSuccess,
		loadDefaultSubscribersSuccess, loadCampaignEventsSuccess } from '../actions/maintenance';

import { NOTIFICATIONS_LOAD, NOTIFICATION_LOAD_BY_ID, NOTIFICATION_SEND, NOTIFICATION_CREATE,
		NOTIFICATION_UPDATE, NOTIFICATION_DELETE_BY_ID, LOAD_DEFAULT_SUBSCRIBERS, 
		LOAD_CAMPAIGNS, CREATE_CAMPAIGN, UPDATE_CAMPAIGN, LAUNCH_CAMPAIGN, DELETE_CAMPAIGN,
		LOAD_FIRMWARES, LOAD_HARDWARES, LOAD_PRICE_ZONES, LOAD_STATION_EQUIPMENTS, LOAD_CAMPAIGN,
		LOAD_BORNES_STATUS, LOAD_BORNES_ANALYSIS, LOAD_CAMPAIGN_EVENTS } from '../actions/maintenance';

import { faillure } from '../actions';
import { authProviderType } from '../AuthProviderType';

export const notificationsLoadEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(NOTIFICATIONS_LOAD),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlNotifications = apiRootUri + 'notifications';
				let lurl = urlNotifications+"/subscriptions/";
				if(action.data.idSearch){
					lurl = lurl  + action.data.idSearch + "/";
				}
				lurl = lurl + "filter/" + action.data.notificationType + "?page=" +action.data.page +"&sizePage="+action.data.sizePage;
				const req = ajax({
					  url: lurl,
					  method: 'GET',					  
					  headers: {
						'Content-Type': 'application/json'
					  },
					  withCredentials: true
					});
				return req.pipe(
            			map(res => {return notificationsLoadSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);	
};

export const notificationLoadByIdEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(NOTIFICATION_LOAD_BY_ID),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlNotifications = apiRootUri + 'notifications';
				const req = ajax({
					  	url: urlNotifications+"/subscriptions/"+action.data.notificationId,
					  	method: 'GET',
						withCredentials: true
					});
				return req.pipe(
            			map(res => {return notificationLoadByIdSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);	
};

export const notificationSendEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(NOTIFICATION_SEND),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlNotifications = apiRootUri + 'notifications/subscriptions/';
				if(action.data.notificationId){
					urlNotifications += (action.data.notificationId + '/');
				} 
				urlNotifications += 'message';
				let reqObj = {
					url: urlNotifications,
					  method: 'POST',					  
					  headers: {
						'Content-Type': 'application/json'
					},
					withCredentials: true
				};
				if(action.data.notification){
					reqObj.body = action.data.notification;
				}
				const req = ajax(reqObj);
				return req.pipe(
            			map(res => { return notificationSendSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			})
	);	
};

export const notificationCreateEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(NOTIFICATION_CREATE),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlNotifications = apiRootUri + 'notifications';
				const req = ajax({
						url: urlNotifications+"/subscriptions/",
					  	method: 'POST',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
					  	body: action.data.abonnement
					});
				return req.pipe(
            			map(res => { return notificationCreateSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const notificationUpdateEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(NOTIFICATION_UPDATE),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlNotifications = apiRootUri + 'notifications';
				const req = ajax({
						url: urlNotifications+"/subscriptions/" + action.data.notificationId,
					  	method: 'PUT',
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
					  	body: action.data.abonnement
					});
				return req.pipe(
            			map(res => { return notificationUpdateSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const notificationDeleteByIdEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(NOTIFICATION_DELETE_BY_ID),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlNotifications = apiRootUri + 'notifications';
				const req = ajax({
					url: urlNotifications+"/subscriptions/"+action.data.notificationDeleteId,
					method: 'DELETE',			  
					headers: {
					  'Content-Type': 'application/json'
				  },
				  withCredentials: true
			 	});
				return req.pipe(
					map(res => {return notificationDeleteByIdSuccess(res);}),
					catchError(res => { return of(faillure(res));})
				)
			}),
	);	
};

export const loadDefaultSubscribersEpic = (epicAction) => {
	return epicAction.pipe(
		ofType(LOAD_DEFAULT_SUBSCRIBERS),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlNotificationSubscribers = apiRootUri + 'notifications/subscriptions/subscribers/';
			const req = ajax({
				url: urlNotificationSubscribers + action.data.notificationType + '/' + action.data.isEventBorne,
				method: 'GET',
				withCredentials: true
			});
			return req.pipe(
				map(res => { return loadDefaultSubscribersSuccess(res); }),
				catchError(res => { return of(faillure(res)); })
			)
		}),
	);
};

export const loadCampaignsEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(LOAD_CAMPAIGNS),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlCampaign = apiRootUri + 'campaigns';
				const req = ajax({
						url: urlCampaign + '?page='+action.data.page +'&pageSize='+action.data.pageSize,
					  	method: 'GET',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true
					});
				return req.pipe(
            			map(res => { return loadCampaignsSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const loadCampaignEpic = (epicAction)  => {	
	return  epicAction.pipe(
			ofType(LOAD_CAMPAIGN),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlCampaign = apiRootUri + 'campaigns';
				const req = ajax({
						url: urlCampaign + '/' + action.data.campaignId + '?planning=' + action.data.planning,
					  	method: 'GET',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true
					});
				return req.pipe(
            			map(res => { return loadCampaignSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const createCampaignEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(CREATE_CAMPAIGN),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlCampaign = apiRootUri + 'campaigns';
				const req = ajax({
						url: urlCampaign,
					  	method: 'POST',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
					  	body: action.data.campaign
					});
				return req.pipe(
            			map(res => { return createCampaignSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const updateCampaignEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(UPDATE_CAMPAIGN),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlCampaign = apiRootUri + 'campaigns';
				const req = ajax({
						url: urlCampaign + '/' + action.data.campaign.campaign.id,
					  	method: 'PUT',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
					  	body: action.data.campaign
					});
				return req.pipe(
            			map(res => { return updateCampaignSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const launchCampaignEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(LAUNCH_CAMPAIGN),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlCampaign = apiRootUri + 'campaigns';
				const req = ajax({
						url: urlCampaign + '/' + action.data.campaign.campaign.id + '/launch',
					  	method: 'PUT',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
					  	body: action.data.campaign.campaign
					});
				return req.pipe(
            			map(res => { return launchCampaignSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const deleteCampaignEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(DELETE_CAMPAIGN),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlCampaign = apiRootUri + 'campaigns';
				const req = ajax({
						url: urlCampaign + '/' + action.data.campaignId,
					  	method: 'DELETE',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true
					});
				return req.pipe(
            			map(res => { return deleteCampaignSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const loadFirmwaresEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(LOAD_FIRMWARES),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlCampaign = apiRootUri + 'campaigns';
				const req = ajax({
						url: urlCampaign + (action.data.borneType ? '/' + action.data.borneType : '') + '/firmwares',
					  	method: 'GET',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true
					});
				return req.pipe(
            			map(res => { return loadFirmwaresSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const loadHardwaresEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(LOAD_HARDWARES),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlCampaign = apiRootUri + 'campaigns';
				const req = ajax({
						url: urlCampaign + (action.data.borneType ? '/' + action.data.borneType : '') + '/hardwares',
					  	method: 'GET',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true
					});
				return req.pipe(
            			map(res => { return loadHardwaresSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const loadPriceZonesEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(LOAD_PRICE_ZONES),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlCampaign = apiRootUri + 'campaigns';
				const req = ajax({
						url: urlCampaign + '/pricezones',
					  	method: 'GET',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true
					});
				return req.pipe(
            			map(res => { return loadPriceZonesSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const loadStationEquipmentsEpic = (epicAction)  => {	
	return  epicAction.pipe(
			ofType(LOAD_STATION_EQUIPMENTS),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlCampaign = apiRootUri + 'campaigns';
				let url = urlCampaign + (action.data.campaignId ? '/' + action.data.campaignId : '')  
							+ '/equipments/' + action.data.borneType 
							+ '?stationLevel=' + action.data.stationLevel 
							+ '&planning=' + action.data.planning 
							+ '&station=' + action.data.station
							+ '&firmware=' + action.data.firmware
							+ '&hardware=' + action.data.hardware
							+ '&pricezone=' + action.data.priceZone
							+ '&page=' + action.data.page 
							+ '&pageSize=' + action.data.pageSize;
				const req = ajax({
						url: url,
					  	method: 'GET',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true
					});
				return req.pipe(
            			map(res => { return loadStationEquipmentsSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const loadCampaignEventsEpic = (epicAction)  => {	
	return  epicAction.pipe(
		ofType(LOAD_CAMPAIGN_EVENTS),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlCampaign = apiRootUri + 'campaigns';
			let url = urlCampaign + '/' + action.data.campaignId + '/events';
			const req = ajax({
					url: url,
					method: 'GET',					  
					headers: {
						'Content-Type': 'application/json'
					},
					withCredentials: true
				});
			return req.pipe(map(res => loadCampaignEventsSuccess(res)), catchError(res => of(faillure(res))));
		})
	);
};

export const loadBornesStatusEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(LOAD_BORNES_STATUS),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlMaintenance = apiRootUri + 'maintenance/';
				let url = urlMaintenance + 'bornes/status';
				const req = ajax({
						url: url,
					  	method: 'POST',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
						body: action.data.filter
					});
				return req.pipe(
            			map(res => { return loadBornesStatusSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const loadBornesAnalysisEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(LOAD_BORNES_ANALYSIS),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlMaintenance = apiRootUri + 'maintenance/';
				let url = urlMaintenance + 'analysis';
				const req = ajax({
						url: url,
					  	method: 'POST',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
						body: action.data.filter
					});
				return req.pipe(
            			map(res => { return loadBornesAnalysisSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};