import { progressSpinnerHandler } from '../utils/ProgressSpinnerHandler'
import http from "./common";
import msg from "../texts/msg"
import { authProviderType } from './AuthProviderType';

class UploadFilesService {

  /* upload file for firmware and hardware */
  upload(file, description, borneType, id, onUploadProgress) {
    let formData = new FormData();
    let url;
    if(file){
      formData.append("formData", file);
    }
    formData.append("description",encodeURI(description));
    if (borneType) {
      formData.append("borneType", borneType);
      if (id) {
        formData.append("hardwareId", id.toString());
        url = "/hardware/upload/update";
      } else {
        url = "/hardware/upload/create";
      }
    } else {
      if (id) {
        formData.append("firmwareId", id.toString());
        url = "/firmware/upload/update";
      } else {
        url = "/firmware/upload";
      }
    }
    return http.post(url,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress,
        withCredentials: true
      });
  }

  uploadImportUsersFile(file){
    let formData = new FormData();
    let url = "/users/import";
    if(file){
      formData.append("formData", file);
    }
    return http.post(url,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        withCredentials: true
      });
  }

  setProgressSpinnerVisible(visible) {
    progressSpinnerHandler.show(visible);
  }

  /* download equipment config and all export XLS (equipement, transac .....) */
  getFileData = (exportUrl, filename, filter) => {
    progressSpinnerHandler.show(true);
    let apiRootUri = authProviderType.getApiRootUri();
    let apiUri = apiRootUri + exportUrl;    
    return filter ? fetch(apiUri, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(filter),
    }).then((response) => {
      progressSpinnerHandler.show(false);
      if (response.ok) {
        this.downloadDataFile(response, filename);
      } else {
        return response;
      }}).catch((err) => { progressSpinnerHandler.show(false); })
      :
      fetch(apiUri, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
      });
  }
  
  getFileDataForConsult = (exportUrl, filename, filter) => {
    progressSpinnerHandler.show(true);
    let apiRootUri = authProviderType.getApiRootUri();
    let apiUri = apiRootUri + exportUrl;
    return filter ? fetch(apiUri, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(filter),
    }).then((response) => { this.openDataFileInWindow(response, filename) }).catch((err) => { progressSpinnerHandler.show(false); })
      :
      fetch(apiUri, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
      });
  }
  /* download equipment config and all export XLS (equipement, transac .....) */
  getFileCommandData = (exportUrl, commandType) => {
    progressSpinnerHandler.show(true);
    let apiRootUri = authProviderType.getApiRootUri();
    let apiUri = apiRootUri + exportUrl;
    switch (commandType) {
      case 'GET_LOGS':
      case 'GET_KERNEL_LOGS':
        return fetch(apiUri, {
          method: 'GET',
          headers: { 'Content-Type': 'application/x-tar' },
          credentials: 'include'
        });

      case 'GET_STARTUP_REPORT':
        return fetch(apiUri, {
          method: 'GET',
          headers: { 'Content-Type': 'application/octet-stream' },
          credentials: 'include'
        });
      default:
    }
  }

  downloadDataFile = (response, filename) => {
    const fname = filename;
    response.blob().then(blob => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      if (response.status === 401) {
        alert(msg('login.unauthorized.internalauthenticationserviceexception'));
      } else if (response.status === 400) {
      }
      else {
        a.href = url;
        a.download = fname;
        a.click();
      }
    });
    progressSpinnerHandler.show(false);
  };

  openDataFileInWindow = (response, filename) => {
    const fname = filename;
    response.blob().then(blob => {
      let url = URL.createObjectURL(blob);
      if (response.status === 401) {
        alert(msg('login.unauthorized.internalauthenticationserviceexception'));
      } else if (response.status === 400) {
      }
      else {
        window.name = fname;
        window.open(url);
      }
    });
    progressSpinnerHandler.show(false);
  };

}

export default new UploadFilesService();