
import { PRICEZONE, PRICEZONE_SUCCESS, PRICEZONE_BY_ID, PRICEZONE_BY_ID_SUCCESS, DELETE_PRICEZONE, DELETE_PRICEZONE_SUCCESS } from "../actions/pricezone";


const state0 = { data: null, error: null };

export const pricezonesReducer = (state = state0, action) => {
	switch (action.type) {
		case PRICEZONE:
			return {
				state,
				error: null
			};
		case PRICEZONE_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};

export const pricezoneByIdReducer = (state = state0, action) => {
	switch (action.type) {
		case PRICEZONE_BY_ID:
			return {
				state,
				error: null
			};
		case PRICEZONE_BY_ID_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};

export const deletePricezoneReducer = (state = state0, action) => {
	switch (action.type) {
		case DELETE_PRICEZONE:
			return {
				state,
				error: null
			};
		case DELETE_PRICEZONE_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};