import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import msg from '../../../texts/msg';
import { getCalendarLocale } from '../../../texts/locales/calendar-locale';
import { MultiSelect } from 'primereact/multiselect';
import { Fieldset } from 'primereact/fieldset';
import { Message } from 'primereact/message';

class BorneActivityFilter extends Component {
    constructor(props) {
        super(props);
        let sDate = new Date();
        sDate.setDate(1);
        sDate.setHours(0);
        sDate.setMinutes(0);
        sDate.setSeconds(0);
        let eDate = new Date();
        eDate.setHours(23);
        eDate.setMinutes(59);
        eDate.setSeconds(59);
        this.state = {
            locale: getCalendarLocale(),
            startDate: sDate,
            endDate: eDate,
            maxEndDate: eDate,
            transactionTypes: this.props.filter.transactionTypes,
            showAllAlarm: this.props.filter.showAllAlarm,
            showOnlyOpenedAlarm: this.props.filter.showOnlyOpenedAlarm,
            mandated: this.props.filter.mandated,
            butonRadioChecked: this.props.filter.butonRadioChecked,
            commandsTypes: this.props.filter.commandsTypes,
            commandsTypesProps: this.props.filter.commandsTypes,
            meanOfPaymentTypes: this.props.filter.meanOfPaymentTypes,
            eventTypes: this.props.filter.eventTypes,
            sortDirection: this.props.filter.sortDirection,
            isEndDateAfterStartDate: false
        };
        this.apply = this.apply.bind(this);
        this.onTypeDescriptionChange = this.onTypeDescriptionChange.bind(this);
        this.onTypeCommandChange = this.onTypeCommandChange.bind(this);
        this.onTypeMeanOfPaymentChange = this.onTypeMeanOfPaymentChange.bind(this);
        this.buildMeansPayment = this.buildMeansPayment.bind(this);
    }

    componentDidUpdate(prevProps) {
        let reset = (this.props.filter.lastFilterRestTime &&
            (!prevProps.filter.lastFilterRestTime || prevProps.filter.lastFilterRestTime < this.props.filter.lastFilterRestTime));
        if (reset) {
            let sDate = new Date();
            sDate.setDate(1);
            sDate.setHours(0);
            sDate.setMinutes(0);
            sDate.setSeconds(0);
            let eDate = new Date();
            eDate.setHours(23);
            eDate.setMinutes(59);
            eDate.setSeconds(59);
            this.setState({
                locale: getCalendarLocale(),
                startDate: sDate,
                endDate: eDate,
                transactionTypes: this.props.filter.transactionTypes,
                showAllAlarm: this.props.filter.showAllAlarm,
                showOnlyOpenedAlarm: this.props.filter.showOnlyOpenedAlarm,
                mandated: this.props.filter.mandated,
                butonRadioChecked: this.props.filter.butonRadioChecked,
                commandsTypes: this.props.filter.commandsTypes,
                meanOfPaymentTypes: this.props.filter.meanOfPaymentTypes,
                eventTypes: this.props.filter.eventTypes,
                sortDirection: this.props.filter.sortDirection
            });
        }
        if(!prevProps.periodStartDays && this.props.periodStartDays){
            let sDate = new Date();
            sDate.setDate(sDate.getDate() - this.props.periodStartDays);
            sDate.setHours(0);
            sDate.setMinutes(0);
            sDate.setSeconds(0);
            this.setState({ startDate: sDate });
        }
    }

    onTypeDescriptionChange(e) {
        let selectedTransactionTypes = [...this.state.transactionTypes];
        if (e.checked)
            selectedTransactionTypes.push(e.value);
        else
            selectedTransactionTypes.splice(selectedTransactionTypes.indexOf(e.value), 1);
        this.setState({ transactionTypes: selectedTransactionTypes });
    }

    onTypeCommandChange(e) {
        if (e.value === 'true') {
            this.setState({commandsTypes: this.state.commandsTypesProps})
        } else {
            this.setState({commandsTypes: []})
        }
    }

    onTypeMeanOfPaymentChange(e) {
        let selectedMeanOfPaymentTypes = [...this.state.meanOfPaymentTypes];
        if (e.checked)
            selectedMeanOfPaymentTypes.push(e.value);
        else
            selectedMeanOfPaymentTypes.splice(selectedMeanOfPaymentTypes.indexOf(e.value), 1);
        this.setState({ meanOfPaymentTypes: selectedMeanOfPaymentTypes });
    }

    apply() {
         if (this.state.startDate > this.state.endDate) {
            this.setState({isEndDateAfterStartDate: true});
            return;
        }
        this.setState({isEndDateAfterStartDate: false});
        this.props.apply(this.buildFilter());
    }

    buildFilter() {
        //If the calendar time enabled remove this
        if (this.state.startDate && this.state.endDate) {
            this.state.startDate.setHours(0);
            this.state.startDate.setMinutes(0);
            this.state.startDate.setSeconds(0);
            this.state.endDate.setHours(23);
            this.state.endDate.setMinutes(59);
            this.state.endDate.setSeconds(59);
        }
        return {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            transactionTypes: this.state.transactionTypes,
            showAllAlarm: this.state.showAllAlarm,
            showOnlyOpenedAlarm: this.state.showOnlyOpenedAlarm,
            mandated: this.state.mandated,
            commandsTypes: this.state.commandsTypes,
            meanOfPaymentTypes: this.state.meanOfPaymentTypes,
            eventTypes: this.state.eventTypes,
            sortDirection: this.state.sortDirection
        };
    }

    buildMeansPayment() {
        if (this.props.borneType === 'TK2') {
            return [
                { label: msg('equipment.configuration.meansOfPayment.cb'), value: 'CB' },
                { label: msg('equipment.configuration.meansOfPayment.tw'), value: 'TW' },
                { label: msg('equipment.configuration.meansOfPayment.cash'), value: 'CASH' },
            ];
        } else if (this.props.borneType === 'TL16' || this.props.borneType === 'TL17') {
            return [
                { label: msg('equipment.configuration.meansOfPayment.cb'), value: 'CB' },
                { label: msg('equipment.configuration.meansOfPayment.tw'), value: 'TW' },
                { label: msg('equipment.configuration.meansOfPayment.pjegr'), value: 'PJEGR' },
                { label: msg('equipment.configuration.meansOfPayment.gr'), value: 'GR' },
                { label: msg('equipment.configuration.meansOfPayment.paidCode'), value: 'PAID_CODE' },
                { label: msg('equipment.configuration.meansOfPayment.paidAppCode'), value: 'PAID_APP_CODE' },
                { label: msg('equipment.configuration.meansOfPayment.freeCode'), value: 'FREE_CODE' },
                { label: msg('equipment.configuration.meansOfPayment.happyHour'), value: 'HAPPY_HOUR' },
                { label: msg('equipment.configuration.meansOfPayment.cash'), value: 'CASH' },
                { label: msg('equipment.configuration.meansOfPayment.maintenance'), value: 'MAINTENANCE' }
            ];
        } else if (this.props.borneType === 'TL18') {
            return [
                { label: msg('equipment.configuration.meansOfPayment.cb'), value: 'CB' },
                { label: msg('equipment.configuration.meansOfPayment.tw'), value: 'TW' },
                { label: msg('equipment.configuration.meansOfPayment.pjegr'), value: 'PJEGR' },
                { label: msg('equipment.configuration.meansOfPayment.gr'), value: 'GR' },
                { label: msg('equipment.configuration.meansOfPayment.paidCode'), value: 'PAID_CODE' },
                { label: msg('equipment.configuration.meansOfPayment.paidAppCode'), value: 'PAID_APP_CODE' },
                { label: msg('equipment.configuration.meansOfPayment.freeCode'), value: 'FREE_CODE' },
                { label: msg('equipment.configuration.meansOfPayment.happyHour'), value: 'HAPPY_HOUR' },
                { label: msg('equipment.configuration.meansOfPayment.cash'), value: 'CASH' },
                { label: msg('equipment.configuration.meansOfPayment.maintenance'), value: 'MAINTENANCE' },
                { label: msg('equipment.configuration.meansOfPayment.licensePlate'), value: 'LICENSE_PLATE' }
            ];
        }
    }

    render() {

        const meansOfPayments = this.buildMeansPayment();

        const eventBornes = [
            { label: msg('type.periph.gantry'), value: 'GANTRY' },
            { label: msg('type.periph.bank.card.reader'), value: 'BANK_CARD_READER' },
            { label: msg('type.periph.user.card.reader'), value: 'USER_CARD_READER' },
            { label: msg('type.periph.bill.reader'), value: 'BILL_READER' },
            { label: msg('type.periph.coin.acceptor'), value: 'COIN_ACCEPTOR' },
            { label: msg('type.periph.hopper'), value: 'HOPPER' },
            { label: msg('type.periph.card.dispenser'), value: 'CARD_DISPENSER' },
            { label: msg('type.periph.printer'), value: 'PRINTER' },
            { label: msg('type.periph.coin.changer'), value: 'COIN_CHANGER' },
            { label: msg('type.periph.terminal.it.self'), value: 'TERMINAL_ITSELF' },
            { label: msg('type.periph.scan.code.reader'), value: 'SCAN_CODE_READER' },
            { label: msg('type.periph.relay.board'), value: 'RELAY_BOARD' },
            { label: msg('type.periph.network'), value: 'NETWORK' },
            { label: msg('type.periph.jetWash'), value: 'JET_WASH' },
            { label: msg('type.periph.unknown'), value: 'UNKNOWN' },
            { label: msg('type.periph.led.controler'), value: 'LED_CONTROLER' },
            { label: msg('type.periph.light.sensor'), value: 'LIGHT_SENSOR' }

        ];

        return (
            <div className="p-grid filter">
                {this.props.showDate && (<div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-1 p-md-1"><span>{msg('filter.date.from')}</span></div>
                        <div className="p-col-2 p-md-2">
                            <Calendar dateFormat='dd/mm/yy' showIcon={false} selectOtherMonths={true} hideOnDateTimeSelect={true}
                                value={this.state.startDate}
                                onChange={(e) => {if (!this.props.readOnly) this.setState({ startDate: e.value })}} readOnlyInput={this.props.readOnly} locale={this.state.locale} />
                        </div>
                        <div className="p-col-1 p-md-1"><span></span></div>
                        <div className="p-col-1 p-md-1 " ><span>{msg('filter.date.to')}</span></div>
                        <div className="p-col-1 p-md-1" style={{display:'flex', flexDirection:'column', minWidth:'max-content'}}>
                            <Calendar dateFormat='dd/mm/yy' showIcon={false} selectOtherMonths={true} hideOnDateTimeSelect={true}
                                value={this.state.endDate}
                                onChange={(e) => {if (!this.props.readOnly) this.setState({ endDate: e.value })}} readOnlyInput={this.props.readOnly} locale={this.state.locale} />
                            {this.state.isEndDateAfterStartDate && <Message severity="error" text={msg('station.fields.contract.endDate.after.startDate')} />}                        
                        </div>
                        <br></br>
                        <br></br>
                        <div className="p-col-5 p-md-5 "><span></span></div>
                        <div className="p-col-12 p-md-12">
                            <Fieldset legend="Transactions" toggleable={true}>
                                <div className="p-col-12 p-grid">
                                    <div className="p-col-3 p-md-3">
                                        <Checkbox inputId="cb1" value="S" onChange={this.onTypeDescriptionChange} checked={this.state.transactionTypes.includes('S')}  ></Checkbox>
                                        <label className="p-checkbox-label"  > {msg('equipements.filter.ventes')}</label>
                                    </div>
                                    <div className="p-col-3 p-md-3">
                                        <Checkbox inputId="cb2" value="P" onChange={this.onTypeDescriptionChange} checked={this.state.transactionTypes.includes('P')}></Checkbox>
                                        <label className="p-checkbox-label">{msg('equipements.filter.prestations')}</label>
                                    </div>
                                    <div className="p-col-3 p-md-3">
                                        <Checkbox checked={this.state.mandated} onChange={e => this.setState({ mandated: e.checked })} />
                                        <label className="p-checkbox-label">{msg('equipements.filter.sous.mandat')}</label>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    {this.state.transactionTypes.length > 0 &&
                                        <div className="p-col-12 p-md-12 p-grid" >
                                            <div className="p-col-1 p-md-3" >
                                                <label className="p-checkbox-label">{msg('equipements.filter.byMeanPaimentType')}</label>
                                            </div>
                                            <div className="p-col-11 p-md-9" >
                                                <MultiSelect id="maensPayment" value={this.state.meanOfPaymentTypes}
                                                    options={meansOfPayments} style={{ minWidth: '60%', marginLeft: "20px" }}
                                                    onChange={e => this.setState({ meanOfPaymentTypes: e.target.value })}
                                                    placeholder={msg('equipment.filtre.meansOfPayment')}
                                                    onKeyUp={(e) => { if (e.keyCode === 13) this.doOnSave() }}
                                                    selectedItemsLabel={this.state.meanOfPaymentTypes.length + " " + msg('subscription.maintenance.selected.items')} />
                                            </div>
                                        </div>
                                    }
                                    {/*<div className="p-datatable-globalfilter-container" style={{ float: 'left' }}>
                                        <InputText size="30" onChange={(e) => this.setState({idSearch: e.target.value })} onKeyUp={this.apply}
                                            placeholder={this.props.idTxtPlaceholder} />
                                        <i className="topbar-icon material-icons pointer" >search</i>
                                </div>*/}
                                </div>
                            </Fieldset>
                        </div>
                        <div className="p-col-12 p-md-12">
                            <Fieldset legend="Alarmes" toggleable={true} collapsed={true}>
                                <div className="p-col-12 p-grid">
                                    <div className="p-col-3 p-md-3">
                                        <RadioButton inputId="rb1" name="alarme" value="true" onChange={(e) => { this.setState({ showAllAlarm: e.value }); this.setState({ butonRadioChecked: 'all', showOnlyOpenedAlarm: false }) }} checked={this.state.butonRadioChecked === 'all'} />
                                        <label className="p-radiobutton-label">{msg('equipements.filter.toutes')} </label>
                                    </div>
                                    <div className="p-col-3 p-md-3">
                                        <RadioButton inputId="rb2" name="alarme" value="true" onChange={(e) => { this.setState({ showOnlyOpenedAlarm: e.value }); this.setState({ butonRadioChecked: 'onlyOpenedAlarm' }); this.setState({ showAllAlarm: false }) }} checked={this.state.butonRadioChecked === 'onlyOpenedAlarm'} />
                                        <label className="p-radiobutton-label">{msg('equipements.filter.ouvertes')}  </label>
                                    </div>
                                    <div className="p-col-3 p-md-3">
                                        <RadioButton inputId="rb3" name="alarme" value="false" onChange={(e) => { this.setState({ showAllAlarm: e.value }); this.setState({ butonRadioChecked: 'no' }); this.setState({ showOnlyOpenedAlarm: false }) }} checked={this.state.butonRadioChecked === 'no'} />
                                        <label className="p-radiobutton-label">{msg('equipements.filter.aucune')} </label>
                                    </div>
                                    <br></br>
                                    {this.state.butonRadioChecked !== 'no' &&
                                        <div className="p-col-12 p-md-12 p-grid">
                                            <div className="p-col-1 p-md-3" >
                                                <label className="p-checkbox-label">{msg('equipements.filter.terminal.events')}</label>
                                            </div>
                                            <div className="p-col-11 p-md-9" >
                                                <MultiSelect id="typeAlarme" value={this.state.eventTypes}
                                                    options={eventBornes}
                                                    onChange={e => this.setState({ eventTypes: e.target.value })}
                                                    placeholder={msg('equipment.filtre.evnements')}
                                                    style={{ minWidth: '60%', marginLeft: "20px" }}
                                                    onKeyUp={(e) => { if (e.keyCode === 13) this.doOnSave() }}
                                                    selectedItemsLabel={this.state.eventTypes.length + " " + msg('equipment.filtre.selected.items')} />
                                            </div>
                                        </div>
                                    }

                                </div>
                            </Fieldset>
                        </div>
                        <div className="p-col-12 p-md-12">
                            <Fieldset legend="Commandes" toggleable={true} collapsed={true}>
                                <div className="p-col-12 p-grid">
                                    <div className="p-col-3 p-md-3">
                                        <RadioButton inputId="rb4" name="commande" value="true" onChange={(e) => { this.onTypeCommandChange(e) }} checked={this.state.commandsTypes.length > 0} />
                                        <label className="p-radiobutton-label">{msg('equipements.filter.toutes')} </label>
                                    </div>
                                    <div className="p-col-3 p-md-3">
                                        <RadioButton inputId="rb5" name="commande" value="false" onChange={(e) => { this.onTypeCommandChange(e) }} checked={this.state.commandsTypes.length === 0} />
                                        <label className="p-radiobutton-label">{msg('equipements.filter.aucune')} </label>
                                    </div>

                                </div>
                            </Fieldset>
                        </div>
                        <div className="p-col-12 apply-btn">
                            <Button label={msg('filter.apply')} title={msg('filter.apply')} onClick={this.apply} />
                        </div>
                    </div>
                </div>)}
            </div>);
    }
}

export default BorneActivityFilter;