import { Button } from "primereact/button";
import msg from "../../../texts/msg";
import { Chips } from "primereact/chips";
import { Message } from "primereact/message";
import { Component, Fragment } from "react";
import { InputText } from "primereact/inputtext";

export class Destination extends Component {
    
    render() {
        let isApplication = this.props.type === 'application';
        let header = isApplication ? msg("subscription.maintenance.application.id") : msg("subscription.maintenance.recipien");
        let destinationInput = this.props.type === 'application' ? <InputText className="inputClass" id="destValueId" type="text" size="30" maxLength="30"
            value={this.props.value} onChange={this.props.onChange}/> : <Chips value={this.props.value} onChange={this.props.onChange} />;        
        return (<div className="p-grid  p-col-12">
            <div className="p-col-6"><h3>{header} <span className="obligatoire">*</span> </h3></div>
            <div className="p-col-6">
                {!isApplication && 
                <Fragment>
                    <Button type="button" onClick={this.props.send} icon="pi-md-send" className="but" tooltip={msg("notification.send.email")} style={{marginTop: "11px", float: "left"}} 
                        disabled={this.props.disabled} />
                    <span style={{marginTop:"18px"}}>{msg("subscription.maintenance.button.send")}</span>
                </Fragment>}
            </div>
            <div className="p-grid p-col-12"><span/></div>
            <div className="p-grid p-col-12"><span className="infos">{msg("subscription.maintenance.entry.message") + " "}</span></div>
            <div className="card card-w-title p-col-12">
                <div className="p-col-12">
                    {destinationInput}
                    {this.props.error && <Message severity="error" text={this.props.error} key="destinationKey" />}
                </div>
            </div>
        </div>);
    }
}