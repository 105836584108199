import React, { Component } from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ScrollPanel } from 'primereact/components/scrollpanel/ScrollPanel';
import { Dialog } from 'primereact/dialog';
import './ui/ripple';
import { CartadisTopbar } from './ui/CartadisTopbar';
import { CartadisMenu } from './ui/CartadisMenu';
import { CartadisFooter } from './ui/CartadisFooter';
import { CartadisBreadcrumb } from './ui/CartadisBreadcrumb';
import { CartadisInlineProfile } from './ui/CartadisInlineProfile';
import { CartadisRightPanel } from './ui/CartadisRightPanel';
import { windowUtils } from './ui/WindowUtils';
import { LoginPage } from './components/login-form/LoginPage';
import Maintenance from './components/maintenance/dashboard/Maintenance';
import { Administration } from './components/administration/Administration';
import { AdminEquipments } from './components/administration/equipment/AdminEquipments';
import { Equipments } from './components/maintenance/equipment/Equipments';
import Bornes from './components/support/Bornes';
import { ActivityJornal } from './components/maintenance/equipment/ActivityJornal'
import Commerce from './components/commerce/Commerce';
import { Server } from './components/server/Server';
import { Stations } from './components/administration/station/Stations';
import { CreateStation } from './components/administration/station/CreateStation';
import { UpdateStation } from './components/administration/station/UpdateStation';
import { Users } from './components/administration/user/Users';
import { Roles } from './components/administration/roles/Roles';
import { CreateRole } from './components/administration/roles/CreateRole';
import { UpdateRole } from './components/administration/roles/UpdateRole';
import { CreationUser } from './components/administration/user/CreationUser';
import { UpdateUser } from './components/administration/user/UpdateUser';
import { PasswordUpdate } from './components/administration/user/PasswordUpdate';
import { Reporting } from './components/reporting/Reporting';
import { ReportingExploitant } from './components/reporting/ReportingExploitant';
import Notifications from './components/maintenance/notification/Notifications.js';
import { NotificationUpdate } from './components/maintenance/notification/NotificationUpdate.js';
import { NotificationCreate } from './components/maintenance/notification/NotificationCreate.js';
import Campaign from './components/administration/campaign/Campaign';
import { CreateFirmware } from './components/administration/firmware/CreateFirmware';
import Commission from './components/administration/commission/Commission';
import { Groups } from './components/administration/group/Groups';
import { Hardware } from './components/administration/hardware/Hardware';
import { CreateHardware } from './components/administration/hardware/CreateHardware';
import { UpdateHardware } from './components/administration/hardware/UpdateHardware';
import { PriceZones } from './components/administration/pricezone/PriceZones';
import { CreateGroup } from './components/administration/group/CreateGroup';
import UpdateGroup from './components/administration/group/UpdateGroup';
import CreateEquipment from './components/administration/equipment/CreateEquipment';
import UpdateEquipment from './components/administration/equipment/UpdateEquipment';
import StationGroup from './components/administration/stationgroup/StationGroup';
import { login, logout, loadRoleFeatures, redirectToProvider, redirectToApiServer } from './middleware/actions/login';
import {
    loadFilter, loadFilterStations, loadFilterStationsWithContracts, loadSystemEventsFilter, loadFilterExploitantsReports,
    loadFilterDodoStations
} from './middleware/actions/filter';
import { commerceDashbord, commerceAnalysis, commerceExploitAnalysis } from './middleware/actions/commerce';
import { equipments, equipmentById, closeAlarm, qrCodeSendById, loadServiceInformations, loadOpenAlarms } from './middleware/actions/equipments';
import { adminequipments, createEquipment, equipmentDeleteById, equipment, equipmentUpdate, updateBorne } from './middleware/actions/adminequipments';
import { reboot, getBorneLogs, loadBornes, checkNetworkSpeed, disassociateBorne } from './middleware/actions/borne';
import { configMateriels, configMateriel, configMaterielDeleteById } from './middleware/actions/hardwares';
import { firmwares, firmware, firmwareDeleteById } from './middleware/actions/firmwares';
import { users, user, addUser, loadGroups, userDeleteById, passwordUpdate, myPasswordUpdate, userUpdate, generateBorneAccessCode} from './middleware/actions/user';
import { authorisations, addRole, loadRole, roles, roleDeleteById, role, roleUpdate } from './middleware/actions/role';
import { systemEvents } from './middleware/actions/server';
import { groups, groupById, addGroup, groupDeleteById, group, groupUpdate } from './middleware/actions/group';
import { pricezones, pricezoneById, deletePricezone } from './middleware/actions/pricezone';
import {
    loadStations, loadStationsMap, addStation, stationUpdate, changeStationContract,
    stationDeleteById, station, updateConfigStation, loadStationPriceZones, loadStationCommissions
} from './middleware/actions/station'
import {
    loadStationGroups, loadStationGroup, loadStationGroupStations,
    createStationGroup, updateStationGroup, deleteStationGroup
} from './middleware/actions/stationgroup';
import {
    notificationsLoad, notificationLoadById, notificationSend, notificationCreate,
    notificationUpdate, notificationDeleteById, loadDefaultSubscribers,
    loadCampaigns, loadCampaign, createCampaign, updateCampaign, launchCampaign, deleteCampaign,
    loadFirmwares, loadHardwares, loadPriceZones, loadStationEquipments, loadCampaignEvents,
    loadBornesStatus, loadBornesAnalysis
} from './middleware/actions/maintenance';
import {
    loadCommissions, loadCommission, createCommission,
    updateCommission, deleteCommission
} from './middleware/actions/commission';
import { onPageSizeChange, onFilterChange } from './middleware/actions/userSessionParams'
import { rolesValidator, TRADE, EQUIPMENTS, USERS, GROUPS, ROLE, MAINTENANCE, NOTIFICATIONS, ADMINEQUIPMENTS, STATIONS, TRADE_EXPLOIT } from './utils/RolesValidator';
import msg from './texts/msg.js';
import './CartadisMain.css';
import { Firmware } from './components/administration/firmware/Firmware';
import UpdateFirmware from './components/administration/firmware/UpdateFirmware';
import ProgressSpinnerDialog from './components/common/ProgressSpinnerDialog';
import { progressSpinnerHandler } from './utils/ProgressSpinnerHandler';
import { userSessionParamsHandler } from './utils/UserSessionParamsHandler';
import { authProviderType } from './middleware/AuthProviderType';

class CartadisMain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            connected: false,
            desktopLayoutStatic: true,
            layoutMode: 'slim',
            profileMode: 'top',
            display: false,
            closable: false,
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            rotateMenuButton: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            darkMenu: false,
            rightPanelActive: false,
            menuActive: false,
            selectItemMenu: null,
            breadcrumbData: null
        };

        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.onRightPanelButtonClick = this.onRightPanelButtonClick.bind(this);
        this.onRightPanelClick = this.onRightPanelClick.bind(this);
        this.getSelectItemFromMenu = this.getSelectItemFromMenu.bind(this);
        this.getDataInfoBar = this.getDataInfoBar.bind(this);
        this.setDataInfoBar = this.setDataInfoBar.bind(this);
        this.customize = this.customize.bind(this);

        this.onProfileClick = this.onProfileClick.bind(this);
        this.showProgress = this.showProgress.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.cancelUpdatePassword = this.cancelUpdatePassword.bind(this);

        this.onLogin = this.onLogin.bind(this);
        this.onLogout = this.onLogout.bind(this);
        this.doLoadRoleFeatures = this.doLoadRoleFeatures.bind(this);        
    }

    componentDidMount() {
        progressSpinnerHandler.init(this);
        if (document.location.pathname !== '/') {
            if(document.location.pathname === '/total-auth-provider') {
                this.props.redirectToApiServer(authProviderType.getAuthRegistrationID1());
                return;
            } else if(document.location.pathname === ('/' + authProviderType.getAuthRegistrationID1())) {
                this.props.redirectToApiServer(authProviderType.getAuthRegistrationID1());
                return;
            } else if(document.location.pathname === ('/' + authProviderType.getAuthRegistrationID2())) {
                this.props.redirectToApiServer(authProviderType.getAuthRegistrationID2());
                return;
            } else {
                document.location = '/';
                return;
            }
        }
        this.doLoadRoleFeatures('total');
        if (this.state.mobileMenuActive) {
            this.addClass(document.body, 'body-overflow-hidden');
        } else {
            this.removeClass(document.body, 'body-overflow-hidden');
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.redirectToProviderData && this.props.redirectToProviderData.redirectUrl 
                && this.props.redirectToProviderData !== prevProps.redirectToProviderData){
            authProviderType.setAuthType('total');
            window.location.replace(this.props.redirectToProviderData.redirectUrl);
			return;
        }
        if(this.props.redirectToApiServerData && (
            this.props.redirectToApiServerData.status === 200 || this.props.redirectToApiServerData.status === '200')
            && this.props.redirectToApiServerData !== prevProps.redirectToApiServerData){
                this.doLoadRoleFeatures('total');
            return;
        }
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error && (this.props.error.status === 401 || this.props.error.status === '401'
            || this.props.error.status === 403 || this.props.error.status === '403')) {
            this.setState({ connected: false });
            rolesValidator.setFeatures([]);
            rolesValidator.setCompanyTypes([]);
            return;
        } else if (!this.state.connected) {
            let prevChange = prevProps.loginData ? prevProps.loginData.lastChange : null;
            let lastChange = this.props.loginData ? this.props.loginData.lastChange : null;
            let connected = (lastChange && (!prevChange || prevChange < lastChange));
            if (connected) {
                userSessionParamsHandler.setOnPageSizeChange(this.props.onPageSizeChange);
                userSessionParamsHandler.setOnFilterChange(this.props.onFilterChange);
                userSessionParamsHandler.firePageSizeChange(50);
                this.setState({ connected: connected });
                rolesValidator.setFeatures(this.props.loginData.features);
                rolesValidator.setCompanyTypes(this.props.loginData.companyTypes);
                rolesValidator.setOutMandated(this.props.loginData.user.outMandated);
                let authProvider = this.props.loginData.user.authProvider.toLowerCase();
                authProviderType.setAuthType(authProvider === 'undefined' || authProvider === 'washadmin' ? 'cartadis' : 'total')
                this.redirectToUserHomePage();
            }
        }
        if (this.state.redirect) {
            this.setState({ redirect: null });
        }
        userSessionParamsHandler.updateParams(this.props, prevProps);
    }

    redirectToUserHomePage() {
        if (this.props.loginData && this.props.loginData.location) {
            switch (this.props.loginData.location.toLowerCase()) {
                case "trade":
                    let trade = { label: msg('menu.commerce.dashbord'), icon: "equalizer", to: "/commerce/dashboard", features: [TRADE] };
                    if (this.props.location !== "/commerce/dashboard") {
                        this.setState({ redirect: "/commerce/dashboard", selectItemMenu: trade });
                    };
                    break;
                case "trade_exploit":
                    let tradeExploit = { label: msg('menu.commerce.exploit'), icon: "account_box", to: "/commerce/exploit", features: [TRADE_EXPLOIT] };
                    if (this.props.location !== "/commerce/exploit") {
                        this.setState({ redirect: "/commerce/exploit", selectItemMenu: tradeExploit });
                    };
                    break;
                case "maintenance":
                    let maintenance = { label: msg('menu.maintenance.dashbord'), icon: 'network_check', to: "/maintenance/dashboard", features: [MAINTENANCE] };
                    if (this.props.location !== "/maintenance/dashboard") {
                        this.setState({ redirect: "/maintenance/dashboard", selectItemMenu: maintenance });
                    };
                    break;
                case "equipments":
                    let equipment = { label: msg('menu.admin.equipements'), icon: "network_check", to: "/", features: [EQUIPMENTS] };
                    if (this.props.location !== "/") {
                        this.setState({ redirect: "/", selectItemMenu: equipment });
                    };
                    break;
                case "notifications":
                    let notification = { label: msg('menu.maintenance.notification'), icon: 'notifications_active', to: "/notification", features: [NOTIFICATIONS] };
                    if (this.props.location !== "/notification") {
                        this.setState({ redirect: "/notification/mail", selectItemMenu: notification });
                    };
                    break;
                case "users":
                    let user = { label: msg('menu.admin.users'), icon: "person", to: "/users", features: [USERS] };
                    if (this.props.location !== "/users") {
                        this.setState({ redirect: "/users", selectItemMenu: user });
                    };
                    break;
                case "groups":
                    let group = { label: msg('menu.admin.groups'), icon: "group", to: "/groups", features: [GROUPS] };
                    if (this.props.location !== "/groups") {
                        this.setState({ redirect: "/groups", selectItemMenu: group });
                    };
                    break;
                case "stations":
                    let station = { label: msg('menu.admin.stations'), icon: "business", to: "/stations", features: [STATIONS] };
                    if (this.props.location !== "/stations") {
                        this.setState({ redirect: "/stations", selectItemMenu: station });
                    };
                    break;
                case "roles":
                    let role = { label: msg('menu.admin.roles'), icon: "recent_actors", to: "/roles", features: [ROLE] };
                    if (this.props.location !== "/roles") {
                        this.setState({ redirect: "/roles", selectItemMenu: role });
                    };
                    break;
                case "adminequipments":
                    let adminequipments = { label: msg('menu.admin.equipements'), icon: "dock", to: "/equipment", features: [ADMINEQUIPMENTS] };
                    if (this.props.location !== "/equipment") {
                        this.setState({ redirect: "/equipment", selectItemMenu: adminequipments });
                    };
                    break;
                case "campaigns":
                    let campaign = { label: msg('menu.maintenance.campaign'), icon: "system_update_alt", to: "/campaign/list" };
                    if (this.props.location !== "/campaign/list") {
                        this.setState({ redirect: "/campaign/list", selectItemMenu: campaign });
                    };
                    break;
                case "commissions":
                    let commission = { label: msg('menu.admin.commission'), icon: "donut_large", to: "/commissions/list" };
                    if (this.props.location !== "/commissions/list") {
                        this.setState({ redirect: "/commissions/list", selectItemMenu: commission });
                    };
                    break;
                case "firmware":
                    let firmware = { label: msg('menu.maintenance.firmware'), icon: "file_upload", to: "/firmware" };
                    if (this.props.location !== "/firmware") {
                        this.setState({ redirect: "/firmware", selectItemMenu: firmware });
                    };
                    break;
                case "hardware":
                    let hardware = { label: msg('menu.maintenance.hardware'), icon: "file_upload", to: "/hardwares" };
                    if (this.props.location !== "/hardwares") {
                        this.setState({ redirect: "/hardwares", selectItemMenu: hardware });
                    };
                    break;
                case "pricezones":
                    let pricezones = { label: msg('menu.admin.pricezone'), icon: "euro_symbol", to: "/pricezones" };
                    if (this.props.location !== "/pricezones") {
                        this.setState({ redirect: "/pricezones", selectItemMenu: pricezones });
                    };
                    break;
                case "reporting":
                    let reporting = { label: msg('menu.report.excel'), icon: "assignment", to: "/reporting/excel" };
                    if (this.props.location !== "/reporting/excel") {
                        this.setState({ redirect: "/reporting/excel", selectItemMenu: reporting });
                    };
                    break;
                case "financialmandate":
                    let financialmandate = { label: msg('menu.report.mandat'), icon: "monetization_on", to: "/reporting/mandate" };
                    if (this.props.location !== "/reporting/mandate") {
                        this.setState({ redirect: "/reporting/mandate", selectItemMenu: financialmandate });
                    };
                    break;
                    case "exploitantsreport":
                        let exploitantsreport = { label: msg('menu.report.exploitants'), icon: "assignment", to: "/reportingExploitant/daily" };
                        if (this.props.location !== "/reportingExploitant/daily") {
                            this.setState({ redirect: "/reportingExploitant/daily", selectItemMenu: exploitantsreport });
                        };
                        break;
                case "server":
                    let server = { label: msg('menu.server.systemevents'), icon: "settings", to: "/server/systemevents" };
                    if (this.props.location !== "/server/systemevents") {
                        this.setState({ redirect: "/server/systemevents", selectItemMenu: server });
                    };
                    break;
                case "stationgroups":
                    let stationgroups = { label: msg('menu.admin.station.groups'), icon: "business_center", to: "/stationgroups" };
                    if (this.props.location !== "/stationgroups") {
                        this.setState({ redirect: "/stationgroups", selectItemMenu: stationgroups });
                    };
                    break;
                default:
                    this.setState({ redirect: "/", selectItemMenu: equipment });
            }
        } else {
            let features = this.props.loginData && this.props.loginData.features ? this.props.loginData.features : [];
            let equipment = { label: msg('menu.admin.equipements'), icon: "dock", to: "/", features: [EQUIPMENTS] };
            let maintenance = { label: msg('menu.maintenance.dashbord'), icon: 'network_check', to: "/maintenance", features: [MAINTENANCE] };
            let commerce = { label: msg('menu.commerce.dashbord'), icon: "equalizer", to: "/commerce/dashboard", features: [TRADE] };
            let commerceExploit = { label: msg('menu.commerce.exploit'), icon: "account_box", to: "/commerce/exploit", features: [TRADE_EXPLOIT] };
            if (features.includes(EQUIPMENTS)) {
                if (this.props.location !== "/") {
                    this.setState({ redirect: "/", selectItemMenu: equipment });
                };
            } else if (features.includes(MAINTENANCE)) {
                if (this.props.location !== "/maintenance/dashboard") {
                    this.setState({ redirect: "/maintenance/dashboard", selectItemMenu: maintenance });
                };
            } else if (features.includes(TRADE)) {
                if (this.props.location !== "/commerce/dashboard") {
                    this.setState({ redirect: "/commerce/dashboard", selectItemMenu: commerce });
                };
            } else if (features.includes(TRADE_EXPLOIT)) {
                if (this.props.location !== "/commerce/exploit") {
                    this.setState({ redirect: "/commerce/exploit", selectItemMenu: commerceExploit });
                };
            } else {
                this.setState({ redirect: "/", selectItemMenu: equipment });
            }
        }
    }

    onLogin(username, password) {
        authProviderType.setAuthType('cartadis');
        this.props.login(username, password);
    }

    doLoadRoleFeatures(authType) {
        if(!authType){
            authType = 'total';
        }
        authProviderType.setAuthType(authType);
        this.props.loadRoleFeatures(authType);
    }

    onLogout() {
        this.props.logout();
        rolesValidator.setFeatures([]);
        this.setState({ connected: false });
        sessionStorage.clear();
    }

    onWrapperClick(event) {
        if (!this.menuClick) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            });
        }
        this.menuClick = false;
    }

    onToggleMenu(event) {
        this.menuClick = true;
        if (windowUtils.isDesktop()) {
            if (!this.state.desktopLayoutStatic) {
                if (this.state.layoutMode === 'overlay') {
                    this.setState({ overlayMenuActive: !this.state.overlayMenuActive });
                } else if (this.state.layoutMode === 'static') {
                    this.setState({ staticMenuInactive: !this.state.staticMenuInactive });
                }
            }
        } else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({ mobileMenuActive: !mobileMenuActive });
        }
        event.preventDefault();
    }

    onMenuClick(event) {
        this.menuClick = true;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.setState(({
            rotateMenuButton: !this.state.rotateMenuButton,
            topbarMenuActive: false
        }));
        if (this.state.layoutMode === 'overlay') {
            this.setState({
                overlayMenuActive: !this.state.overlayMenuActive
            });
        } else {
            if (windowUtils.isDesktop())
                this.setState({ staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive });
            else
                this.setState({ staticMenuMobileActive: !this.state.staticMenuMobileActive });
        }
        event.preventDefault();
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.setState({ topbarMenuActive: !this.state.topbarMenuActive });
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick(event) {
        this.topbarItemClick = true;
        if (this.state.activeTopbarItem === event.item) {
            this.setState({ activeTopbarItem: null });
        } else {
            this.setState({ activeTopbarItem: event.item });
        }
        event.originalEvent.preventDefault();
    }

    onMenuItemClick(event) {
        if (!event.item.items) {
            this.hideOverlayMenu();
        }
        if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
            this.setState({ menuActive: false })

        }
        if (event.item.items && !this.isHorizontal() && this.layoutMenuScroller) {
            setTimeout(() => { this.layoutMenuScroller.moveBar(); }, 500);
        }

        switch (event.item.label) {
            case msg('menu.commerce'):
            case msg('menu.maintenance'):
            case msg('menu.admin'):
            case msg('menu.server'):
                break;
            default:
                this.setState({ selectItemMenu: event.item })
        }

    }

    onRootMenuItemClick(event) {
        this.setState({ menuActive: !this.state.menuActive });
    }

    onRightPanelButtonClick(event) {
        this.rightPanelClick = true;
        this.setState({ rightPanelActive: !this.state.rightPanelActive });
        event.preventDefault();
    }

    onRightPanelClick(event) {
        this.rightPanelClick = true;
    }

    onDocumentClick(event) {
        if (!this.topbarItemClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }
        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.setState({ menuActive: false });
            }
            this.hideOverlayMenu();
        }
        if (!this.rightPanelClick) {
            this.setState({ rightPanelActive: false });
        }
        this.topbarItemClick = false;
        this.menuClick = false;
        this.rightPanelClick = false;
    }

    hideOverlayMenu() {
        this.setState({
            rotateMenuButton: false,
            overlayMenuActive: false,
            staticMenuMobileActive: false
        })
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.state.layoutMode === 'slim';
    }

    addClass(element, className) {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    removeClass(element, className) {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    getDataInfoBar() {
        return this.state.breadcrumbData;
    }
    getSelectItemFromMenu() {
        return this.state.selectItemMenu;
    }
    setDataInfoBar(dataInfoBar) {
        this.setState({ breadcrumbData: dataInfoBar });
    }

    customize(s) {
        this.setState(s);
    }
    updatePassword(userPassword) {
        this.setState({ userPassword: userPassword });
        this.props.myPasswordUpdate(userPassword);
        this.setState({ display: false });
    }
    cancelUpdatePassword(userPassword) {
        this.setState({ userPassword: userPassword });
        this.setState({ display: false });
    }
    onProfileClick() {
        this.setState({ display: true });
    }
    onHide(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    showProgress(show) {
        this.setState({ showProgress: show });
    }

    render() {
        let logedIn = this.state.connected;
        if (logedIn && this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        }

        let loginForm = !logedIn && (<LoginPage 
            onLogin={this.onLogin} 
            loginError={this.props.error}
            redirectToProvider={this.props.redirectToProvider}
            redirectToProviderData={this.props.redirectToProviderData}
        />);

        const layoutContainerClassName = classNames('layout-container', {
            'menu-layout-static': this.state.layoutMode !== 'overlay',
            'menu-layout-overlay': this.state.layoutMode === 'overlay',
            'layout-menu-overlay-active': this.state.overlayMenuActive,
            'menu-layout-slim': this.state.layoutMode === 'slim',
            'menu-layout-horizontal': this.state.layoutMode === 'horizontal',
            'layout-menu-static-inactive': this.state.staticMenuDesktopInactive,
            'layout-menu-static-active': this.state.staticMenuMobileActive
        });
        let menuClassName = classNames('layout-menu', { 'layout-menu-dark': this.state.darkMenu });

        let iniPassword = (
            <Dialog visible={this.state.display} style={{ width: '80%' }} closable={this.state.closable} onHide={() => this.onHide('display')}
                modal={false}>
                {this.state.display && <PasswordUpdate username={this.props.loginData ? (this.props.loginData.user ? this.props.loginData.user.firstname : null) : null}
                    apply={this.updatePassword} cancel={this.cancelUpdatePassword} showDate={true} />}
            </Dialog>
        );

        let CartadisBreadcrumbWithRouter = withRouter(CartadisBreadcrumb);
        let cartadisBreadcrumb = logedIn && (<CartadisBreadcrumbWithRouter getDataInfoBar={this.getDataInfoBar}
            getSelectItemMenu={(el) => this.state.selectItemMenu} onLogout={this.onLogout} />);
        let cartadisInlineProfile = logedIn && (<CartadisInlineProfile onLogout={this.onLogout} user={this.props.loginData ? this.props.loginData.user : null} />);
        let cartadisMenu = logedIn && (<CartadisMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} onRootMenuItemClick={this.onRootMenuItemClick}
            customize={this.customize} layoutMode={this.state.layoutMode} active={this.state.menuActive} />);

        let equipmentRoute = logedIn && (<Route path={["/", "/borne-status/:status", "/station-map/:station"]} exact
            render={(props) => <Equipments {...props}
                loadEquipments={this.props.equipments}
                equipments={this.props.equipmentsData}
                loadEquipmentById={this.props.equipmentById}
                equipmentById={this.props.equipmentByIdData}
                loadCloseAlarm={this.props.closeAlarm}
                closeAlarmData={this.props.alarmCloseData}
                loadFilter={this.props.loadFilter}
                filterData={this.props.loadFilterData}
                loadFilterStations={this.props.loadFilterStations}
                filterStations={this.props.loadFilterStationsData}
                loadSendQrCodeById={this.props.qrCodeSendById}
                sentQrCodeByIdData={this.props.qrCodeSendByIdData}
                loadServiceInformations = {this.props.loadServiceInformations}
                loadServiceInformationsData = {this.props.loadServiceInformationsData}
                loadOpenAlarms = {this.props.loadOpenAlarms}
                openAlarmsData = {this.props.loadOpenAlarmsData}
                error={this.props.error}
                setDataInfoBar={this.setDataInfoBar}
            />} />);

            let borneRoute = logedIn && (<Route path={["/support/borne"]}
            render={(props) => <Bornes {...props}
                disassociateBorne={this.props.disassociateBorne}
                disassociateBorneData={this.props.disassociateBorneData}
                loadBornes={this.props.loadBornes}
                bornesData={this.props.loadBornesData}
                error={this.props.error}
                setDataInfoBar={this.setDataInfoBar}
            />} />);


        let activityJornalRoute = logedIn && (<Route path="/actitvityJournal" render={(props) => <ActivityJornal {...props}
            loadEquipmentById={this.props.equipmentById}
            equipmentById={this.props.equipmentByIdData}
            closeAlarm={this.props.closeAlarm}
            closeAlarmData={this.props.alarmCloseData}
            loadFilter={this.props.loadFilter}
            filterData={this.props.loadFilterData}
            rebootData={this.props.rebootData}
            checkNetworkSpeedData={this.props.checkNetworkSpeedData}
            reboot={this.props.reboot}
            checkNetworkSpeed={this.props.checkNetworkSpeed}
            borneLogsData={this.props.borneLogsData}
            getBorneLogs={this.props.getBorneLogs}
            setDataInfoBar={this.setDataInfoBar}
            error={this.props.error}
        />} />);

        let equipmentAdminRoute = logedIn && (<Route path="/equipment" exact
            render={(props) => <AdminEquipments {...props}
                loadAdminequipments={this.props.adminequipments}
                adminequipments={this.props.adminequipmentsData}
                deleteEquipment={this.props.equipmentDeleteById}
                equipmentDeleteById={this.props.equipmentDeleteByIdData}
                loadFilter={this.props.loadFilter}
                filterData={this.props.loadFilterData}
                loadEquipment={this.props.equipment}
                loadFilterStations={this.props.loadFilterStations}
                filterStations={this.props.loadFilterStationsData}
                updateBorneData={this.props.updateBorneData}
                updateBorne={this.props.updateBorne}
                error={this.props.error}
                setDataInfoBar={this.setDataInfoBar}
            />} />);

        let createEquipmentRoute = logedIn && (<Route path="/equipment/create" render={(props) => <CreateEquipment {...props}
            createEquipmentData={this.props.createEquipmentData}
            createEquipment={this.props.createEquipment}
            loadFirmwaresData={this.props.loadFirmwaresData}
            loadFirmwares={this.props.loadFirmwares}
            loadHardwaresData={this.props.loadHardwaresData}
            loadHardwares={this.props.loadHardwares}
            loadStations={this.props.loadStations}
            stations={this.props.loadStationsData}
            station={this.props.station}
            stationData={this.props.stationData}
            setDataInfoBar={this.setDataInfoBar}
            error={this.props.error}
        />} />);

        let updateEquipmentRoute = logedIn && (<Route path="/equipment/update" render={(props) => <UpdateEquipment {...props}
            loadFirmwaresData={this.props.loadFirmwaresData}
            loadFirmwares={this.props.loadFirmwares}
            loadHardwaresData={this.props.loadHardwaresData}
            loadHardwares={this.props.loadHardwares}
            setDataInfoBar={this.setDataInfoBar}
            error={this.props.error}
            equipment={this.props.equipmentData}
            updateEquipment={this.props.equipmentUpdate}
            equipmentUpdate={this.props.equipmentUpdateData}
            loadStations={this.props.loadStations}
            stations={this.props.loadStationsData}
            station={this.props.station}
            stationData={this.props.stationData}
        />} />);

        let maintRoute = logedIn && (<Route path="/maintenance/:submenu" render={(props) => <Maintenance {...props}
            loadBornesStatus={this.props.loadBornesStatus}
            bornesStatusData={this.props.bornesStatusData}
            loadBornesAnalysis={this.props.loadBornesAnalysis}
            bornesAnalysisData={this.props.bornesAnalysisData}
            loadStationsMap={this.props.loadStationsMap}
            stationsMapData={this.props.loadStationsMapData}
            loadFilter={this.props.loadFilter}
            filterData={this.props.loadFilterData}
            loadFilterStations={this.props.loadFilterStations}
            filterStations={this.props.loadFilterStationsData}
            setDataInfoBar={this.setDataInfoBar}
        />} />);

        let notificationRoute = logedIn && (<Route path="/notification/:submenu" render={(props) => <Notifications {...props}
            loadNotifications={this.props.notificationsLoad}
            notifications={this.props.notificationsData}
            loadNotificationById={this.props.notificationLoadById}
            notificationByIdData={this.props.notificationByIdData}
            createNotification={this.props.notificationCreate}
            createNotificationData={this.props.notificationCreateData}
            updateNotification={this.props.notificationUpdate}
            updateNotificationData={this.props.notificationUpdateData}
            deleteNotification={this.props.notificationDeleteById}
            deleteNotificationData={this.props.notificationDeleteByIdData}
            sendNotification={this.props.notificationSend}
            sentNotificationData={this.props.notificationSendData}
            loadSubscribers={this.props.loadDefaultSubscribers}
            setDataInfoBar={this.setDataInfoBar}
        />} />);

        let notificationUpdateRoute = logedIn && (<Route path="/notificationUpdate/:submenu" render={(props) => <NotificationUpdate {...props}
            loadNotificationById={this.props.notificationLoadById}
            notificationByIdData={this.props.notificationByIdData}
            createNotification={this.props.notificationCreate}
            createNotificationData={this.props.notificationCreateData}
            updateNotification={this.props.notificationUpdate}
            updateNotificationData={this.props.notificationUpdateData}
            deleteNotification={this.props.notificationDeleteById}
            deleteNotificationData={this.props.notificationDeleteByIdData}
            subscribersData={this.props.subscribersData}
            sendNotification={this.props.notificationSend}
            sentNotificationData={this.props.notificationSendData}
            loadStations={this.props.loadFilterStations}
            stationsData={this.props.loadFilterStationsData}
            setDataInfoBar={this.setDataInfoBar}
        />} />);

        let notificationCreateRoute = logedIn && (<Route path="/notificationCreate/:submenu" render={(props) => <NotificationCreate {...props}
            createNotification={this.props.notificationCreate}
            createNotificationData={this.props.notificationCreateData}
            updateNotification={this.props.notificationUpdate}
            updateNotificationData={this.props.notificationUpdateData}
            deleteNotification={this.props.notificationDeleteById}
            deleteNotificationData={this.props.notificationDeleteByIdData}
            subscribersData={this.props.subscribersData}
            sendNotification={this.props.notificationSend}
            sentNotificationData={this.props.notificationSendData}
            setDataInfoBar={this.setDataInfoBar}
            loadSystemEventsFilter={this.props.loadSystemEventsFilter}
            systemEventsFilterData={this.props.loadSystemEventsFilterData}
            loadStations={this.props.loadFilterStations}
            stationsData={this.props.loadFilterStationsData}
        />} />);


        let commRoute = logedIn && (<Route path="/commerce/:submenu"
            render={(props) => <Commerce {...props}
                loadDashbord={this.props.commerceDashbord}
                loadAnalysis={this.props.commerceAnalysis}
                loadExploitAnalysis={this.props.commerceExploitAnalysis}
                dashbord={this.props.commerceDashbordData}
                analysis={this.props.commerceAnalysisData}
                exploitAnalysis={this.props.commerceExploitAnalysisData}
                loadFilter={this.props.loadFilter}
                filterData={this.props.loadFilterData}
                loadFilterStations={this.props.loadFilterStations}
                filterStations={this.props.loadFilterStationsData}
                loadStationsMap={this.props.loadStationsMap}
                stationsMapData={this.props.loadStationsMapData}
                setDataInfoBar={this.setDataInfoBar}
            />} />);

        let stationGroupRoute = logedIn && (<Route path="/stationgroups" render={(props) => <StationGroup {...props}
            stationGroupsData={this.props.loadStationGroupsData}
            loadStationGroups={this.props.loadStationGroups}
            stationGroupData={this.props.loadStationGroupData}
            loadStationGroup={this.props.loadStationGroup}
            stationsData={this.props.loadStationGroupStationsData}
            loadStations={this.props.loadStationGroupStations}
            createStationGroupData={this.props.createStationGroupData}
            createStationGroup={this.props.createStationGroup}
            updateStationGroupData={this.props.updateStationGroupData}
            updateStationGroup={this.props.updateStationGroup}
            deleteStationGroupData={this.props.deleteStationGroupData}
            deleteStationGroup={this.props.deleteStationGroup}
            loadStation={this.props.station}
            loadStationData={this.props.stationData}
            setDataInfoBar={this.setDataInfoBar}
            error={this.props.error} />} />);

        let campaignRoute = logedIn && (<Route path="/campaign/:submenu" render={(props) => <Campaign {...props}
            borneAutoConfigEnabled={this.props.loginData ? this.props.loginData.borneAutoConfigEnabled : false}
            loadCampaignsData={this.props.loadCampaignsData}
            loadCampaigns={this.props.loadCampaigns}
            loadCampaignData={this.props.loadCampaignData}
            loadCampaign={this.props.loadCampaign}
            loadFirmwaresData={this.props.loadFirmwaresData}
            loadFirmwares={this.props.loadFirmwares}
            loadHardwaresData={this.props.loadHardwaresData}
            loadHardwares={this.props.loadHardwares}
            loadPriceZonesData={this.props.loadPriceZonesData}
            loadPriceZones={this.props.loadPriceZones}
            loadStationEquipmentsData={this.props.loadStationEquipmentsData}
            loadStationEquipments={this.props.loadStationEquipments}
            loadCampaignEvents={this.props.loadCampaignEvents}
            campaignEventsData={this.props.loadCampaignEventsData}
            createCampaignData={this.props.createCampaignData}
            createCampaign={this.props.createCampaign}
            updateCampaignData={this.props.updateCampaignData}
            updateCampaign={this.props.updateCampaign}
            launchCampaignData={this.props.launchCampaignData}
            launchCampaign={this.props.launchCampaign}
            deleteCampaignData={this.props.deleteCampaignData}
            deleteCampaign={this.props.deleteCampaign}
            setDataInfoBar={this.setDataInfoBar}
            loadEquipmentById={this.props.equipmentById}
            error={this.props.error} />} />);

        let commissionRoute = logedIn && (<Route path="/commissions/:submenu" render={(props) => <Commission {...props}
            loadCommissionsData={this.props.loadCommissionsData}
            loadCommissions={this.props.loadCommissions}
            loadCommissionData={this.props.loadCommissionData}
            loadCommission={this.props.loadCommission}
            createCommissionData={this.props.createCommissionData}
            createCommission={this.props.createCommission}
            updateCommissionData={this.props.updateCommissionData}
            updateCommission={this.props.updateCommission}
            launchCommissionData={this.props.launchCommissionData}
            launchCommission={this.props.launchCommission}
            deleteCommissionData={this.props.deleteCommissionData}
            deleteCommission={this.props.deleteCommission}
            setDataInfoBar={this.setDataInfoBar}
            error={this.props.error} />} />);

        let adminRoute = logedIn && (<Route path="/administration" component={Administration} />);


        let stationsRoute = logedIn && (<Route path="/stations" render={(props) => <Stations {...props}
            loadStations={this.props.loadStations}
            stations={this.props.loadStationsData}
            station={this.props.station}
            stationData={this.props.stationData}
            deleteStation={this.props.stationDeleteById}
            stationDeleteById={this.props.stationDeleteByIdData}
            loadFilter={this.props.loadFilter}
            filterData={this.props.loadFilterData}
            updateConfigStationData={this.props.updateConfigStationData}
            updateConfigStation={this.props.updateConfigStation}
            setDataInfoBar={this.setDataInfoBar}
            error={this.props.error} />} />);

        let stationCreatRoute = logedIn && (<Route path="/station/create" render={(props) => <CreateStation {...props}
            addStation={this.props.addStation}
            station={this.props.addStationData}
            loadCommissions={this.props.loadStationCommissions}
            commissionsData={this.props.stationCommissionsData}
            loadPriceZones={this.props.loadStationPriceZones}
            priceZonesData={this.props.stationPriceZonesData}
            loadFilter={this.props.loadFilter}
            filterData={this.props.loadFilterData}
            loadUsers={this.props.users}
            users={this.props.usersData}
            stationGroupsData={this.props.loadStationGroupsData}
            loadStationGroups={this.props.loadStationGroups}
            error={this.props.error} />} />);

        let stationUpdateRoute = logedIn && (<Route path="/station/update" render={(props) => <UpdateStation {...props}            
            loadCommissions={this.props.loadStationCommissions}
            commissionsData={this.props.stationCommissionsData}
            loadPriceZones={this.props.loadStationPriceZones}
            priceZonesData={this.props.stationPriceZonesData}
            loadStation={this.props.station}
            stationData={this.props.stationData}
            updateStation={this.props.stationUpdate}
            updateStationData={this.props.stationUpdateData}            
            changeContract={this.props.changeStationContract}
            changeContractData={this.props.changeStationContractData}
            loadFilter={this.props.loadFilter}
            filterData={this.props.loadFilterData}
            loadUsers={this.props.users}
            users={this.props.usersData}
            stationGroupsData={this.props.loadStationGroupsData}
            loadStationGroups={this.props.loadStationGroups}
            error={this.props.error} />} />);

        let firmwareRoute = logedIn && (<Route path="/firmwares"
            render={(props) => <Firmware {...props}
                loadFirmwares={this.props.firmwares}
                firmwares={this.props.firmwaresData}
                loadFirmware={this.props.firmware}
                firmware={this.props.firmwareData}
                deleteFirmware={this.props.firmwareDeleteById}
                firmwareDeleteById={this.props.firmwareDeleteByIdData}
                error={this.props.error}
                setDataInfoBar={this.setDataInfoBar}
            />}
        />);

        let firmwareCreateRoute = logedIn && (<Route path="/firmware/create"
            render={(props) => <CreateFirmware {...props}
                loadFirmwares={this.props.firmwares}
                firmwares={this.props.firmwaresData}
                error={this.props.error}
                setDataInfoBar={this.setDataInfoBar}
            />}
        />);

        let firmwareUpdateRoute = logedIn && (<Route path="/firmware/update"
            render={(props) => <UpdateFirmware {...props}
                loadFirmware={this.props.firmware}
                firmware={this.props.firmwareData}
                error={this.props.error}
                setDataInfoBar={this.setDataInfoBar}
            />}
        />);

        let hardwareRoute = logedIn && (<Route path="/hardwares"
            render={(props) => <Hardware {...props}
                loadConfigMateriels={this.props.configMateriels}
                configMateriels={this.props.configMaterielsData}
                loadConfigMateriel={this.props.configMateriel}
                configMateriel={this.props.configMaterielData}
                deleteConfigMateriel={this.props.configMaterielDeleteById}
                configMaterielDeleteById={this.props.configMaterielDeleteByIdData}
                error={this.props.error}
                setDataInfoBar={this.setDataInfoBar}
            />}
        />);

        let hardwareCreateRoute = logedIn && (<Route path="/hardware/create" render={(props) => <CreateHardware {...props}
            setDataInfoBar={this.setDataInfoBar}
            error={this.props.error}

        />} />);

        let hardwareUpdateRoute = logedIn && (<Route path="/hardware/update" render={(props) => <UpdateHardware {...props}
            loadConfigMateriel={this.props.configMateriel}
            configMateriel={this.props.configMaterielData}
            setDataInfoBar={this.setDataInfoBar}
            error={this.props.error}

        />} />);

        let rolesRoute = logedIn && (<Route path="/roles"
            render={(props) => <Roles {...props}
                loadRoles={this.props.roles}
                roles={this.props.rolesData}
                dRole={this.props.roleDeleteById}
                roleDeleteById={this.props.roleDeleteByIdData}
                loadGroup={this.props.group}
                loadRoleupdate={this.props.role}
                error={this.props.error}
                setDataInfoBar={this.setDataInfoBar}
            />}
        />);

        let createRolesRoute = logedIn && (<Route path="/createRole"
            render={(props) => <CreateRole {...props}
                authorisations={this.props.authorisations}
                authorisationsData={this.props.loadAuthorisationsData}
                loadRole={this.props.loadRole}
                roleData={this.props.loadRoleData}
                addRole={this.props.addRole}
                addRoleData={this.props.loadAddRoleData}
                setDataInfoBar={this.setDataInfoBar}
                error={this.props.error}
            />}
        />);

        let modificationRoleRoute = logedIn && (<Route path="/updateRole"
            render={(props) => <UpdateRole {...props}
                authorisations={this.props.authorisations}
                authorisationsData={this.props.loadAuthorisationsData}
                loadRole={this.props.loadRole}
                roleData={this.props.loadRoleData}
                role={this.props.roleData}
                updateRole={this.props.roleUpdate}
                roleUpdate={this.props.roleUpdateData}
                group={this.props.groupData}
                error={this.props.error}
            />} />);

        let utilisateursRoute = logedIn && (<Route path="/users"
            render={(props) => <Users {...props}
                adminUser={this.props.loginData ? this.props.loginData.user : null}
                loadUsers={this.props.users}
                users={this.props.usersData}
                loadUser={this.props.user}
                dUser={this.props.userDeleteById}
                userDeleteById={this.props.userDeleteByIdData}
                updatePassword={this.props.passwordUpdate}
                updatePasswordData={this.props.passwordUpdateData}
                error={this.props.error}
                setDataInfoBar={this.setDataInfoBar}
                uploadMaxFileSize={this.props.loginData ? this.props.loginData.uploadMaxFileSize : 2000000000}
            />}
        />);

        let pricezoneRoute = logedIn && (<Route path="/pricezones"
            render={(props) => <PriceZones {...props}
                loadPriceZones={this.props.pricezones}
                pricezones={this.props.pricezonesData}
                loadArticlesByPricezone={this.props.pricezoneById}
                pricezoneById={this.props.pricezoneByIdData}
                deletePricezone={this.props.deletePricezone}
                deletePricezoneData={this.props.deletePricezoneData}
                error={this.props.error}
                setDataInfoBar={this.setDataInfoBar}
            />}
        />);

        let groupsRoute = logedIn && (<Route path="/groups"
            render={(props) => <Groups {...props}
                loadGroups={this.props.groups}
                groups={this.props.groupsData}
                loadUserByGroup={this.props.groupById}
                groupById={this.props.groupByIdData}
                loadGroup={this.props.group}
                dGroup={this.props.groupDeleteById}
                groupDeleteById={this.props.groupDeleteByIdData}
                error={this.props.error}
                setDataInfoBar={this.setDataInfoBar}
            />}
        />);

        let creationGroupeRoute = logedIn && (<Route path="/createGroup"
            render={(props) => <CreateGroup {...props}
                loadRole={this.props.loadRole}
                roleData={this.props.loadRoleData}
                addGroups={this.props.addGroup}
                addGroup={this.props.addGroupData}
                stationGroupsData={this.props.loadStationGroupsData}
                loadStationGroups={this.props.loadStationGroups}
                error={this.props.error}
            />} />);

        let modificationGroupRoute = logedIn && (<Route path="/updateGroup"
            render={(props) => <UpdateGroup {...props}
                loadRole={this.props.loadRole}
                roleData={this.props.loadRoleData}
                group={this.props.groupData}
                updateGroup={this.props.groupUpdate}
                groupUpdate={this.props.groupUpdateData}
                stationGroupsData={this.props.loadStationGroupsData}
                loadStationGroups={this.props.loadStationGroups}
                error={this.props.error}
            />} />);

        let creationUtilisateurRoute = logedIn && (<Route path="/creationUser"
            render={(props) => <CreationUser {...props}
                adminUser={this.props.loginData ? this.props.loginData.user : null}
                loadGroups={this.props.loadGroups}
                groupsData={this.props.loadGroupsData}
                addUsers={this.props.addUser}
                addUser={this.props.addUserData}
                generateBorneAccessCode={this.props.generateBorneAccessCode}
                borneAccessCodeData={this.props.borneAccessCodeData}
                error={this.props.error}
                setDataInfoBar={this.setDataInfoBar}
            />} />);

        let modificationUtilisateurRoute = logedIn && (<Route path="/updateUser"
            render={(props) => <UpdateUser {...props}
                adminUser={this.props.loginData ? this.props.loginData.user : null}
                user={this.props.userData}
                loadGroups={this.props.loadGroups}
                groupsData={this.props.loadGroupsData}
                updateUser={this.props.userUpdate}
                userUpdate={this.props.userUpdateData}
                generateBorneAccessCode={this.props.generateBorneAccessCode}
                borneAccessCodeData={this.props.borneAccessCodeData}
                error={this.props.error}
                setDataInfoBar={this.setDataInfoBar}
            />} />);

        let serverRoute = logedIn && (<Route path="/server/:submenu"
            render={(props) => <Server {...props}
                loadSystemEvents={this.props.systemEvents}
                systemEvents={this.props.systemEventsData}
                setDataInfoBar={this.setDataInfoBar}
                loadSystemEventsFilter={this.props.loadSystemEventsFilter}
                systemEventsFilterData={this.props.loadSystemEventsFilterData}
            />} />);
        let reportRoute = logedIn && (<Route path="/reporting/:submenu"
            render={(props) => <Reporting {...props}
            loadFilter={this.props.loadFilter}
            filterData={this.props.loadFilterData}
            loadFilterStations={this.props.loadFilterStations}
            filterStations={this.props.loadFilterStationsData}
            setDataInfoBar={this.setDataInfoBar} />}
    />);

        let reportExploitantRoute = logedIn && (<Route path="/reportingExploitant/:submenu"
        render={(props) => <ReportingExploitant {...props}            
            loadFilter={this.props.loadFilter}
            filterData={this.props.loadFilterData}
            loadFilterStations={this.props.loadFilterStations}
            filterStations={this.props.loadFilterStationsData}
            filterDodoStations={this.props.loadFilterDodoStationsData}
            loadFilterDodoStations={this.props.loadFilterDodoStations}
            loadFilterExploitantsReports = {this.props.loadFilterExploitantsReports}
            exploitantReport = {this.props.exploitantReportData}
            loadFilterStationsWithContracts={this.props.loadFilterStationsWithContracts}
            filterStationsWithContracts={this.props.loadFilterStationsWithContractsData}
            setDataInfoBar={this.setDataInfoBar} />}
    />);

        let cartadisRightPanel = logedIn && <CartadisRightPanel expanded={this.state.rightPanelActive} onContentClick={this.onRightPanelClick} />;

        return (
            <div className="layout-wrapper" onClick={this.onDocumentClick}>
                <div ref={(el) => this.layoutContainer = el} className={layoutContainerClassName}>
                    <CartadisTopbar profileMode={this.state.profileMode} horizontal={this.isHorizontal()}
                        topbarMenuActive={this.state.topbarMenuActive} activeTopbarItem={this.state.activeTopbarItem}
                        onMenuButtonClick={this.onMenuButtonClick} onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
                        onTopbarItemClick={this.onTopbarItemClick} onRightPanelButtonClick={this.onRightPanelButtonClick}
                        onLogout={this.onLogout} logedIn={logedIn}
                        user={this.props.loginData ? this.props.loginData.user : null}
                        onProfileClick={this.onProfileClick} />

                    {logedIn && (<div className={menuClassName} onClick={this.onMenuClick}>
                        <ScrollPanel ref={(el) => this.layoutMenuScroller = el} style={{ height: '100%' }}>
                            <div className="menu-scroll-content">
                                {(this.state.profileMode === 'inline' && this.state.layoutMode !== 'horizontal') && cartadisInlineProfile}
                                {cartadisMenu}
                            </div>
                        </ScrollPanel>
                    </div>)}
                    <div className="layout-main">
                        {cartadisBreadcrumb}
                        <div className="layout-content">
                            <ProgressSpinnerDialog show={this.showProgress} />
                            {loginForm}
                            {equipmentRoute}
                            {borneRoute}
                            {commRoute}
                            {campaignRoute}
                            {stationGroupRoute}
                            {commissionRoute}
                            {maintRoute}
                            {adminRoute}
                            {utilisateursRoute}
                            {rolesRoute}
                            {creationUtilisateurRoute}
                            {modificationUtilisateurRoute}
                            {stationsRoute}
                            {serverRoute}
                            {reportRoute}
                            {reportExploitantRoute}
                            {notificationRoute}
                            {notificationCreateRoute}
                            {notificationUpdateRoute}
                            {iniPassword}
                            {groupsRoute}
                            {creationGroupeRoute}
                            {modificationGroupRoute}
                            {createRolesRoute}
                            {modificationRoleRoute}
                            {pricezoneRoute}
                            {firmwareRoute}
                            {createEquipmentRoute}
                            {updateEquipmentRoute}
                            {equipmentAdminRoute}
                            {hardwareRoute}
                            {hardwareCreateRoute}
                            {hardwareUpdateRoute}
                            {stationCreatRoute}
                            {stationUpdateRoute}
                            {firmwareCreateRoute}
                            {firmwareUpdateRoute}
                            {activityJornalRoute}
                        </div>
                    </div>
                    {cartadisRightPanel}
                    <div className="layout-mask"></div>
                </div>
                <div id="spacer"></div>
                <CartadisFooter />
            </div>
        );

    }
}

const mapStateToProps = (state) => {
    let loged = state.login ? state.login.logedIn : false;
    loged = loged ? loged : (state.loadRoleFeatures ? state.loadRoleFeatures.logedIn : false );
    let logedData = state.login && loged ? state.login.data : null;
    logedData = logedData ? logedData : (state.loadRoleFeatures ? state.loadRoleFeatures.data : null);
    return ({
        commerceDashbordData: state.commerceDashbord ? state.commerceDashbord.data : null,
        commerceAnalysisData: state.commerceAnalysis ? state.commerceAnalysis.data : null,
        commerceExploitAnalysisData: state.commerceExploitAnalysis ? state.commerceExploitAnalysis.data : null,
        exploitantReportData : state.loadFilterExploitantsReports ? state.loadFilterExploitantsReports.data : null,
        loadFilterData: state.loadFilter ? state.loadFilter.data : null,
        loadFilterStationsData: state.loadFilterStations ? state.loadFilterStations.data : null,
        loadFilterDodoStationsData: state.loadFilterDodoStations ? state.loadFilterDodoStations.data : null,
        loadFilterStationsWithContractsData: state.loadFilterStationsWithContracts ? state.loadFilterStationsWithContracts.data : null,
        loadSystemEventsFilterData: state.loadSystemEventsFilter ? state.loadSystemEventsFilter.data : null,
        equipmentsData: state.equipments ? state.equipments.data : null,
        equipmentByIdData: state.equipmentById ? state.equipmentById.data : null,
        loadBornesData: state.loadBornes ? state.loadBornes.data : null,        
        alarmCloseData: state.alarmClose ? state.alarmClose.data : null,
        notificationsData: state.loadNotifications ? state.loadNotifications.data : null,
        notificationByIdData: state.loadNotificationById ? state.loadNotificationById.data : null,
        notificationDeleteByIdData: state.deleteNotificationById ? state.deleteNotificationById.data : null,
        notificationCreateData: state.createNotification ? state.createNotification.data : null,
        notificationUpdateData: state.updateNotification ? state.updateNotification.data : null,
        subscribersData: state.loadDefaultSubscribers ? state.loadDefaultSubscribers.data : null,
        notificationSendData: state.sendNotification ? state.sendNotification.data : null,
        loadCampaignsData: state.loadCampaigns ? state.loadCampaigns : null,
        loadCampaignData: state.loadCampaign ? state.loadCampaign : null,
        createCampaignData: state.createCampaign ? state.createCampaign : null,
        updateCampaignData: state.updateCampaign ? state.updateCampaign : null,
        launchCampaignData: state.launchCampaign ? state.launchCampaign : null,
        deleteCampaignData: state.deleteCampaign ? state.deleteCampaign : null,
        loadCommissionsData: state.loadCommissions ? state.loadCommissions : null,
        loadCommissionData: state.loadCommission ? state.loadCommission : null,
        createCommissionData: state.createCommission ? state.createCommission : null,
        updateCommissionData: state.updateCommission ? state.updateCommission : null,
        launchCommissionData: state.launchCommission ? state.launchCommission : null,
        deleteCommissionData: state.deleteCommission ? state.deleteCommission : null,
        loadFirmwaresData: state.loadFirmwares ? state.loadFirmwares : null,
        loadHardwaresData: state.loadHardwares ? state.loadHardwares : null,
        loadPriceZonesData: state.loadPriceZones ? state.loadPriceZones : null,
        deletePricezoneData: state.deletePricezone ? state.deletePricezone : null,
        loadStationEquipmentsData: state.loadStationEquipments ? state.loadStationEquipments : null,
        loadCampaignEventsData: state.loadCampaignEvents ? state.loadCampaignEvents : null,        
        systemEventsData: state.systemEvents ? state.systemEvents.data : null,
        error: state.faillure ? state.faillure.error : null,
        logedIn: loged,
        loginData: logedData,
        loadRoleFeaturesData: state.loadRoleFeatures ? state.loadRoleFeatures.data : null,
        redirectToProviderData: state.redirectToProvider ? state.redirectToProvider.data : null,
        redirectToApiServerData: state.redirectToApiServer ? state.redirectToApiServer.data : null,        
        usersData: state.users ? state.users.data : null,
        userData: state.user ? state.user.data : null,
        addUserData: state.addUser ? state.addUser.data : null,
        loadGroupsData: state.loadGroups ? state.loadGroups.data : null,
        userDeleteByIdData: state.userDeleteById ? state.userDeleteById.data : null,
        passwordUpdateData: state.updatePassword ? state.updatePassword.data : null,
        myPasswordUpdateData: state.updateMyPassword ? state.updateMyPassword.data : null,
        userUpdateData: state.userUpdate ? state.userUpdate.data : null,
        borneAccessCodeData: state.generateBorneAccessCode ? state.generateBorneAccessCode.data : null,
        groupsData: state.groups ? state.groups.data : null,
        groupByIdData: state.groupById ? state.groupById.data : null,
        loadAuthorisationsData: state.authorisations ? state.authorisations.data : null,
        loadAddRoleData: state.addRole ? state.addRole.data : null,
        loadRoleData: state.loadRole ? state.loadRole.data : null,
        addGroupData: state.addGroup ? state.addGroup.data : null,
        groupData: state.group ? state.group.data : null,
        groupUpdateData: state.groupUpdate ? state.groupUpdate.data : null,
        groupDeleteByIdData: state.groupDeleteById ? state.groupDeleteById.data : null,
        rolesData: state.roles ? state.roles.data : null,
        roleDeleteByIdData: state.roleDeleteById ? state.roleDeleteById.data : null,
        roleData: state.role ? state.role.data : null,
        roleUpdateData: state.roleUpdate ? state.roleUpdate.data : null,
        pricezonesData: state.pricezones ? state.pricezones.data : null,
        pricezoneByIdData: state.pricezoneById ? state.pricezoneById.data : null,
        adminequipmentsData: state.adminequipments ? state.adminequipments.data : null,
        createEquipmentData: state.createEquipment ? state.createEquipment : null,
        equipmentDeleteByIdData: state.equipmentDeleteById ? state.equipmentDeleteById.data : null,
        bornesStatusData: state.loadBornesStatus ? state.loadBornesStatus.data : null,
        bornesAnalysisData: state.loadBornesAnalysis ? state.loadBornesAnalysis.data : null,
        equipmentData: state.equipment ? state.equipment.data : null,
        equipmentUpdateData: state.equipmentUpdate ? state.equipmentUpdate.data : null,
        loadStationsData: state.loadStations ? state.loadStations.data : null,
        loadStationsMapData: state.loadStationsMap ? state.loadStationsMap.data : null,
        loadStationGroupsData: state.loadStationGroups ? state.loadStationGroups.data : null,
        loadStationGroupData: state.loadStationGroup ? state.loadStationGroup.data : null,
        loadStationGroupStationsData: state.loadStationGroupStations ? state.loadStationGroupStations.data : null,
        createStationGroupData: state.createStationGroup ? state.createStationGroup.data : null,
        updateStationGroupData: state.updateStationGroup ? state.updateStationGroup.data : null,
        deleteStationGroupData: state.deleteStationGroup ? state.deleteStationGroup.data : null,
        configMaterielsData: state.configMateriels ? state.configMateriels.data : null,
        configMaterielData: state.configMateriel ? state.configMateriel.data : null,
        configMaterielDeleteByIdData: state.configMaterielDeleteById ? state.configMaterielDeleteById.data : null,        
        addStationData: state.addStation ? state.addStation.data : null,
        stationUpdateData: state.stationUpdate ? state.stationUpdate.data : null,
        changeStationContractData: state.changeStationContract ? state.changeStationContract.data : null,
        stationDeleteByIdData: state.stationDeleteById ? state.stationDeleteById.data : null,
        stationData: state.station ? state.station.data : null,
        stationPriceZonesData: state.loadStationPriceZones ? state.loadStationPriceZones.data : null,
        stationCommissionsData: state.loadStationCommissions ? state.loadStationCommissions.data : null,
        updateBorneData: state.updateBorne ? state.updateBorne : null,
        firmwaresData: state.firmwares ? state.firmwares.data : null,
        firmwareData: state.firmware ? state.firmware.data : null,
        firmwareDeleteByIdData: state.firmwareDeleteById ? state.firmwareDeleteById.data : null,
        rebootData: state.reboot ? state.reboot : null,
        checkNetworkSpeedData: state.checkNetworkSpeed ? state.checkNetworkSpeed : null,
        borneLogsData: state.getBorneLogs ? state.getBorneLogs : null,
        qrCodeSendByIdData: state.qrCodeSend ? state.qrCodeSend.data : null,
        loadServiceInformationsData: state.loadServiceInformations ? state.loadServiceInformations.data : null,
        loadOpenAlarmsData: state.loadOpenAlarms ? state.loadOpenAlarms.data : null,        
        pageSize: state.onPageSizeChange ? state.onPageSizeChange.pageSize : null,
        pageSizes: state.onPageSizeChange ? state.onPageSizeChange.pageSizes : null,
        filter: state.onFilterChange ? state.onFilterChange.filter : null,
        filters: state.onFilterChange ? state.onFilterChange.filters : null,
        updateConfigStationData : state.updateConfigStation ? state.updateConfigStation : null,
        disassociateBorneData: state.disassociateBorne ? state.disassociateBorne.data : null
    });
};
const mapDispatchToProps = dispatch => bindActionCreators(
    {
        commerceDashbord,
        commerceAnalysis,
        commerceExploitAnalysis,
        loadFilter,
        loadFilterStations,
        loadFilterDodoStations,
        loadFilterStationsWithContracts,
        loadFilterExploitantsReports,
        loadSystemEventsFilter,
        equipments,
        equipmentById,
        loadBornes,
        notificationsLoad,
        notificationLoadById,
        notificationDeleteById,
        notificationCreate,
        notificationUpdate,
        loadCampaigns,
        loadCampaign,
        loadFirmwares,
        loadHardwares,
        loadPriceZones,
        deletePricezone,
        loadStationPriceZones,
        loadStationCommissions,
        loadStationEquipments,
        loadCampaignEvents,
        createCampaign,
        updateCampaign,
        launchCampaign,
        deleteCampaign,
        loadCommissions,
        loadCommission,
        createCommission,
        updateCommission,
        deleteCommission,
        loadStationGroup,
        loadStationGroups,
        loadStationGroupStations,
        createStationGroup,
        updateStationGroup,
        deleteStationGroup,
        notificationSend,
        loadDefaultSubscribers,
        systemEvents, login, logout, loadRoleFeatures, redirectToProvider, redirectToApiServer,
        users, user, addUser, loadGroups, userDeleteById, passwordUpdate,
        myPasswordUpdate, userUpdate, generateBorneAccessCode, groups, groupById, authorisations, addRole, closeAlarm, loadRole,
        addGroup, group, groupUpdate, groupDeleteById, roles, roleDeleteById, role, roleUpdate, pricezones,
        pricezoneById, adminequipments, createEquipment, equipmentDeleteById, loadBornesStatus, equipment, equipmentUpdate,
        loadStationsMap, loadStations, configMateriels, configMateriel, configMaterielDeleteById, loadBornesAnalysis,
        addStation, stationUpdate, changeStationContract, stationDeleteById, station, updateBorne, firmwares, firmware,
        firmwareDeleteById, checkNetworkSpeed, getBorneLogs, qrCodeSendById, onPageSizeChange, onFilterChange, updateConfigStation, 
        loadServiceInformations, loadOpenAlarms, reboot, disassociateBorne
    },

    dispatch
);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartadisMain));
