import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { Message } from 'primereact/message';
import { TabView, TabPanel } from 'primereact/tabview';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import msg from '../../../texts/msg';
import { IP_PATTERN, NUMBER_PATTERN } from '../../../utils/RegexPatterns';
import UploadService from "../../../middleware/download-upload-files.service"
import MessageDialog from '../../common/MessageDialog';
import { progressSpinnerHandler } from '../../../utils/ProgressSpinnerHandler';
import { UPDATE, ADMINEQUIPMENTS, rolesValidator, READ } from '../../../utils/RolesValidator';
import { TimeTable, getDefaultTimetableRefCopie, getTimetable } from '../station/TimeTable';
import '../../common/css/common.css';

const STATION_TIMETABLE = {key: msg("equipment.apply.station.opening.time"), value: true};
const EQUIPMENT_TIMETABLE = {key: msg("equipment.apply.custom.opening.time"), value: false};
const borneOpeningHourOptions = [ STATION_TIMETABLE, EQUIPMENT_TIMETABLE];
const MAINTENANCE_CAUSE_STATE = ['REPAIR', 'DROUGHT'];

class UpdateEquipment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isName: false,
            isFirmware: false,
            isHardware: false,
            isConfig: false,
            isStation: false,
            isBorneType: false,
            selectedFiles: undefined,
            progress: 0,
            isUpdateStation: true,
            borneTypeSelected: true,
            borneExiste: false,
            hardwareOrfirmwareexist: false,
            isModifiable: true,
            uploadOk: false,
            uploadNo: false,
            showConfirmUpload: false,
            isNotDownload: false,
            searchStation: false,
            stationSelected: false,
            borneFilter: {
            },
            equipment: {
                id: null,
                name: "",
                description: "",
                dhcp: false,
                borneIp: "",
                borneMask: "",
                borneGateWay: "",
                maintenance: false,
                maintenanceCause: null,
                logLevel: { key: msg('equipment.log.details'), value: 0 },
                neftisIp: "",
                neftisId: 0,
                firmware: {},
                hardware: {},
                station: {},
                borneType: {},
                codeServer: {},
                meansOfPayment: {},
                borne: {},
                borneOpeningHourOption:STATION_TIMETABLE,
                enableTimeTable: STATION_TIMETABLE.value,
                timetable: getDefaultTimetableRefCopie(),
                userConfirmUpdateBorne: false,
            },
            station: {
                implant: "",
                name: "",
                brand: "",
                language: "",
                ticketHdr: "",
                ticketFtr: "",
                contract: ""
            },
            pricezone: {
                pricezoneName: "",
                pricezoneVersion: "",
                currency: "",
            },
            meansOfPayment: {
                cbFleet: true,
                pjegr: true,
                tw: true,
                code: true,
                qrcode: true,
                cash: false,
                bill: false,
                coins: false,
                maintenance: true,
                licensePlate: false,
                cb: true,
                totalEnergie: true
            },
            borne: {
                isBillReaderPresent: null,
                urlStartPage: null,
                isCoinAcceptorPresent: null,
                remainingInSeconds: false, // tl17              
                beforeLaunch: { key: 10, value: 10 },//tl16
                isMulti: false,//tl16
            },
            codeServer: {
                ip: "127.0.0.1",
                port: 7117
            },
            isInitialized: false,
            query: '',
            isWorldContext: process.env.REACT_APP_TARGET_RUNTIME === 'world'
        };
        this.doOnSave = this.doOnSave.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.filterStations = this.filterStations.bind(this);
        this.meansOfPaymentBuild = this.meansOfPaymentBuild.bind(this);
        this.borneParametersBuild = this.borneParametersBuild.bind(this);
        this.setTimetableState = this.setTimetableState.bind(this);
        this.maintenanceMaintenanceCauseOnChange = this.maintenanceMaintenanceCauseOnChange.bind(this);
        this.droughtMaintenanceCauseOnChange = this.droughtMaintenanceCauseOnChange.bind(this);
        this.onConfirmUpdateBorneDialog = this.onConfirmUpdateBorneDialog.bind(this);
        this.onCancelUpdateBorneDialog = this.onCancelUpdateBorneDialog.bind(this);
    }

    componentDidMount() {
        //The firmwares, hardwares and pricezones are loaded from the campaign URI Rest API
        // The campaign feature is required for that
        //TODO call the common Rest API (firmwares, hardwares and pricezones Rest API)
        this.props.loadFirmwares();
        this.props.loadHardwares();
        this.loadStations(this.state.query);
        if (this.state.isWorldContext) {
            this.setState(prevState => ({
                meansOfPayment: {
                    ...prevState.meansOfPayment,
                    pjegr: false,
                    tw: false,
                    qrcode: false,
                    licensePlate: false,
                    totalEnergie: false
                },
            }));
        }
    }

    componentDidUpdate(prevProps) {
        if (this.checkApiErrors(prevProps)) {
            return;
        }
        if (!this.state.isInitialized && this.state.isModifiable && this.props.equipment && this.props.equipment.name) {
            let serialNumber = this.props.equipment && this.props.equipment.borne ? this.props.equipment.borne.serialNumber : null;
            let meansOfPayment = this.props.equipment && this.props.equipment.meansOfPayment ? this.props.equipment.meansOfPayment : this.state.meansOfPayment;
            let borne = this.props.equipment && this.props.equipment.borne ? this.props.equipment.borne : this.state.borne;
            let codeServer = this.props.equipment && this.props.equipment.codeServer ? this.props.equipment.codeServer : this.state.codeServer;
            let coins = (!borne.isBillReaderPresent && meansOfPayment.cash) || meansOfPayment.cash ? true : false;
            let equipement = {
                id: this.props.equipment.id,
                name: this.props.equipment.name,
                description: this.props.equipment.description,
                dhcp: this.props.equipment.dhcp === true ? true : false,                                    
                borneIp: this.props.equipment.borneIp,
                borneMask: this.props.equipment.borneMask,
                borneGateWay: this.props.equipment.borneGateWay,
                maintenance: this.props.equipment.maintenance === true ? true : false,
                maintenanceCause: this.props.equipment.maintenance ? this.props.equipment.maintenanceCause: null,
                logLevel: {
                    key: this.props.equipment.logLevel ? msg('equipment.log.' + this.props.equipment.logLevel.key) : null,
                    value: this.props.equipment.logLevel ? this.props.equipment.logLevel.value : null
                },
                neftisIp: this.props.equipment.neftisIp,
                neftisId: this.props.equipment.neftisId,
                firmware: this.props.equipment.firmware,
                hardware: this.props.equipment.hardware,
                station: this.props.equipment.station,
                borneType: this.props.equipment.borneType,
                borneOpeningHourOption: this.props.equipment.enableTimeTable ? STATION_TIMETABLE : EQUIPMENT_TIMETABLE,
                enableTimeTable: !this.props.equipment.enableTimeTable  ? EQUIPMENT_TIMETABLE.value : this.props.equipment.enableTimeTable,
                timetable: getTimetable(this.props.equipment.timetable),
            };
            meansOfPayment =  {
                cbFleet: meansOfPayment.cbFleet,
                pjegr: meansOfPayment.pjegr,
                tw: meansOfPayment.tw,
                code: meansOfPayment.code,
                qrcode: meansOfPayment.qrcode,
                cash: meansOfPayment.cash,
                bill: borne.isBillReaderPresent,
                coins: coins,
                maintenance: meansOfPayment.maintenance,
                licensePlate: meansOfPayment.licensePlate,
                cb: (!meansOfPayment.cb && meansOfPayment.cb !== false) ? meansOfPayment.cbFleet : meansOfPayment.cb,
                totalEnergie: (!meansOfPayment.totalEnergie && meansOfPayment.totalEnergie !== false) ? meansOfPayment.cbFleet : meansOfPayment.totalEnergie
            };
            borne = {
                urlStartPage: borne.urlStartPage,
                isCoinAcceptorPresent: borne.isCoinAcceptorPresent,
                remainingInSeconds: borne.remainingInSeconds,
                isBillReaderPresent: borne.isBillReaderPresent,
                beforeLaunch: borne.beforeLaunch,
                isMulti: borne.isMulti,
            };            
            codeServer = {
                ip: codeServer.ip,
                port: codeServer.port
            };
            this.setState({
                equipment: equipement, meansOfPayment: meansOfPayment, borne: borne, codeServer: codeServer,
                borneExiste: serialNumber ? true : false,
                isInitialized: true
            });
            this.props.station(this.props.equipment.station.value);
        }
        if (this.state.stationSelected && this.state.equipment.station.value) {
            this.props.station(this.state.equipment.station.value);
            this.setState({ stationSelected: false });
        }

        let prevCreate = prevProps.equipmentUpdate && prevProps.equipmentUpdate.lastChange;
        let lastCreate = this.props.equipmentUpdate && this.props.equipmentUpdate.lastChange;
        let created = (lastCreate && (!prevCreate || prevCreate < lastCreate))
        if (created) {
            this.props.history.push('/equipment');
        }

        let prevChange = prevProps.stations && prevProps.stations.lastChange;
        let lastChange = this.props.stations && this.props.stations.lastChange;
        let change = (lastChange && (!prevChange || prevChange < lastChange))        
        if (change) {            
            let stations = [];
            this.props.stations.stations.forEach(s => stations.push({key : s.implant + '(' + s.name + ')', value: s.implant}));
            this.setState({filteredStations: stations, searchStation: true});
        }
    }

    doOnSave() {
        let lEquipment = Object.assign({}, this.state.equipment);
        let valid = true;
        if (!lEquipment.name || !lEquipment.name.trim()) {
            this.setState({ isName: true });
            valid = false;
        } else {
            this.setState({ isName: false });
        }
        if (!this.state.borneExiste && (!lEquipment.firmware || !lEquipment.firmware.value)) {
            this.setState({ isFirmware: true });
            valid = false;
        } else {
            this.setState({ isFirmware: false });
        }
        if (!this.state.borneExiste && (!lEquipment.hardware || !lEquipment.hardware.value)) {
            this.setState({ isHardware: true });
            valid = false;
        } else {
            this.setState({ isHardware: false });
        }

        if (!this.state.borneExiste && (!lEquipment.station || !lEquipment.station.value)) {
            this.setState({ isStation: true });
            valid = false;
        } else {
            this.setState({ isStation: false });
        }
        if(lEquipment.dhcp){
            lEquipment.borneIp = "0.0.0.0";
            lEquipment.borneMask = "0.0.0.0";
            lEquipment.borneGateWay = "0.0.0.0";
        }
        if (lEquipment.borneIp || lEquipment.borneMask || lEquipment.borneGateWay || lEquipment.neftisIp || lEquipment.logLevel || this.state.codeServer.ip) {
            if (lEquipment.borneIp && !IP_PATTERN.test(lEquipment.borneIp)) {
                this.setState({ borneIpNotValid: true });
                valid = false;
            } else {
                this.setState({ borneIpNotValid: false });
            }
            if (lEquipment.borneMask && !IP_PATTERN.test(lEquipment.borneMask)) {
                this.setState({ borneMaskNotValid: true });
                valid = false;
            } else {
                this.setState({ borneMaskNotValid: false });
            }
            if (lEquipment.borneGateWay && !IP_PATTERN.test(lEquipment.borneGateWay)) {
                this.setState({ borneGateWayNotValid: true });
                valid = false;
            } else {
                this.setState({ borneGateWayNotValid: false });
            }
            if (lEquipment.neftisIp && !IP_PATTERN.test(lEquipment.neftisIp)) {
                this.setState({ neftisIpNotValid: true });
                valid = false;
            } else {
                this.setState({ neftisIpNotValid: false });
            }
            if (this.state.codeServer.ip && !IP_PATTERN.test(this.state.codeServer.ip)) {
                this.setState({ ipNotValid: true });
                valid = false;
            } else {
                this.setState({ ipNotValid: false });
            }
        }

        if (lEquipment.neftisId || this.state.borne.beforeLaunch || this.state.codeServer.port) {
            if (lEquipment.neftisId && !NUMBER_PATTERN.test(lEquipment.neftisId)) {
                this.setState({ neftisIdNotValid: true });
                valid = false;
            } else {
                this.setState({ neftisIdNotValid: false });
            }
            if (this.state.borne.beforeLaunch && !NUMBER_PATTERN.test(this.state.borne.beforeLaunch.value)) {
                this.setState({ beforeLaunchNotValid: true });
                valid = false;
            } else {
                this.setState({ beforeLaunchNotValid: false });
            }
            if (this.state.codeServer.port && !NUMBER_PATTERN.test(this.state.codeServer.port)) {
                this.setState({ portNotValid: true });
                valid = false;
            } else {
                this.setState({ portNotValid: false });
            }
        }
        if (this.state.equipment.maintenance && !this.state.equipment.maintenanceCause) {
            valid = false;
            this.setState({ maintenanceCauseNotValid: true });
        }
        if (valid) {
            lEquipment.codeServer = { ip: this.state.codeServer.ip, port: this.state.codeServer.port };
            valid = this.meansOfPaymentBuild(lEquipment, this.state.meansOfPayment, valid);
            this.borneParametersBuild(lEquipment, this.state.borne);
        }
        if (valid) {
            if (this.state.borneExiste) {
                this.setState({ equipment: lEquipment, isEquipmentUpdated: true });
            } else {
                this.props.updateEquipment(this.state.equipment.id, lEquipment);
            }
        }
    }
    handleOpeningTimeChange(e){
        this.setState({ equipment: Object.assign({}, this.state.equipment, { borneOpeningHourOption: e.target.value }, {enableTimeTable: e.target.value.value}) });
    }


    downloadTargetConfiguration() {
        let configFileName = "config";
        if (this.props.equipment.borneType.value) {
            configFileName = this.props.equipment.borneType.value + "_config_" + this.props.equipment.id + ".json";
        }
        UploadService.getFileData("adminequipments/" + this.props.equipment.id + "/configuration", configFileName, null)
            .then((response) => {
                const fname = configFileName;
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    if (response.status === "401" || response.status === 401) {
                        this.setState({ buttonSelectd: false, sessionExpired: true });
                    } else if (response.status === 400 || response.status === "400") {
                        this.setState({ isNotDownload: true, buttonSelectd: false });
                    }
                    else {
                        this.setState({ buttonSelectd: false });
                        a.href = url;
                        a.download = fname;
                        a.click();
                    }
                });
                progressSpinnerHandler.show(false);
            }).catch((err) => {progressSpinnerHandler.show(false); });
    }

    borneParametersBuild(lEquipment, borne) {
        switch (lEquipment.borneType.value) {
            case 'TK2':
                if (this.state.meansOfPayment.bill) {
                    lEquipment.borne = {
                        isBillReaderPresent: true
                    }
                } else {
                    lEquipment.borne = {
                        isBillReaderPresent: false
                    }
                }
                break;
            case 'TL16':
                lEquipment.borne = {
                    beforeLaunch: borne.beforeLaunch,
                    isMulti: borne.isMulti
                }
                break;
            case 'TL17':
                lEquipment.borne = {
                    remainingInSeconds: borne.remainingInSeconds
                }
                break;
            case 'TL18':
                lEquipment.borne = {
                    urlStartPage: borne.urlStartPage,
                    isCoinAcceptorPresent: borne.isCoinAcceptorPresent,
                }
                break;
            default:
                break;
        }
    }

    meansOfPaymentBuild(lEquipment, meansOfPayment, valid) {
        switch (lEquipment.borneType.value) {
            case 'TK2':
                if (!meansOfPayment.cbFleet && !meansOfPayment.tw && !meansOfPayment.cash && !meansOfPayment.bill
                    && !meansOfPayment.cb && !meansOfPayment.totalEnergie) {
                    this.setState({ isMeanOfPayment: true });
                    valid = false;
                    return valid;
                } else {
                    if (meansOfPayment.coins || meansOfPayment.bill) {
                        meansOfPayment.cash = true;
                    } else {
                        meansOfPayment.cash = false;
                    }
                    lEquipment.meansOfPayment = {
                        cbFleet: meansOfPayment.cb || meansOfPayment.totalEnergie,
                        tw: meansOfPayment.tw,
                        cash: meansOfPayment.cash,
                        cb: meansOfPayment.cb,
                        totalEnergie: meansOfPayment.totalEnergie
                    }
                    return valid;
                }
            case 'TL16':
                if (!meansOfPayment.cbFleet && !meansOfPayment.tw && !meansOfPayment.cash && !meansOfPayment.pjegr
                    && !meansOfPayment.code && !meansOfPayment.qrcode && !meansOfPayment.maintenance
                    && !meansOfPayment.cb && !meansOfPayment.totalEnergie) {
                    this.setState({ isMeanOfPayment: true });
                    valid = false;
                    return valid;
                } else {
                    lEquipment.meansOfPayment = {
                        cbFleet: meansOfPayment.cb || meansOfPayment.totalEnergie,
                        tw: meansOfPayment.tw,
                        cash: meansOfPayment.cash,
                        pjegr: meansOfPayment.pjegr,
                        code: meansOfPayment.code,
                        qrcode: meansOfPayment.qrcode,
                        maintenance: meansOfPayment.maintenance,
                        cb: meansOfPayment.cb,
                        totalEnergie: meansOfPayment.totalEnergie
                    }
                    return valid;
                }
            case 'TL17':
                if (!meansOfPayment.cbFleet && !meansOfPayment.tw && !meansOfPayment.cash && !meansOfPayment.pjegr
                    && !meansOfPayment.code && !meansOfPayment.qrcode && !meansOfPayment.maintenance
                    && !meansOfPayment.cb && !meansOfPayment.totalEnergie) {
                    this.setState({ isMeanOfPayment: true });
                    valid = false;
                    return valid;
                } else {
                    lEquipment.meansOfPayment = {
                        cbFleet: meansOfPayment.cb || meansOfPayment.totalEnergie,
                        tw: meansOfPayment.tw,
                        cash: meansOfPayment.cash,
                        pjegr: meansOfPayment.pjegr,
                        code: meansOfPayment.code,
                        qrcode: meansOfPayment.qrcode,
                        maintenance: meansOfPayment.maintenance,
                        cb: meansOfPayment.cb,
                        totalEnergie: meansOfPayment.totalEnergie
                    }
                    return valid;
                }
            case 'TL18':
                if (!meansOfPayment.cbFleet && !meansOfPayment.tw && !meansOfPayment.cash && !meansOfPayment.pjegr
                    && !meansOfPayment.code && !meansOfPayment.qrcode && !meansOfPayment.maintenance && !meansOfPayment.licensePlate
                    && !meansOfPayment.cb && !meansOfPayment.totalEnergie) {
                    this.setState({ isMeanOfPayment: true });
                    valid = false;
                    return valid;
                } else {
                    lEquipment.meansOfPayment = {
                        cbFleet: meansOfPayment.cb || meansOfPayment.totalEnergie,
                        tw: meansOfPayment.tw,
                        cash: meansOfPayment.cash,
                        pjegr: meansOfPayment.pjegr,
                        code: meansOfPayment.code,
                        qrcode: meansOfPayment.qrcode,
                        maintenance: meansOfPayment.maintenance,
                        licensePlate: meansOfPayment.licensePlate,
                        cb: meansOfPayment.cb,
                        totalEnergie: meansOfPayment.totalEnergie
                    }
                    return valid;
                }
            default:
                break;
        }
    }

    filterStations(event, isDropdownClick) {        
        let query = event.query.toLowerCase();
        if(!isDropdownClick) {            
            if(query === this.state.query) {
                return;
            }
        } else if(query && (query === this.state.query)) {
            return;
        }
        this.loadStations(isDropdownClick ? this.state.query : query);
        this.setState({ query: query });
    }

    loadStations(query) {
        let filter = {label: query, page: 0, pageSize: 10};
        this.props.loadStations(filter);
    }

    checkApiErrors(prevProps) {
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            let isError = this.props.error.message && this.props.error.message.startsWith('error.equipment.') ? true : false;
            this.setState({ isApiError: isError });
            return true;
        }
        return false;
    }

    handleTypeChange(e) {
        this.setState({ isModifiable: false, borneTypeSelected: false, equipment: Object.assign({}, this.state.equipment, { borneType: e.value }) });
    }

    handleErrorChange(e) {
        this.setState({ isApiError: false, isDone: false, isError: false, isNameValid: false, sessionExpired: false, selectedFile: null, message: null, isMeanOfPayment: false })
        this.setState({ currentFile: undefined });
    }

    handleErrorExpiredSessionChange(e) {
        this.setState({ isDone: false, isError: false, isNameValid: false, sessionExpired: false, selectedFile: null, message: null })
        this.setState({ currentFile: undefined });
        this.props.history.push('/equipment');
    }

    setTimetableState(timetable) {
        this.setState({equipment: Object.assign({}, this.state.equipment, {timetable: timetable })});
    }

    maintenanceMaintenanceCauseOnChange(e) {
        this.setState(prevState => ({
            equipment: {
                ...prevState.equipment,
                maintenanceCause: MAINTENANCE_CAUSE_STATE[0]
            },
        }));
    }
    droughtMaintenanceCauseOnChange(e) {
        this.setState(prevState => ({
            equipment: {
                ...prevState.equipment,
                maintenanceCause: MAINTENANCE_CAUSE_STATE[1]
            },
        }));
    }

    onConfirmUpdateBorneDialog()  {
        this.setState({ isEquipmentUpdated: true });
        this.setState(prevState => ({
            equipment: {
                ...prevState.equipment,
                userConfirmUpdateBorne: true
            },
        }), () => this.props.updateEquipment(this.state.equipment.id, this.state.equipment));
    }
    onCancelUpdateBorneDialog() {
        this.setState({ isEquipmentUpdated: true });
        this.setState(prevState => ({
            equipment: {
                ...prevState.equipment,
                userConfirmUpdateBorne: false
            },
        }), () => this.props.updateEquipment(this.state.equipment.id, this.state.equipment));
    }
    render() {

        let authorized = rolesValidator.validate([ADMINEQUIPMENTS]);
        let rAuthorized = authorized.toString().includes(READ);
        let uAuthorized = authorized.toString().includes(UPDATE);

        let firmwares = this.props.loadFirmwaresData && this.props.loadFirmwaresData.data ? this.props.loadFirmwaresData.data : [];
        let hardwares = this.props.loadHardwaresData && this.props.loadHardwaresData.data ? this.props.loadHardwaresData.data : [];
        let borneType = this.props.equipment.borneType ? this.props.equipment.borneType : null;

        let firmwareValid = [];
        let hardwareValid = [];
        let isMeansForTK2 = false;
        let isMeansForTL16 = false;
        let isMeansForTL17 = false;
        let isMeansForTL18 = false;

        let borne = this.props.equipment && this.props.equipment.borne ? this.props.equipment.borne : null;
        let equipment = this.state.equipment;

        if (borneType && borneType.value) {
            firmwares.forEach(function (item) {
                let i = item.value.split('_');
                if (i[0] === borneType.value) {
                    firmwareValid.push(item);
                }
            });            
            hardwares.forEach(function (item) {
                let i = item.value.split('_');
                if (i[0] === borneType.value) {
                    hardwareValid.push(item);
                }
            });
            if ((firmwareValid.length === 0 || !firmwareValid.includes(equipment.firmware)) && equipment.firmware && equipment.firmware.key) {
                firmwareValid.push(equipment.firmware)
            }
            if ((hardwareValid.length === 0 || !hardwareValid.includes(equipment.hardware)) && equipment.hardware && equipment.hardware.key) {
                hardwareValid.push(equipment.hardware)
            }
            if (borneType.value === "TK2") {
                isMeansForTK2 = true;
            } else if (borneType.value === "TL16") {
                isMeansForTL16 = true;
            } else if (borneType.value === "TL18") {
                isMeansForTL18 = true;
            } else {
                isMeansForTL17 = true;
            }
            firmwareValid.sort((f1, f2) => f2.key.localeCompare(f1.key));
            hardwareValid.sort((h1, h2) => h1.key.localeCompare(h2.key));
        }

        const bornTypes = [
            { key: msg('equipment.type.tl16'), value: 'TL16' },
            { key: msg('equipment.type.tl17'), value: 'TL17' },
            { key: msg('equipment.type.tl18'), value: 'TL18' },
            { key: msg('equipment.type.tk2'), value: 'TK2' }
        ];

        const logsLevel = [
            { key: msg('equipment.log.details'), value: 0 },
            { key: msg('equipment.log.information'), value: 1 },
            { key: msg('equipment.log.warning'), value: 2 },
            { key: msg('equipment.log.error'), value: 3 }
        ];

        const beforeLaunchs = [
            { key: 10, value: 10 },
            { key: 15, value: 15 },
            { key: 20, value: 20 },
            { key: 25, value: 25 },
            { key: 30, value: 30 }
        ];

        return rAuthorized && (
            <div className="card commonClass roleClass">
                <TabView>
                    <TabPanel header={msg('equipment.identity')}>
                        <div className="detail p-grid p-col-12" style={{ marginTop: '-.2em' }}>
                            <h4 className="p-col-3" style={{ marginTop: '-.2em' }}>{msg('equipment.identity.update')}</h4>
                            <h4 className="p-col-3" style={{ marginTop: '-.2em' }}>{msg('equipment.identity.update.id')} {equipment.id}</h4>
                            {this.state.borneExiste && <h4 className="p-col-5" style={{ marginTop: '-.2em' }}>{msg('equipment.identity.update.NumberSerie')} {borne.serialNumber}
                            </h4>}
                            {this.state.borneExiste && <h4 className="p-col-1" style={{ marginTop: '-.2em' }}>
                                <ol className="tooltip" style={{ marginLeft: '-5em', marginTop: '-.2em' }}>
                                    <input id="image" type="image" src="/assets/ultima/layout/images/cartadis/download.svg" style={{ marginTop: '-10px', marginLeft: '100px', height: '30px' }}
                                        alt={(msg('equipment.button.config.download'))}
                                        tooltip={msg('equipment.button.config.download.tooltip')}
                                        onClick={() => this.downloadTargetConfiguration()} />
                                    {<span className="tooltiptext">{msg('equipment.button.config.download.tooltip')}</span>}
                                </ol>
                            </h4>}
                            <div className="p-grid p-col-6">
                                <div className="p-grid p-col-10"></div>
                                <div className="p-grid p-col-2">{
                                    !this.state.borneExiste && <h4 className="p-col-6" style={{ marginTop: '-.2em' }}>
                                        <ol className="tooltip" style={{ marginLeft: '-5em', marginTop: '-.2em' }}>
                                            <input id="image" type="image" src="/assets/ultima/layout/images/cartadis/download.svg" style={{ marginTop: '-10px', marginLeft: '100px', height: '30px' }}
                                                alt={(msg('equipment.button.config.download'))}
                                                tooltip={msg('equipment.button.config.download.tooltip')}
                                                onClick={() => this.downloadTargetConfiguration()} />
                                            {<span className="tooltiptext">{msg('equipment.button.config.download.tooltip')}</span>}
                                        </ol>
                                    </h4>
                                }</div>
                            </div>
                        </div>
                        <br />

                        <div className="p-grid form-group p-col-10">
                            {<div className="p-grid p-col-6">
                                <div className="p-col-6"><label>{msg('equipment.fields.name')}</label><span className="obligatoire">*</span></div>
                                <div className="p-col-6">
                                    <span className="md-inputfield">
                                        <InputText value={equipment.name} onChange={e => this.setState({ isModifiable: false, equipment: Object.assign({}, equipment, { name: e.target.value }) })}
                                        type="text" size="70"/>
                                    </span>
                                    {this.state.isName && <Message severity="error" text={msg('equipment.input.name.required')} key="name" />}
                                </div>
                            </div>}
                            <div className="p-grid form-group p-col-12">
                                {<div className="p-grid p-col-6">
                                    <div className="p-col-6" style={{ padding: 0 }}><label>{msg('equipment.fields.description')}</label></div>
                                    <div className="p-col-6">
                                        <span className="md-inputfield">
                                            <InputText value={equipment.description} onChange={e => this.setState({ isModifiable: false, equipment: Object.assign({}, equipment, { description: e.target.value }) })}
                                                type="text" size="100" />
                                        </span>
                                    </div>
                                </div>}
                            </div>
                            {<div className="p-grid p-col-12">
                                <div className="p-col-3"> <label>{msg('equipment.fields.borneType')}</label><span className="obligatoire">*</span></div>
                                <div className="p-col-6">
                                    <span className="md-inputfield">
                                        <Dropdown options={bornTypes} optionLabel="key" value={equipment.borneType} disabled={true}
                                            placeholder="Selectionnez" key="bornTypes"
                                        />
                                    </span>
                                    {this.state.isBorneType && <Message severity="error" text={msg('equipment.input.borneType.required')} key="name" />}
                                </div>
                            </div>}
                            {<div className="p-grid p-col-12">
                                <div className="p-col-3"> <label>{msg('equipment.fields.firmware')}</label><span className="obligatoire">*</span></div>
                                <div className="p-col-4">
                                    <Dropdown options={firmwareValid} optionLabel="key" value={equipment.firmware}
                                        disabled={this.state.borneExiste}
                                        onChange={e => this.setState({ isModifiable: false, equipment: Object.assign({}, equipment, { firmware: e.target.value }) })}
                                        placeholder={msg('equipment.firmware.placeholder')} key="firmwares"
                                    />
                                    {this.state.isFirmware && <Message severity="error" text={msg('equipment.input.firmware.required')} key="login" />}
                                </div>
                                <div className="p-col-5 config-desc"><span>{equipment.firmware.label}</span></div>
                            </div>}

                            {<div className="p-grid p-col-12">
                                <div className="p-col-3"> <label>{msg('equipment.fields.hardware')}</label><span className="obligatoire">*</span></div>
                                <div className="p-col-4">
                                    <Dropdown options={hardwareValid} optionLabel="key" value={equipment.hardware}
                                        disabled={this.state.borneExiste}
                                        onChange={e => this.setState({ isModifiable: false, equipment: Object.assign({}, equipment, { hardware: e.target.value }) })}
                                        placeholder={msg('equipment.hardware.placeholder')} key="hardwares"
                                    />
                                    {this.state.isHardware && <Message severity="error" text={msg('equipment.input.hardware.required')} key="group" />}
                                </div>
                                <div className="p-col-5 config-desc"><span>{equipment.hardware.label}</span></div>
                            </div>}

                            <div className="p-grid form-group p-col-12">
                                <div className="p-grid p-col-6">
                                    <div className="p-col-6" style={{ padding: 0 }}> <label>{msg('equipment.fields.station')}</label><span className="obligatoire">*</span></div>
                                    <div className="p-col-6">
                                        <span className="md-inputfield">
                                            <AutoComplete placeholder={msg('equipment.station.placeholder')} disabled={this.state.borneExiste}
                                                minLength={0} size={30} dropdown={true} multiple={false} delay={100} field="key"
                                                value={equipment.station} suggestions={this.state.filteredStations}
                                                completeMethod={this.filterStations}
                                                onDropdownClick={e => this.filterStations(e, true)}
                                                onChange={e => this.setState({ stationSelected: true, isModifiable: false, isUpdateStation: false, equipment: Object.assign({}, equipment, { station: e.target.value }) })}
                                                id="station" key="stationKey" />
                                            {this.state.isStation && <Message severity="error" text={msg('equipment.input.station.required')} />}
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="detail p-col-12">
                            <h4 style={{ marginTop: '-.1em' }}>{msg('equipment.configuration.details')} : {this.props.stationData ? this.props.stationData.name : ""} / {this.state.equipment.station.value}</h4>
                        </div>
                        <div className="p-grid form-group p-col-12">
                            <div className="p-grid form-group p-col-12">
                                {<div className="p-grid p-col-6">
                                    <div className="p-col-6"><label>{msg('equipment.configuration.details.exploitant')} :</label></div>
                                    <div className="p-col-6">
                                        <span>
                                            <label>{(this.props.stationData && this.props.stationData.contract) ? this.props.stationData.contract.sapCode : ""}</label>
                                        </span>
                                    </div>
                                </div>}
                                {<div className="p-grid p-col-6">
                                    <div className="p-col-6"><label>{msg('equipment.configuration.details.pricezone')} :</label></div>
                                    <div className="p-col-6">
                                        <span>
                                            <label>{this.props.stationData && this.props.stationData.pricezone ? this.props.stationData.pricezone.key : ""}</label>
                                        </span>
                                    </div>
                                </div>}
                            </div>
                        </div>

                    </TabPanel>
                    <TabPanel header={msg('equipment.configuration.meansOfPayment')}>
                        <div className="p-grid form-group p-col-12">
                            {<div className="p-grid p-col-6">
                                <div className="p-grid p-col-6">
                                    <div className="p-col-6"> <label>{msg('equipment.configuration.meansOfPayment.cb')}</label></div>
                                </div>
                                <div className="p-col-6">
                                    <Checkbox value={this.state.meansOfPayment.cb} onChange={e => this.setState({ isModifiable: false, meansOfPayment: Object.assign({}, this.state.meansOfPayment, { cb: e.checked }) })}
                                        checked={this.state.meansOfPayment.cb}></Checkbox>
                                </div>
                            </div>}
                            {!this.state.isWorldContext && <div className="p-grid p-col-6">
                                <div className="p-grid p-col-6">
                                    <div className="p-col-6"> <label>{msg('equipment.configuration.meansOfPayment.totalEnergie')}</label></div>
                                </div>
                                <div className="p-col-6">
                                    <Checkbox value={this.state.meansOfPayment.totalEnergie} onChange={e => this.setState({ isModifiable: false, meansOfPayment: Object.assign({}, this.state.meansOfPayment, { totalEnergie: e.checked }) })}
                                        checked={this.state.meansOfPayment.totalEnergie} ></Checkbox>
                                </div>
                            </div>}
                            {!this.state.isWorldContext && <div className="p-grid p-col-6">
                                <div className="p-grid p-col-6">
                                    <div className="p-col-6"> <label>{msg('equipment.configuration.meansOfPayment.wash')}</label></div>
                                </div>
                                <div className="p-col-6" >
                                    <Checkbox value={this.state.meansOfPayment.tw} onChange={e => this.setState({ isModifiable: false, meansOfPayment: Object.assign({}, this.state.meansOfPayment, { tw: e.checked }) })}
                                        checked={this.state.meansOfPayment.tw} ></Checkbox>
                                </div>
                            </div>}
                            {!isMeansForTK2 && !this.state.isWorldContext && <div className="p-grid p-col-6">
                                <div className="p-grid p-col-6">
                                    <div className="p-col-6"> <label>{msg('equipment.configuration.meansOfPayment.pjegr')}</label></div>

                                </div>
                                <div className="p-col-6">
                                    <Checkbox value={this.state.meansOfPayment.pjegr} onChange={e => this.setState({ isModifiable: false, meansOfPayment: Object.assign({}, this.state.meansOfPayment, { pjegr: e.checked }) })}
                                        checked={this.state.meansOfPayment.pjegr}></Checkbox>
                                </div>
                            </div>}
                            {!isMeansForTK2 && !this.state.isWorldContext && <div className="p-grid p-col-6">
                                <div className="p-grid p-col-6">
                                    <div className="p-col-6"> <label>{msg('equipment.configuration.meansOfPayment.qrcode')}</label></div>
                                </div>
                                <div className="p-col-6">
                                    <Checkbox value={this.state.meansOfPayment.qrcode} onChange={e => this.setState({ isModifiable: false, meansOfPayment: Object.assign({}, this.state.meansOfPayment, { qrcode: e.checked }) })}
                                        checked={this.state.meansOfPayment.qrcode} ></Checkbox>
                                </div>
                            </div>}
                            {!isMeansForTK2 && <div className="p-grid p-col-6">
                                <div className="p-grid p-col-6">
                                    <div className="p-col-6"> <label>{msg('equipment.configuration.meansOfPayment.code')}</label></div>
                                </div>
                                <div className="p-col-6">
                                    <Checkbox value={this.state.meansOfPayment.code} onChange={e => this.setState({ isModifiable: false, meansOfPayment: Object.assign({}, this.state.meansOfPayment, { code: e.checked }) })}
                                        checked={this.state.meansOfPayment.code}></Checkbox>
                                </div>
                            </div>}
                            {!isMeansForTK2 && <div className="p-grid p-col-6">
                                <div className="p-grid p-col-6">
                                    <div className="p-col-6"> <label>{msg('equipment.configuration.meansOfPayment.maintenance')}</label></div>
                                </div>
                                <div className="p-col-6">
                                    <Checkbox value={this.state.meansOfPayment.maintenance} onChange={e => this.setState({ isModifiable: false, meansOfPayment: Object.assign({}, this.state.meansOfPayment, { maintenance: e.checked }) })}
                                        checked={this.state.meansOfPayment.maintenance}></Checkbox>
                                </div>
                            </div>}
                            {!isMeansForTK2 && <div className="p-grid p-col-6">
                                <div className="p-grid p-col-6">
                                    <div className="p-col-6"> <label>{msg('equipment.configuration.meansOfPayment.cash')}</label></div>
                                </div>
                                <div className="p-col-6">
                                    <Checkbox value={this.state.meansOfPayment.cash} onChange={e => this.setState({ isModifiable: false, meansOfPayment: Object.assign({}, this.state.meansOfPayment, { cash: e.checked }) })}
                                        checked={this.state.meansOfPayment.cash}></Checkbox>
                                </div>
                            </div>}
                            {isMeansForTK2 && <div className="p-grid p-col-6">
                                <div className="p-grid p-col-6">
                                    <div className="p-col-6"> <label>{msg('equipment.configuration.meansOfPayment.coins')}</label></div>
                                </div>
                                <div className="p-col-6">
                                    <Checkbox value={this.state.meansOfPayment.coins} onChange={e => this.setState({ isModifiable: false, meansOfPayment: Object.assign({}, this.state.meansOfPayment, { coins: e.checked }) })}
                                        checked={this.state.meansOfPayment.coins}></Checkbox>
                                </div>
                            </div>}
                            {isMeansForTK2 && <div className="p-grid p-col-6">
                                <div className="p-grid p-col-6">
                                    <div className="p-col-6"> <label>{msg('equipment.configuration.meansOfPayment.bill')}</label></div>
                                </div>
                                <div className="p-col-6">
                                    <Checkbox value={this.state.meansOfPayment.bill} onChange={e => this.setState({ isModifiable: false, meansOfPayment: Object.assign({}, this.state.meansOfPayment, { bill: e.checked }) })}
                                        checked={this.state.meansOfPayment.bill}></Checkbox>
                                </div>
                            </div>}
                            {!isMeansForTK2 && isMeansForTL18 && !this.state.isWorldContext && <div className="p-grid p-col-6">
                                <div className="p-grid p-col-6">
                                    <div className="p-col-12"> <label>{msg('equipment.configuration.meansOfPayment.licensePlate')}</label></div>
                                </div>
                                <div className="p-col-6">
                                    <Checkbox value={this.state.meansOfPayment.licensePlate} onChange={e => this.setState({ isModifiable: false, meansOfPayment: Object.assign({}, this.state.meansOfPayment, { licensePlate: e.checked }) })}
                                        checked={this.state.meansOfPayment.licensePlate} ></Checkbox>
                                </div>
                            </div>}
                        </div>
                    </TabPanel>
                    <TabPanel header={msg('equipment.configuration.borne')}>
                        <div className="p-grid form-group p-col-10">
                            {isMeansForTL16 && <div className="p-grid p-col-6">
                                <div className="p-grid p-col-5">
                                    <div className="p-col-12"><label>{msg('equipment.configuration.borne.isMulti')}</label></div>
                                </div>
                                <div className="p-col-1">
                                    <span className="md-inputfield">
                                        <Checkbox value={this.state.borne.isMulti} onChange={e => this.setState({ isModifiable: false, borne: Object.assign({}, this.state.borne, { isMulti: e.checked }) })}
                                            checked={this.state.borne.isMulti}></Checkbox>
                                    </span>
                                </div>
                            </div>}
                            {isMeansForTL16 && <div className="p-grid p-col-6">
                                <div className="p-grid p-col-10">
                                    <div className="p-col-12"><label>{msg('equipment.configuration.borne.beforeLaunch')}</label></div>
                                </div>
                                <div className="p-col-2">
                                    <span className="md-inputfield">
                                        <Dropdown options={beforeLaunchs} optionLabel="key" value={this.state.borne.beforeLaunch}
                                            onChange={e => this.setState({ isModifiable: false, borne: Object.assign({}, this.state.borne, { beforeLaunch: e.target.value }) })}
                                            placeholder="Selectionnez"
                                        />
                                    </span>
                                    {this.state.beforeLaunchNotValid && <Message severity="error" text={msg('equipment.message.not.valid.number')} key="startTimeError" />}
                                </div>
                            </div>}

                            {isMeansForTL17 && <div className="p-grid p-col-8">
                                <div className="p-grid p-col-6">
                                    <div className="p-col-12"><label>{msg('equipment.configuration.borne.remainingInSeconds')}</label></div>
                                </div>
                                <div className="p-grid p-col-6">
                                    <div className="p-field-radiobutton p-col-6">
                                        <RadioButton inputId="city1" name="city" value={this.state.borne.remainingInSeconds} onChange={(e) => this.setState({ isModifiable: false, borne: Object.assign({}, this.state.borne, { remainingInSeconds: true }) })} checked={this.state.borne.remainingInSeconds === true} />
                                        <label htmlFor="city1">{msg('equipment.remainingsInSeconds.seconde')}</label>
                                    </div>
                                    <div className="p-field-radiobutton p-col-6">
                                        <RadioButton inputId="city2" name="city" value={this.state.borne.remainingInSeconds} onChange={(e) => this.setState({ isModifiable: false, borne: Object.assign({}, this.state.borne, { remainingInSeconds: false }) })} checked={this.state.borne.remainingInSeconds === false} />
                                        <label htmlFor="city2">{msg('equipment.remainingsInSeconds.euro')}</label>
                                    </div>
                                </div>
                            </div>}
                        </div>

                        <div className="detail p-col-12">
                            <h3 style={{ marginTop: '-.1em' }}>{msg('equipment.configuration.reseau.parameters')}</h3>
                        </div>
                        <div className="p-grid form-group p-col-12">
                            <div className="p-grid p-col-12">
                                <div className="p-col-6"><label>{msg('equipment.configuration.reseau.dhcp')}</label></div>
                                <div className="p-col-6">
                                    <span className="md-inputfield">
                                        <Checkbox value={this.state.equipment.dhcp} 
                                            onChange={e => this.setState({ equipment: Object.assign({}, this.state.equipment, { dhcp: e.checked }) })}
                                            checked={this.state.equipment.dhcp}></Checkbox>  </span>
                                </div>
                            </div>
                            {<div className="p-grid p-col-6">
                                <div className="p-col-6"><label>{msg('equipment.configuration.borne.ip')}</label></div>
                                <div className="p-col-6">
                                    <span className="md-inputfield">
                                        <InputText value={!this.state.equipment.dhcp ? this.state.equipment.borneIp : "0.0.0.0"}
                                            onChange={e => this.setState({ equipment: Object.assign({}, this.state.equipment, { borneIp: e.target.value }) })} 
                                            readOnly={this.state.equipment.dhcp} disabled={this.state.equipment.dhcp} />
                                    </span>
                                    {this.state.borneIpNotValid && <Message severity="error" text={msg('equipment.message.not.valid.ip')} key="startTimeError" />}
                                </div>
                            </div>}
                            {<div className="p-grid p-col-6">
                                <div className="p-col-6"><label>{msg('equipment.configuration.borne.mask')}</label></div>
                                <div className="p-col-6">
                                    <span className="md-inputfield">
                                        <InputText value={!this.state.equipment.dhcp ? this.state.equipment.borneMask : "0.0.0.0"}
                                            onChange={e => this.setState({ equipment: Object.assign({}, this.state.equipment, { borneMask: e.target.value }) })} type="text" 
                                            readOnly={this.state.equipment.dhcp} disabled={this.state.equipment.dhcp} />
                                    </span>
                                    {this.state.borneMaskNotValid && <Message severity="error" text={msg('equipment.message.not.valid.ip')} key="startTimeError" />}
                                </div>
                            </div>}
                            {<div className="p-grid p-col-6">
                                <div className="p-col-6"><label>{msg('equipment.configuration.borne.gatewat')}</label></div>
                                <div className="p-col-6">
                                    <span className="md-inputfield">
                                        <InputText value={ !this.state.equipment.dhcp ? this.state.equipment.borneGateWay : "0.0.0.0"} onChange={e => this.setState({ equipment: Object.assign({}, this.state.equipment, { borneGateWay: e.target.value }) })} 
                                            readOnly={this.state.equipment.dhcp} disabled={this.state.equipment.dhcp} />
                                    </span>
                                    {this.state.borneGateWayNotValid && <Message severity="error" text={msg('equipment.message.not.valid.ip')} key="startTimeError" />}
                                </div>
                            </div>}
                        </div>
                        <div className="detail p-col-12">
                            <h3 style={{ marginTop: '-.1em' }}>{msg('equipment.configuration.neftis')}</h3>
                        </div>
                        <div className="p-grid form-group p-col-10">
                            <div className="p-grid form-group p-col-10">
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-6">
                                        <div className="p-col-6"><label>{msg('equipment.configuration.borne.neftisIp')}</label></div>
                                    </div>
                                    <div className="p-col-6">
                                        <span className="md-inputfield">
                                            <InputText value={this.state.equipment.neftisIp} onChange={e => this.setState({ isModifiable: false, equipment: Object.assign({}, this.state.equipment, { neftisIp: e.target.value }) })} />
                                        </span>
                                        {this.state.neftisIpNotValid && <Message severity="error" text={msg('equipment.message.not.valid.ip')} key="startTimeError" />}
                                    </div>
                                </div>}
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-6">
                                        <div className="p-col-6"><label>{msg('equipment.configuration.borne.neftisStationId')}</label></div>
                                    </div>
                                    <div className="p-col-6">
                                        <span className="md-inputfield">
                                            <InputText value={this.state.equipment.neftisId} onChange={e => this.setState({ isModifiable: false, equipment: Object.assign({}, this.state.equipment, { neftisId: e.target.value }) })} type="text" />
                                        </span>
                                        {this.state.neftisIdNotValid && <Message severity="error" text={msg('equipment.message.not.valid.number')} key="startTimeError" />}
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div className="detail p-col-12">
                            <h3 style={{ marginTop: '-.1em' }}>{msg('equipment.configuration.server')}</h3>
                        </div>
                        <div className="p-grid form-group p-col-10">
                            <div className="p-grid form-group p-col-10">
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-6">
                                        <div className="p-col-12"><label>{msg('equipment.configuration.borne.server.ip')}</label></div>
                                    </div>
                                    <div className="p-col-6">
                                        <span className="md-inputfield">
                                            <InputText value={this.state.codeServer.ip}  onChange={e => this.setState({ isModifiable: false, codeServer: Object.assign({}, this.state.codeServer, { ip: e.target.value }) })} />
                                        </span>
                                        {this.state.ipNotValid && <Message severity="error" text={msg('equipment.message.not.valid.ip')} key="startTimeError" />}
                                    </div>
                                </div>}
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-6">
                                        <div className="p-col-6"><label>{msg('equipment.configuration.borne.server.port')}</label></div>
                                    </div>
                                    <div className="p-col-6">
                                        <span className="md-inputfield">
                                            <InputText value={this.state.codeServer.port}  onChange={e => this.setState({ isModifiable: false, codeServer: Object.assign({}, this.state.codeServer, { port: e.target.value }) })} />
                                        </span>
                                        {this.state.portNotValid && <Message severity="error" text={msg('equipment.message.not.valid.number')} key="startTimeError" />}
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div className="detail p-col-12">
                            <h3 style={{ marginTop: '-.1em' }}>{msg('equipment.configuration.jornalisation.parameters')}</h3>
                        </div>
                        <div className="p-grid form-group p-col-10">
                            <div className="p-grid form-group p-col-10">

                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-6">
                                        <div className="p-col-12"><label>{msg('equipment.configuration.borne.logLevel')}</label></div>

                                    </div>
                                    <div className="p-col-4">
                                        <span className="md-inputfield">
                                            <Dropdown options={logsLevel} optionLabel="key" value={this.state.equipment.logLevel}
                                                //selectedItemsLabel={this.state.equipment.logLevel.length + " " + msg('group.selected.items')}
                                                onChange={e => this.setState({ isModifiable: false, equipment: Object.assign({}, this.state.equipment, { logLevel: e.value }) })}
                                                placeholder="Selectionnez" key="loglevel"
                                            />
                                        </span>
                                        {this.state.logLevelNotValid && <Message severity="error" text={msg('equipment.message.not.valid.number')} key="startTimeError" />}
                                    </div>
                                </div>}

                                {<div className="p-grid p-col-12">
                                    <div className="p-grid p-col-7">
                                        <div className="p-col-12"><label>{msg('equipment.configuration.borne.maintenance')}</label></div>
                                    </div>
                                    <div className="p-col-2">
                                        <span className="md-inputfield">
                                            <Checkbox value={this.state.equipment.maintenance} onChange={e => this.setState({ isModifiable: false, equipment: Object.assign({}, this.state.equipment, { maintenance: e.checked }) })}
                                                checked={this.state.equipment.maintenance}></Checkbox>  </span>
                                    </div>
                                </div>}
                                {this.state.maintenanceCauseNotValid && <Message severity="error" text={msg('equipment.input.maintenanceCause.required')} key="maintenanceCauseError" />}
                                {
                                    this.state.equipment.maintenance &&
                                    <div className="p-grid p-col-12">
                                        <div className="p-field-radiobutton p-col-6">
                                                <RadioButton inputId="maintenanceCause0" name={MAINTENANCE_CAUSE_STATE[0]} value={MAINTENANCE_CAUSE_STATE[0]} onChange={this.maintenanceMaintenanceCauseOnChange} checked={this.state.equipment.maintenanceCause === MAINTENANCE_CAUSE_STATE[0]} />
                                            <label htmlFor="maintenanceCauseMaintenance">{msg('equipment.configuration.borne.maintenanceCause.maintenance')}</label>
                                        </div>
                                        <div className="p-field-radiobutton p-col-6">
                                                <RadioButton inputId="maintenanceCause1" name={MAINTENANCE_CAUSE_STATE[1]} value={MAINTENANCE_CAUSE_STATE[1]} onChange={this.droughtMaintenanceCauseOnChange} checked={this.state.equipment.maintenanceCause === MAINTENANCE_CAUSE_STATE[1]} />
                                            <label htmlFor="maintenanceCauseDrought">{msg('equipment.configuration.borne.maintenanceCause.drought')}</label>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header={msg('equipment.configuration.timetable')}>
                        <div className="p-grid form-group p-col-12">
                            <div className="p-grid form-group p-col-12" >
                                    <div className="p-col-3 "><label>{msg('equipment.opening.time')}</label></div>
                                    <div className="p-col-6">
                                    <span className="md-inputfield">
                                        <Dropdown options={borneOpeningHourOptions} value={this.state.equipment.borneOpeningHourOption} 
                                            optionLabel="key" dataKey="key"
                                                onChange={e => this.handleOpeningTimeChange(e)} autoWidth = {true} />
                                    </span>
                                </div>
                            </div>
                            {!this.state.equipment.enableTimeTable && 
                            <TimeTable timetable={this.state.equipment.timetable} setTimetableState={this.setTimetableState} />}
                        </div>
                    </TabPanel>
                </TabView>

                <MessageDialog className={'message-dialog error-message'} visible={this.state.sessionExpired} title={msg('firmware.fields.upload.title.error')}
                    message={msg("login.unauthorized.internalauthenticationserviceexception")}
                    onHide={e => this.handleErrorExpiredSessionChange(e)} onCancel={e => this.handleErrorExpiredSessionChange(e)} />

                <MessageDialog className={'message-dialog error-message'} visible={this.state.hardwareOrfirmwareexist} title={msg('message.dialog.error.title')}
                    message={msg('equipment.hardware.exist')}
                    onHide={() => this.setState({ hardwareOrfirmwareexist: false })} />
                <MessageDialog className={'message-dialog warn-message'} visible={this.state.isNotDownload} title={msg('message.dialog.warn.title')}
                    message={msg('error.equipment.target.configuration.cannot.be.build')}
                    onHide={() => this.setState({ isLignSelected: false, isNotDownload: false })} />

                <MessageDialog className={'message-dialog error-message'} visible={this.state.isApiError || this.state.isError || this.state.isMeanOfPayment} title={msg('message.dialog.error.title')}
                    message={(this.state.isApiError && msg(this.props.error.message))
                        || (this.state.isError && msg(this.state.errorMsg))
                        || (this.state.isMeanOfPayment && msg('equipment.meanOfPayment.exist'))}
                    onHide={e => this.handleErrorChange(e)} onCancel={e => this.handleErrorChange(e)} />

                <MessageDialog confirm={true} className={'confirm-message'} visible={this.state.showConfirmUpload && this.state.borneExiste} title={msg('message.dialog.confirm.title')}
                    message={msg('equipements.upload.file.config.confirm.message')}
                    key="confirm-delete"
                    onConfirm={() => { this.setState({ uploadOk: true }); }}
                    onCancel={() => this.setState({ UploadNo: true })} />

                <MessageDialog className={'message-dialog info-message'}
                    confirm={true}
                    button_yes={true} button_no={true}
                    visible={this.state.isEquipmentUpdated && this.state.borneExiste}
                    title={msg('equipment.borne.confirmation')}
                    message={msg("equipment.borne.update.confirmation.line1")}
                    messagesArray={[
                        msg("equipment.borne.update.confirmation.line2", { equipmentName: equipment.name }),
                        msg("equipment.borne.update.confirmation.line3", { serialNumber: (borne !== null ? borne.serialNumber : "") }),
                    ]}
                    onConfirm={this.onConfirmUpdateBorneDialog}
                    onCancel={this.onCancelUpdateBorneDialog} />

                 <div className="p-grid p-col-12">
                    <div className="p-grid p-col-9">
                    </div>
                    <div className="p-grid p-col-3">
                        <Button label={(msg('user.validate'))} title={(msg('user.validate'))} onClick={this.doOnSave} className="button" disabled={!uAuthorized} />
                        <Link to="/equipment">
                            <Button  label={(msg('user.reset'))} className="button" />
                        </Link>
                    </div>
                </div>

            </div>);
    }
}

export default UpdateEquipment;