import React, { Component } from 'react';
import { windowUtils } from '../../../ui/WindowUtils';
import ActivityAnalysis from '../../common/ActivityAnalysis';
import BorneFilter from '../../common/BorneFilter';
import BorneAnalysis from './BorneAnalysis';
import { rolesValidator, TRADE } from '../../../utils/RolesValidator.js';
import msg from '../../../texts/msg';
import { withRouter } from 'react-router-dom';

class Analysis extends Component {

	constructor(props) {
		super(props);
		let stationId = this.getRedirectStationId();
		this.state = stationId ? {initFilter: {stations: [{key: stationId, value: ''}]}} : {};
	}

	componentDidMount(){
		let stationId = this.getRedirectStationId();
		if(stationId){
			this.setState({initFilter: {stations: [{key: stationId, value: ''}]}});
		}
	}
		
	getRedirectStationId(){
		let path = "/commerce/analysis/";
		let uri = this.props.location.pathname;
		return uri.startsWith(path) ? uri.replace(path, "") : null;
	}

	getBorneAnalysisModel(activity, bHeaders, bContentHeaders) {
		let articles = activity && activity.articles ? activity.articles : [];
		let dataModel = [];
		articles.sort((a, b) => b.amount - a.amount);
		articles.forEach(e => { dataModel.push({ label: e.label, value: e.amount, type: e.type, color: e.color, unit: ' €', eanCode: e.eanCode }); });
		let borneCharts = [{ title: '', type: 'pie', data: dataModel }];
		let analysisModel = { headers: bHeaders, charts: borneCharts, contentHeaders: bContentHeaders };
		return analysisModel;
	}

	getBorneMultiAnalysisModel(activity, bHeaders, bContentHeaders) {
		let articles = activity && activity.articles ? activity.articles : [];
		let dataModel = [];
		articles.sort((a, b) => b.amount - a.amount);
		articles.forEach(e => { dataModel.push({ label: e.label, value: e.amount, type: e.type, color: e.color, unit: ' €', eanCode: e.eanCode }); });
		let borneCharts = [{ title: '', type: 'pie', data: dataModel }];
		let multiAnalysisModel = { headers: bHeaders, charts: borneCharts, contentHeaders: bContentHeaders };
		return multiAnalysisModel;
	}

	getHpAnalysisModel() {
		let hpActivity = this.props.data && this.props.data.hpActivity ? this.props.data.hpActivity : null;
		let amount = hpActivity && hpActivity.amount ? hpActivity.amount.toFixed(2) : '0.00';
		//usage: {value: '0.00', label: 'Minutes'} TODO
		let headers = { title: msg('analysis.h.p'), value: amount + ' €', usage: { value: hpActivity && hpActivity.amount ? hpActivity.count : '0', label: msg('analysis.HPWhashes') } };
		let averageBasket = hpActivity && hpActivity.averageBasket ? hpActivity.averageBasket.toFixed(2) : '0.00'
		//label: 'Moyenne quotidienne', value: '0.00', unit: 'min'} TODO
		let contentHeaders = [{ label: msg('analysis.hp.averageBasket'), value: averageBasket, unit: ' €' }, { label: '', value: '', unit: '' }];
		return this.getBorneAnalysisModel(hpActivity, headers, contentHeaders);
	}

	getAccessAnalysisModel() {
		let accessActivity = this.props.data && this.props.data.accessActivity ? this.props.data.accessActivity : null;
		let amount = accessActivity && accessActivity.amount ? accessActivity.amount.toFixed(2) : '0.00';
		////usage: {value: '0.00', label: 'Minutes'} TODO
		let headers = { title: msg('analysis.Accessories'), value: amount + ' €', usage: { value: accessActivity && accessActivity.amount ? accessActivity.count : '0', label: msg('analysis.accessories.transactions') } };
		let averageBasket = accessActivity && accessActivity.averageBasket ? accessActivity.averageBasket.toFixed(2) : '0.00'
		//label: 'Moyenne quotidienne', value: '0.00', unit: 'min'} TODO
		let contentHeaders = [{ label: msg('analysis.averageBasket'), value: averageBasket, unit: ' €' }, { label: '', value: '', unit: '' }];
		return this.getBorneAnalysisModel(accessActivity, headers, contentHeaders);
	}

	getMultiAnalysisModel() {
		//title: 'Taux d"utilisation des options'
		let multiActivity = this.props.data && this.props.data.multiActivity ? this.props.data.multiActivity : null;
		let amount = multiActivity && multiActivity.amount ? multiActivity.amount.toFixed(2) : '0.00';
		let headers = { title: msg('analysis.multiPrograms'), value: amount + ' €', usage: { value: multiActivity && multiActivity.amount ? multiActivity.count : '0', label: msg('analysis.MPLavage') } };
		let averageBasket = multiActivity && multiActivity.averageBasket ? multiActivity.averageBasket.toFixed(2) : '0.00';
		let washingsPerDay = multiActivity && multiActivity.washingsPerDay ? multiActivity.washingsPerDay : 0;
		let contentHeaders = [{ label: msg('analysis.washDaily'), value: washingsPerDay.toFixed(2), unit: null }, { label: msg('analysis.multiPrograms.averageBasket'), value: averageBasket, unit: ' €' }];
		return this.getBorneMultiAnalysisModel(multiActivity, headers, contentHeaders);
	}

	buildSubHeader(subHeaderAnalysis) {
		return (
			<div className="p-col-12 p-grid sub-header">
				<div className="p-col-8 label">{subHeaderAnalysis[0].label}</div>
				<div className="p-col-4 amount" style={{ color: subHeaderAnalysis[0].color }}>{subHeaderAnalysis[0].value}</div>
				<div className="p-col-8 label">{subHeaderAnalysis[1].label}</div>
				<div className="p-col-4 amount" style={{ color: subHeaderAnalysis[1].color }}>{subHeaderAnalysis[1].value}</div>
			</div>);
	}

	render() {

		let authorized = rolesValidator.validate([TRADE]);
		let data = this.props.data && this.props.data.activities ? this.props.data.activities.year : null;
		let prestations = data ? data.prestations : null;

		if(prestations && prestations.paymentItems) {
			if (prestations.paymentItems["PAID_CODE"]) {
				prestations.paymentItems["PAID_CODE"].amount += (prestations.paymentItems["PAID_APP_CODE"]?.amount || 0);
			} else {
				prestations.paymentItems["PAID_CODE"] = { ...prestations.paymentItems["PAID_APP_CODE"] };
			}
			delete prestations.paymentItems["PAID_APP_CODE"];
		}

		let ventes = data ? data.ventes : null;
		let hpAnalysisModel = this.getHpAnalysisModel();
		let accessAnalysisModel = this.getAccessAnalysisModel();
		let multiAnalysisModel = this.getMultiAnalysisModel();
		if (data && ventes) {
			ventes.cardReloadCount = data.ventes.cardReloadCount;
			ventes.purchasedCardCount = data.ventes.purchasedCardCount;
		}
		let venteSubHeaderAnalysisData = [{ label: msg('analysis.vente.card.reload.count'), value: ventes ? ventes.cardReloadCount : 0, color: '#4b99d3' },
		{ label: msg('analysis.vente.purchased.card.count'), value: ventes ? ventes.purchasedCardCount : 0, color: '#4b99d3' }];
		let venteSubHeaderAnalysis = this.buildSubHeader(venteSubHeaderAnalysisData);
		let filter = (<div className="p-col-12 p-md-4 activity-analysis">
			<BorneFilter apply={this.props.load} reset={this.props.load}
				loadFilter={(filter) => this.props.loadFilter(filter)} loadFilterStations={this.props.loadFilterStations}
                filterData={this.props.filterData} filterStations={this.props.filterStations}
				showDate={true} showStation={true} showEquipment={true} showPeriodType={false}
				showGroupStation={true} showChannel={true} showContract={true}
				title={msg('analysis.analysisPeriod')} setDataInfoBar={this.props.setDataInfoBar} initFilter={this.state.initFilter} />
		</div>);
		return authorized && (
			<div className="p-grid p-fluid dashboard">
				{!windowUtils.isDesktop() && filter}
				<div className="p-col-12 p-md-4 activity-analysis">
					<ActivityAnalysis activityClass="prest" activityIcon="local-car-wash" label={msg('featurekey')}
						data={prestations} chartLabels={[msg('payment.chart.title'), msg('type.bornes.chart.title')]} hValueName={'amount'} hUnit={'€'}
						itemValueName={'amount'} unit={'€'} rounding={true} firstCharItemsName={'paymentItems'} secondCharItemsName={'typeItems'} />
				</div>
				<div className="p-col-12 p-md-4 activity-analysis">
					<ActivityAnalysis activityClass="vente" activityImageSrc="/assets/ultima/layout/images/cartadis/Vente_bleu.svg" label={msg('sellingkey')}
						data={ventes} chartLabels={[msg('payment.chart.title'), msg('type.products.chart.title')]} hValueName={'amount'} hUnit={'€'}
						itemValueName={'amount'} unit={'€'} rounding={true} firstCharItemsName={'paymentItems'} secondCharItemsName={'typeItems'}
						subHeader={venteSubHeaderAnalysis} />
				</div>
				{windowUtils.isDesktop() && filter}
				{(<div className="p-col-12 p-md-4 activity-analysis">
					<BorneAnalysis headers={multiAnalysisModel.headers} contentHeaders={multiAnalysisModel.contentHeaders} charts={multiAnalysisModel.charts} title={msg('analysis.multi.program.title')} />
				</div>)}
				<div className="p-col-12 p-md-4 activity-analysis">
					<BorneAnalysis headers={hpAnalysisModel.headers} contentHeaders={hpAnalysisModel.contentHeaders} charts={hpAnalysisModel.charts} title={msg('analysis.h.p.title')} />
				</div>
				<div className="p-col-12 p-md-4 activity-analysis">
					<BorneAnalysis headers={accessAnalysisModel.headers} contentHeaders={accessAnalysisModel.contentHeaders} charts={accessAnalysisModel.charts} title={msg('analysis.Accessories.title')} />
				</div>
			</div>
		);
	}
}

export default withRouter(Analysis);