import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { userSessionParamsHandler, ALARMS_DIALOG_TABLE_ID } from '../../../utils/UserSessionParamsHandler';
import TableData from '../../../utils/TableData';
import { toLocaleDateTimeFormat } from '../../../utils/dateTimeUtils';
import MessageDialog from '../../common/MessageDialog';

import msg from '../../../texts/msg';

class OpenAlarmsDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: { page: 0, pageSize: userSessionParamsHandler.getPageSize(ALARMS_DIALOG_TABLE_ID)},
            showConfirm: false,
            xChildDialog: 0,
            yChildDialog: 0,
            closedAlarm: false,
        };
        this.onSort = this.onSort.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onSelectionItem = this.onSelectionItem.bind(this);
        this.confirmCloseAlarm = this.confirmCloseAlarm.bind(this);
        this.onContextMenuShow = this.onContextMenuShow.bind(this);
    }

    componentDidMount() {
        let pageSize = userSessionParamsHandler.getPageSize(ALARMS_DIALOG_TABLE_ID)
        this.props.load(this.props.equipment.id, 0, pageSize, this.state.filter);
    }

    onSort = (e, page) => {
        let pageSize = userSessionParamsHandler.getPageSize(ALARMS_DIALOG_TABLE_ID)
        let filter = Object.assign({}, this.state.filter);
        let sortDirection = this.state.sortDirection === "ASC" ? "DESC" : "ASC";
        filter.sortProperty = {key: e.sortField, value: sortDirection};
        this.props.load(this.props.equipment.id, page, pageSize, filter);
        this.setState({ sortDirection: sortDirection, filter: filter });
    }

    onHide() {
        this.setState( {filter: {page: 0, pageSize: userSessionParamsHandler.getPageSize(ALARMS_DIALOG_TABLE_ID)}});
        this.props.onHide(this.state.closedAlarm);
    }

    getCriticality(row){
        let criticality = row.criticality && row.criticality.toLowerCase();
        criticality = row.endDate ? 'closed' : criticality;
        let icon = (criticality === 'info' ? 
            (row.peripheral === "TERMINAL_ITSELF" && row.eventTypeValue === 270 ? "pi-md-sync" : "pi-md-info") : "pi-md-error");
        return (<span className={criticality} style={{ marginLeft: '-2px' }} > <i className={icon} /> </span>);
    }

    getDescription(row) {
        return (
            <div className="p-col-12">
                <ul style={{ marginLeft: '-45px', marginTop: '-25px', marginBottom: '0px' }}>
                    <li style={{ 'listStyle': 'none' }}>
                        <p>
                            <span>{row.peripheralDesc} :</span > <span style={{ fontWeight: 'bolder' }}> {row.description} </span>
                            {row.internal && <span style={{fontSize: 'xx-small', color: '#e4032e', fontStyle: 'italic', fontWeight: 'bold'}}>[P]</span>}
                        </p>
                    </li>
                    {row.comment &&
                    <li style={{ 'listStyle': 'none', marginBottom: '-12px' }}>
                        <span style={{ fontStyle: 'italic' }}> {msg('equipment.open.alarms.comment')} : {row.comment} </span>
                    </li>}
                </ul>
            </div>);
    }

    getOpenDate(row) {
        return (
        <div className="p-col-12">
            <ul style={{ marginLeft: '-45px', marginTop: '-25px', marginBottom: '0px' }}>
                <li style={{ 'listStyle': 'none' }}>
                    <span style={{ fontStyle: 'italic' }}> {toLocaleDateTimeFormat(row.openDate)} </span>
                </li>
                <li style={{ 'listStyle': 'none' }}>
                    <span style={{ fontStyle: 'italic' }}> {msg('equipment.open.alarms.duration') + ' : ' + row.duration} </span>
                </li>
            </ul>
        </div>);
    }

    onSelectionItem(event) {
        let alarmId = null;
        let alarmDescription = null;
        if (event.value && event.value.type === "ALARM" && event.value.id) {
            alarmId = event.value.id;
            alarmDescription = event.value.description;
        }
        this.setState({alarmId: alarmId, alarmDescription: alarmDescription});
    }

    confirmCloseAlarm() {
        if (this.state.alarmId) {
            this.props.closeAlarm(this.state.alarmId);
            this.onHide();
        }
    }

    onContextMenuShow(event) {
        const dialog = document.getElementsByClassName('p-dialog')[1].getBoundingClientRect();
        this.setState({xChildDialog: dialog.x, yChildDialog: dialog.y});
    }

    render() {
        let openAlarms = this.props.visible && this.props.alarms && this.props.alarms.openAlarms ? this.props.alarms.openAlarms : [];
        let totalPages = this.props.visible && this.props.alarms ? this.props.alarms.totalPages : 0;
        let first = this.props.visible && this.props.alarms && this.props.alarms.first === true ? this.props.alarms.first : null;
        let footer = (<div><Button label={msg('message.dialog.button.ok')} onClick={this.onHide} /></div>);
        let header = (<h4>{msg('equipment.open.alarms')}</h4>);
        let tableTitle = this.props.equipment.name;
        let columns = [
            { isColWithBody: false, field: "id", header: "id", sortable: false, className: "visibilityHidden" },
            { isColWithBody: true, field: "criticality", header: msg("equipment.open.alarms.criticality"), body: this.getCriticality, sortable: true, className: "p-col-2" },
            { isColWithBody: true, field: "description", header: msg('equipment.open.alarms.description'), body: this.getDescription, sortable: true, className: "p-col-6" },
            { isColWithBody: true, field: "openDate", header: msg('equipment.open.alarms.start.date'), body: this.getOpenDate, sortable: true, className: "p-col-4" }
        ]
        let closedAlarm = (
            <MessageDialog confirm={true} className={'alarm confirm-message'} visible={this.state.showConfirm} title={msg('message.dialog.confirm.title')}
                message={this.state.showConfirm && msg('alarm.close.confirm.message', { alarmeDescription: this.state.alarmDescription })} key="confirm-close"
                onConfirm={() => { this.setState({ showConfirm: false, closedAlarm: true }, () => this.confirmCloseAlarm()); }}
                onCancel={() => this.setState({ showConfirm: false, closedAlarm: false })}
            />
        );

        let ContextMenuContent = [];
        if (this.state.alarmId) {
            ContextMenuContent = [{
                label: msg('equipements.close.alarm.context.menu'),
                icon: 'pi pi-fw pi-times',
                command: () => this.setState({ showConfirm: true })
            }]
        } else {
            ContextMenuContent = undefined
        }

        let datatable = this.props.visible && (
            <TableData data={openAlarms} titleTable={tableTitle} totalPages={totalPages} first={first}
                getDataFunction={this.props.load} paramnFunctionData={this.props.equipment.id} 
                onSort={this.onSort} filter={this.state.filter} columns={columns} tableId={ALARMS_DIALOG_TABLE_ID}
                selectionMode={"single"} onSelection={this.onSelectionItem}
                ContextMenuContent={ContextMenuContent} onContextMenuShow={this.onContextMenuShow} childDialogPosition={{x: this.state.xChildDialog, y: this.state.yChildDialog}}
        />);


        let content = (<div className="p-col-12">{datatable}</div>);
        return <Dialog className="dialogue" visible={this.props.visible} modal={false} closable={false} style={{maxHeight: '75%', overflowY: 'scroll'}}
                    onHide={this.onHide} header={header} footer={footer}>
            {content}
            {this.state.alarmId && <div className="p-col-12" style={{ height: "100%" }}>
                {closedAlarm}
            </div>}
        </Dialog>;
    }
}

export default OpenAlarmsDialog;