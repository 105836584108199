import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import ActivityDashboard from './ActivityDashboard';
import MapContainer from '../../common/MapContainer';
import { rolesValidator, TRADE } from '../../../utils/RolesValidator.js';
import i18n from '../../../texts/i18n';
import msg from '../../../texts/msg';
import BorneFilter from '../../common/BorneFilter';
import { progressSpinnerHandler } from '../../../utils/ProgressSpinnerHandler.js';

class Dashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {};
		this.load = this.load.bind(this);
		this.reset = this.reset.bind(this);
		this.onRedirect = this.onRedirect.bind(this);
	}

	componentDidUpdate(prevProps) {
		let today = this.props.data && this.props.data.activities && this.props.data.activities.today;
		let prevToday = prevProps.data && prevProps.data.activities && prevProps.data.activities.today;
		let refreshDate = this.props.data ? this.props.data.refreshDate : null;
		if(today && today !== prevToday) {
			this.setState({todayData : today, refreshDate: refreshDate});
		}
		let yesterday = this.props.data && this.props.data.activities && this.props.data.activities.yesterday;
		let prevYesterday = prevProps.data && prevProps.data.activities && prevProps.data.activities.yesterday;
		if(yesterday && yesterday !== prevYesterday) {
			this.setState({yesterdayData : yesterday});
		}
		let month = this.props.data && this.props.data.activities && this.props.data.activities.month;
		let prevMonth = prevProps.data && prevProps.data.activities && prevProps.data.activities.month;
		if(month && month !== prevMonth) {
			this.setState({monthData : month});
		}
		let year = this.props.data && this.props.data.activities && this.props.data.activities.year;
		let prevYear = prevProps.data && prevProps.data.activities && prevProps.data.activities.year;
		if(year && year !== prevYear) {
			this.setState({yearData : year});
			progressSpinnerHandler.show(false);
		}
	}

	load(rFilter) {
		let filter = Object.assign({}, rFilter ? rFilter : this.props.filter);
		filter.label = 'today';
		this.props.load(filter);
		filter = Object.assign({}, rFilter ? rFilter : this.props.filter);
		filter.label = 'yesterday';
		this.props.load(filter);
		filter = Object.assign({}, rFilter ? rFilter : this.props.filter);
		filter.label = 'month';
		this.props.load(filter);
		filter = Object.assign({}, rFilter ? rFilter : this.props.filter);
		filter.label = 'year';
		this.props.load(filter);
		this.setState({filter : filter});
	}

	reset(rFilter) {
		let filter = Object.assign({}, rFilter ? rFilter : this.props.filter);
		filter.label = 'today';
		this.props.load(filter);
		filter.label = 'yesterday';
		this.props.load(filter);
		filter.label = 'month';
		this.props.load(filter);
		filter.label = 'year';
		this.props.load(filter);
		this.setState({filter : filter});
	}

	onRedirect(stationId) {
		this.setState({ redirect: true, stationId: stationId });
	}

	getMapHeight() {
		return (window.innerHeight * 0.75) + 'px';
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to={"/commerce/analysis/" + this.state.stationId} push={true} />;
		}
		let stationsMap = this.props.stationsMap ? this.props.stationsMap.stationMaps : null;
		let coordinateDefaultMaps = this.props.stationsMap ? this.props.stationsMap.coordinateDefaultMaps : null;
		let authorized = rolesValidator.validate([TRADE]);
		let todayDate = new Date();
		let locale = i18n.language ? i18n.language : 'fr-FR';
		let monthLegend = todayDate.toLocaleDateString(locale, { month: 'long' });
		let c = monthLegend.charAt(0);
		monthLegend = monthLegend.replace(c, c.toUpperCase());
		let height = this.getMapHeight();
		let filter = (<div className="p-col-12 p-md-12 activity-analysis">
			<BorneFilter apply={(filter) => this.load(filter)} reset={(filter) => this.reset(filter)}
				loadFilter={(filter) => this.props.loadFilter(filter)} loadFilterStations={this.props.loadFilterStations}
                filterData={this.props.filterData} filterStations={this.props.filterStations}
				showDate={false} showStation={true} showEquipment={true} showGroupStation={true} showChannel={true}
				setDataInfoBar={this.props.setDataInfoBar} initFilter={this.state.initFilter} filterBoxClassName="p-col-3" />
		</div>);
		return authorized && (
			<div className="p-grid p-fluid dashboard">
				<div className="p-col-12">{filter}</div>
				<div className="p-grid p-col-12 activity-data">
					<ActivityDashboard data={this.state.todayData} legend={msg('activity.period.today')} load={() => this.load(this.props.filter)} refresh={this.state.refreshDate} />
					<ActivityDashboard data={this.state.yesterdayData} legend={msg('activity.period.yesterday')} dayBefore={true} />
					<ActivityDashboard data={this.state.monthData} legend={monthLegend} evol={true} />
					<ActivityDashboard data={this.state.yearData} legend={todayDate.getFullYear()} evol={true} />
				</div>
				{<div className="p-col-12" style={{ minHeight: height }}>
					<MapContainer stationsMap={stationsMap} coordinateDefaultMaps={coordinateDefaultMaps} onRedirect={this.onRedirect} width={'100%'} height={height} />
				</div>}
			</div>
		);
	}
}

export default withRouter(Dashboard);