import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Dashbord from './dashboard/Dashboard';
import Analysis from './analysis/Analysis';
import ExploitAnalysis from './exploit/ExploitAnalysis';
import {dataTitlePanel, dataTitlePanelDashboard} from '../common/dataTitlePanel';
import {rolesValidator, TRADE, TRADE_EXPLOIT} from '../../utils/RolesValidator.js';
import { progressSpinnerHandler } from '../../utils/ProgressSpinnerHandler'
import '../common/css/activity-analysis.css';
import '../common/css/activity-analysis-xsm.css';
import '../common/css/activity-analysis-sm.css';
import '../common/css/activity-analysis-md.css';
import '../common/css/activity-analysis-lg.css';
import '../common/css/activity-analysis-xlg.css';
import '../common/css/activity-analysis-xxlg.css';

class Commerce extends Component {
	
	constructor(props) {
        super(props);
        this.state = {submenu: this.props.match.params.submenu};
        this.loadDataDashbord = this.loadDataDashbord.bind(this);
        this.loadDataAnalysis = this.loadDataAnalysis.bind(this);
        this.loadDataExploitAnalysis = this.loadDataExploitAnalysis.bind(this);
        this.loadFilter = this.loadFilter.bind(this);
        this.loadFilterStations = this.loadFilterStations.bind(this);
	}

    componentDidUpdate(prevProps) {
        if(this.props.match.params.submenu !== prevProps.match.params.submenu){
            this.setState({submenu: this.props.match.params.submenu});
        }
        if(this.props.analysis && this.props.analysis !== prevProps.analysis){
            progressSpinnerHandler.show(false);
        }
        if(this.props.exploitAnalysis && this.props.exploitAnalysis !== prevProps.exploitAnalysis){
            progressSpinnerHandler.show(false);
        }
    }
    
    loadFilter(filter) {
        this.props.loadFilter(TRADE, filter);
        this.setState({filter: filter});
    }
    
    loadFilterStations(filter) {
        let authorized = rolesValidator.validate([TRADE]);
        let feature = authorized ? TRADE  : null;
        if(!feature){
            authorized = rolesValidator.validate([TRADE_EXPLOIT]);
            feature = authorized ? TRADE_EXPLOIT.replace("_", "/")  : null;
        }
        if(feature){
            this.props.loadFilterStations(feature, filter);
        }
    }

    loadDataDashbord(filter) {
        progressSpinnerHandler.show(true);
        this.props.loadDashbord(filter);
        if(!filter.label || filter.label === 'today'){
            this.props.loadStationsMap(TRADE, filter);
            this.props.setDataInfoBar(dataTitlePanelDashboard(filter));
        }
        this.setState({filter: filter});
    }

    loadDataAnalysis(filter) {
        progressSpinnerHandler.show(true);
        this.props.loadAnalysis(filter);
        this.props.setDataInfoBar(dataTitlePanel(filter));
        this.setState({filter: filter});
    }

    loadDataExploitAnalysis(filter) {
        progressSpinnerHandler.show(true);
        this.props.loadExploitAnalysis(filter);
        this.props.setDataInfoBar(dataTitlePanel(filter));
    }
  
    render() {
        let authorized = rolesValidator.validate([TRADE]);
        let tradeItem = "";
        let dashbordItem = <Dashbord load={this.loadDataDashbord} loadFilter={this.loadFilter} loadFilterStations={this.loadFilterStations}
            data={this.props.dashbord} filterData={this.props.filterData} filterStations={this.props.filterStations} stationsMap={this.props.stationsMapData} 
            filter={this.state.filter}/>;
        switch(this.state.submenu) {
            case 'dashboard' : {     
                tradeItem = dashbordItem;
                break;
            }
            case 'analysis' : {
                tradeItem = (<Analysis load={this.loadDataAnalysis} loadFilter={this.loadFilter} loadFilterStations={this.loadFilterStations}
                    data={this.props.analysis} filterData={this.props.filterData} filterStations={this.props.filterStations} />);
                break;
            }
            case 'exploit' : {
                authorized = rolesValidator.validate([TRADE_EXPLOIT]);
                tradeItem = (<ExploitAnalysis load={this.loadDataExploitAnalysis} loadFilter={this.loadFilter} loadFilterStations={this.loadFilterStations}
                    data={this.props.exploitAnalysis} filterData={this.props.filterData} filterStations={this.props.filterStations} />);
                break;
            }
            default : {
                tradeItem = dashbordItem;
            }
       }
      
        return authorized && (
            <div className="card p-grid p-col-12">
                <div className="card p-col-12">
	  				{tradeItem}
                </div>
            </div>
        );
    }
}

export default withRouter (Commerce);