/* Component pour afficher la liste des équipements et les transactions attachées a chaque équipement  */

import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { rolesValidator, ADMINEQUIPMENTS, CREATE, READ, UPDATE, DELETE } from '../../../utils/RolesValidator.js';
import TableData from '../../../utils/TableData.js';
import BorneFilter from '../../common/BorneFilter';
import MessageDialog from '../../common/MessageDialog';
import UploadService from "../../../middleware/download-upload-files.service";
import { progressSpinnerHandler } from '../../../utils/ProgressSpinnerHandler.js';
import { userSessionParamsHandler , ADMIN_EQUIPMENTS_TABLE_ID} from '../../../utils/UserSessionParamsHandler.js';
import msg from '../../../texts/msg';
import '../Administration.css';

const MAX_LENGTH = 20;
const ADMIN_EQUIPMENTS_FILTER_KEY = "adminEquipmentFilter";

export class AdminEquipments extends Component {

    constructor(props) {
        super(props);
        const date = new Date();
        date.setDate(1);
        let lBorneFilter = userSessionParamsHandler.getFilter(ADMIN_EQUIPMENTS_TABLE_ID);
        this.state = {
            first: 0,
            userExist: false,
            tieredItems: "test",
            display: false,
            closable: false,
            buttonSelectd: false,
            isLignSelected: false,
            isAssociatedBorne: false,
            isNotDownload: false,
            showConfirmDeleteBorne: false,
            borneFilter: lBorneFilter ? lBorneFilter : sessionStorage.getItem(ADMIN_EQUIPMENTS_FILTER_KEY) ? JSON.parse(sessionStorage.getItem(ADMIN_EQUIPMENTS_FILTER_KEY)) : { sortProperty: { key: 's.implant', value: "ASC" } },
            sortDirection: "ASC"
        }
        this.onSelection = this.onSelection.bind(this);
        this.loadAdminequipments = this.loadAdminequipments.bind(this);
        this.loadEquipmentsByLabel = this.loadEquipmentsByLabel.bind(this);
        this.loadFilter = this.loadFilter.bind(this);
        this.loadFilterStations = this.loadFilterStations.bind(this);
        this.update = this.update.bind(this);
        this.deleteEquipment = this.deleteEquipment.bind(this);
        this.updateBorne = this.updateBorne.bind(this);
        this.onSort = this.onSort.bind(this);
        this.applyBorneFilter = this.applyBorneFilter.bind(this);
        this.downloadTargetConfiguration = this.downloadTargetConfiguration.bind(this);
        this.onQuickSearchChange = this.onQuickSearchChange.bind(this);
    }

    componentDidMount() {
        progressSpinnerHandler.show(true); 
        this.props.setDataInfoBar(this.dataInfobar(null));
        const page = 0;
        const pageSize = userSessionParamsHandler.getPageSize(ADMIN_EQUIPMENTS_TABLE_ID);
        this.props.loadAdminequipments(page, pageSize, this.state.borneFilter);
        userSessionParamsHandler.fireFilterChange(ADMIN_EQUIPMENTS_TABLE_ID, this.state.borneFilter);
    }

    componentDidUpdate(prevProps) {
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            progressSpinnerHandler.show(false);
            let isError = this.props.error.message && this.props.error.message.startsWith('error.equipment.') ? true : false;
            this.setState({ isApiError: isError });
        }
        if(this.props.adminequipments  && this.props.adminequipments  !== prevProps.adminequipments){
            progressSpinnerHandler.show(false);
        }
        let prevDelete = prevProps.equipmentDeleteById && prevProps.equipmentDeleteById.lastChange;
        let lastDelete = this.props.equipmentDeleteById && this.props.equipmentDeleteById.lastChange;
        let reload = (lastDelete && (!prevDelete || prevDelete < lastDelete));
        if (reload) {
            const page = 0;
            const pageSize = userSessionParamsHandler.getPageSize(ADMIN_EQUIPMENTS_TABLE_ID);
            this.props.loadAdminequipments(page, pageSize, JSON.parse(sessionStorage.getItem(ADMIN_EQUIPMENTS_FILTER_KEY)));
        }

        let prevUpdateBorne = prevProps.updateBorneData.data && prevProps.updateBorneData.data.lastChange;
        let lastUpdateBorne = this.props.updateBorneData.data && this.props.updateBorneData.data.lastChange;
        let updated = (lastUpdateBorne && (!prevUpdateBorne || prevUpdateBorne < lastUpdateBorne));
        if (updated) {
            this.setState({ isUpdated: true });
        }
    }

    loadFilter(borneFilter) {
        this.props.loadFilter(ADMINEQUIPMENTS, borneFilter);
        this.props.setDataInfoBar(this.dataInfobar(borneFilter));
        this.setState({ borneFilter: borneFilter });
    }
    
    loadFilterStations(filter) {
        this.props.loadFilterStations(ADMINEQUIPMENTS, filter);
    }

    loadAdminequipments(page, pageSize, filter) {
        progressSpinnerHandler.show(true); 
        this.props.loadAdminequipments(page, pageSize, filter);
    }

    loadEquipmentsByLabel(quickSearch) {
        let page = 0;
        let pageSize = userSessionParamsHandler.getPageSize(ADMIN_EQUIPMENTS_TABLE_ID);
        this.loadAdminequipments(page, pageSize, this.state.borneFilter);        
    }

    onQuickSearchChange(e){
        let filter = Object.assign({}, this.state.borneFilter);
        filter.label = e.target.value;
        this.setState({ borneFilter: filter });
    }

    applyBorneFilter(filter) {
        this.loadAdminequipments(0, userSessionParamsHandler.getPageSize(ADMIN_EQUIPMENTS_TABLE_ID), filter);
        this.setState({ borneFilter: filter, equipmentId: null, equipmentName: null });
        this.props.setDataInfoBar(this.dataInfobar(filter));
        sessionStorage.setItem(ADMIN_EQUIPMENTS_FILTER_KEY, JSON.stringify(filter));
    }

    onSort = (e, page) => {
        progressSpinnerHandler.show(true); 
        let pageSize = userSessionParamsHandler.getPageSize(ADMIN_EQUIPMENTS_TABLE_ID)
        let filter = userSessionParamsHandler.getFilter(ADMIN_EQUIPMENTS_TABLE_ID);
        let sortDirection = this.state.sortDirection === "ASC" ? "DESC" : "ASC";
        filter.sortProperty = {key: e.sortField, value: sortDirection};
        this.props.loadAdminequipments(page, pageSize, filter);
        this.setState({ sortDirection: sortDirection, borneFilter: filter });
        progressSpinnerHandler.show(true);
    }

    dataInfobar(filter) {
        return [
            { key: "titleFilter", value: msg('filter.in.progress') },
            { key: "Canaux", value: filter && filter.saleChannels.length > 0 && filter.saleChannels[0].value ? msg("filter.channels.canal") + ": " + 
            ((filter.saleChannels[0].value === 'LOTUS') ?msg('station.contract.schannel.lotus') :filter.saleChannels[0].value ) + ";" : msg("filter.channels.canal") + ": " + msg('filter.channels.all') + ";" },
            { key: "Station", value: filter && filter.stations.length > 0 && filter.stations[0].value ? msg('filter.station') + ": " + filter.stations[0].value + ";" : msg('filter.station') + ": " + msg('filter.stations.all') + ";" },
            { key: "Type bornes", value: filter && filter.borneTypes.length > 0 && filter.borneTypes[0].value ? msg('filter.borne.type') + ": " + filter.borneTypes[0].value + ";" : msg('filter.borne.type') + ": " + msg('filter.bornetypes.all') + ";" },
            { key: "Bornes", value: filter && filter.bornes.length > 0 && filter.bornes[0].value !== "" ? msg('filter.bonre') + ": " + filter.bornes[0].value + ";" : msg('filter.bonre') + ": " + msg('filter.equipments.all') }
        ]
    }

    update() {
        if (this.state.equipmentId && this.state.buttonSelectd) {
            this.props.loadEquipment(this.state.equipmentId);
            this.setState({ buttonSelectd: false });
            this.props.history.push('/equipment/update');
        } else {
            this.setState({ isLignSelected: true });
        }
    }

    updateBorne() {
        if (this.state.equipmentUpdated) {
            this.props.updateBorne(this.state.equipmentId);
        }
    }
    confirmDeleteEquipment() {
        if (this.state.equipmentId) {
            this.props.deleteEquipment(this.state.equipmentId);
            this.setState({ buttonSelectd: false });
        } else {
            this.setState({ isLignSelected: true });
        }
        this.setState({ buttonSelectd: false });
    }

    onSelection(event) {
        if (event.value != null) {
            if(event.value.borne != null) {
                this.setState({ borneName: event.value.borne.borneType });
            }
            this.setState({ equipmentName: event.value.name });
            this.setState({ equipmentId: event.value.id });
            this.setState({ transaction: event.value.transaction });
            this.setState({ alarme: event.value.alarme });
            this.setState({ campagne: event.value.campagne });
            this.setState({ equipmentUpdated: event.value.updated });
            this.setState({ buttonSelectd: true });
            this.setState({ serialNumber: null });
            if (event.value.borne && event.value.borne.serialNumber) {
                this.setState({ serialNumber: event.value.borne.serialNumber });
            }
        }
    }

    downloadTargetConfiguration() {
        if (this.state.equipmentId && this.state.buttonSelectd) {
            let configFileName = "config";
            if (this.state.borneName) {
                configFileName = this.state.borneName + "_config_" + this.state.equipmentId + ".json";
            }

            UploadService.getFileData("adminequipments/" + this.state.equipmentId + "/configuration", configFileName, null).then((response) => {
                const fname = configFileName;
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    if (response.status === "401" || response.status === 401) {
                        this.setState({ buttonSelectd: false, sessionExpired: true });
                    } else if (response.status === 400 || response.status === "400") {
                        this.setState({ isNotDownload: true, buttonSelectd: false });
                    }
                    else {
                        this.setState({ buttonSelectd: false });
                        a.href = url;
                        a.download = fname;
                        a.click();
                    }
                    progressSpinnerHandler.show(false);
                });
            });
        }
    }

    deleteEquipment() {
        if (this.state.equipmentId && this.state.serialNumber) {
            if (this.state.transaction) {
                this.setState({ hiddenConfirmDelete: true });
            } else {
                if (this.state.alarme) {
                    this.setState({ showConfirmDeleteBorneAlarme: true });
                    this.setState({ serialNumber: null });
                } else {
                    if (this.state.campagne) {
                        this.setState({ showConfirmDeleteBorneCampagne: true });
                        this.setState({ serialNumber: null });
                    } else {
                        this.setState({ showConfirmDeleteBorne: true });
                    }
                }
            }
        } else {
            if (this.state.transaction) {
                this.setState({ hiddenConfirmDelete: true });
            } else {
                if (this.state.alarme) {
                    this.setState({ showConfirmDeleteBorneAlarme: true });
                    this.setState({ serialNumber: null });
                } else {
                    if (this.state.campagne) {
                        this.setState({ showConfirmDeleteBorneCampagne: true });
                        this.setState({ serialNumber: null });
                    } else {
                        this.setState({ showConfirmDelete: true });
                        this.setState({ serialNumber: null });
                    }
                }
            }
        }
    }

    getSerialNumber(rowData, column) {
        let serialNumber = rowData.borne ? rowData.borne.serialNumber : "";
        if (serialNumber != null && serialNumber.length > 15)
            return (<div className="tooltip">
                <div className="hasTooltip p-ellipsis" style={{ marginRight: '5px' }} tooltip={serialNumber}>{serialNumber}
                    <span className="tooltiptext" style={{ height: '30px' }}>{serialNumber}</span></div>
            </div>);
        else
            return <div> {serialNumber} </div>
    }

    getFirmwareName(rowData, column) {
        if (rowData.borne && rowData.borne.currentFirmwareName) {

            let firmwareName = rowData.borne ? rowData.borne.currentFirmwareName : "";
            if (firmwareName != null && firmwareName.length > 15)
                return <div className="hasTooltip p-ellipsis"> {firmwareName} <span> {firmwareName}  </span></div>;
            else
                return <div> {firmwareName} </div>
        } else {

            if (rowData.currentFirmwareName != null && rowData.currentFirmwareName.length > 15)
                return <div className="hasTooltip p-ellipsis"> {rowData.currentFirmwareName} <span> {rowData.currentFirmwareName}  </span></div>;
            else
                return <div> {rowData.currentFirmwareName} </div>
        }
    }

    getHardware(rowData, column) {
        if (rowData.borne && rowData.borne.currentHardwareConfigName) {
            let hardwareName = rowData.borne ? rowData.borne.currentHardwareConfigName : "";
            let hardwareVersion = rowData.borne ? rowData.borne.currentHardwareConfigVersion : null;

            if (hardwareName != null && hardwareVersion != null)
                if (hardwareName.length > 15)

                    return <div className="hasTooltip p-ellipsis"> {hardwareName} <span> {hardwareName}  </span></div>;
                else
                    return <div> {hardwareName} </div>
        } else {
            if (rowData.currentHardwareConfigName != null && rowData.currentHardwareConfigVersion != null)
                if (rowData.currentHardwareConfigName.length > 15)
                    return <div className="hasTooltip p-ellipsis"> {rowData.currentHardwareConfigName} <span> {rowData.currentHardwareConfigName}  </span></div>;
                else
                    return <div> {rowData.currentHardwareConfigName} </div>
        }
    }

    getPricezone(rowData, column) {
        if (rowData.borne && rowData.borne.currentPriceZoneName) {
            let pricezoneName = rowData.borne ? rowData.borne.currentPriceZoneName : "";
            let pricezoneVersion = rowData.borne ? rowData.borne.currentPriceZoneVersion : null;
            if (pricezoneName != null && pricezoneVersion != null)
                if (pricezoneName.length > 15)
                    return <div className="hasTooltip p-ellipsis"> {pricezoneName} <span> {pricezoneName}  </span></div>;
                else
                    return <div> {pricezoneName} </div>
        } else {
            if (rowData.currentPriceZoneName != null && rowData.currentPriceZoneVersion != null)
                if (rowData.currentPriceZoneName.length > 15)
                    return <div className="hasTooltip p-ellipsis"> {rowData.currentPriceZoneName} <span> {rowData.currentPriceZoneName}  </span></div>;
                else
                    return <div> {rowData.currentPriceZoneName} </div>
        }
    }

    name(rowData) {
        let name = rowData.name ? rowData.name : "";
        if (name != null && name.length > 25)
            return <div className="hasTooltip p-ellipsis"> {name} <span> {name}  </span></div>;
        else
            return <div> {name} </div>

    }

    handleErrorChange(e) {
        this.setState({ isDone: false, isError: false, isNameValid: false, sessionExpired: false, selectedFile: null, message: null })
        this.setState({ currentFile: undefined });
    }

    handleErrorExpiredSessionChange(e) {
        this.setState({ isDone: false, isError: false, isNameValid: false, sessionExpired: false, selectedFile: null, message: null })
        this.setState({ currentFile: undefined });
        this.props.history.push('/');
        this.props.history.push('/equipment');
    }

    getStationNameAndImplant(rowData){
        let name = rowData.stationName ? rowData.stationName : ""
        let implant = rowData.station ? rowData.station.implant : ""
        let fullname = <div> 
                         <div>{implant}</div>
                         <div>{name}</div>
                       </div>
        if(name && name.length > MAX_LENGTH)
           return <div className="hasTooltip p-ellipsis"> {fullname} <span> {fullname}  </span></div>;
         else
          return <div> {fullname} </div> 
    }

    render() {
        let authorized = rolesValidator.validate([ADMINEQUIPMENTS]);
        let cAuthorized = authorized.toString().includes(CREATE);
        let rAuthorized = authorized.toString().includes(READ);
        let uAuthorized = authorized.toString().includes(UPDATE);
        let dAuthorized = authorized.toString().includes(DELETE);
        let columnsEquipments = [
            { isColWithBody: false, field: "id", header: "id", sortable: false, className: "visibilityHidden" },
            { isColWithBody: true, field: "station.implant",  header: msg('equipments.station.implant.name'), body: this.getStationNameAndImplant, sortable: true, className: "p-col-2" },
            { isColWithBody: true, field: "name", header: msg('equipements.station.name'), body: this.name, sortable: true, className: "p-col-2" },
            { isColWithBody: true, field: "borne.serialNumber", header: msg('equipements.borne.serialNumber'), body: this.getSerialNumber, sortable: true, className: "p-col-1 p-ellipsis" },
            { isColWithBody: false, field: "borne.borneType", header: msg('equipements.name'), sortable: true, className: "p-col-1" },
            { isColWithBody: true, field: "borne.currentFirmwareName", header: msg('equipements.borne.currentFirmwareName'), body: this.getFirmwareName, sortable: true, className: "p-col-2" },
            { isColWithBody: true, field: "borne.currentHardwareConfigName", header: msg('equipements.borne.currentHardwareName'), body: this.getHardware, sortable: true, className: "p-col-2" },
            { isColWithBody: true, field: "borne.currentPriceZoneName", header: msg('equipements.borne.currentPricezone'), body: this.getPricezone, sortable: true, className: "p-col-2" }
        ];

        let equipmentsOutilsBar = [
            { filename: "Liste des équipments.xlsx", iconSrc: "/assets/ultima/layout/images/cartadis/excel.svg", titleIcone: "Equipements", tooltipTextIcone: msg('equipement.tooltip.export'), urlExport: "equipments/equipments-excel/true" }
        ]

        let equipments = this.props.adminequipments && this.props.adminequipments.equipments ? this.props.adminequipments.equipments : [];
        let totalPages = this.props.adminequipments ? this.props.adminequipments.totalPages : 0;
        let ContextMenuContent =
        (this.state.equipmentUpdated ? [
            {
              label: msg('equipment.button.update'),
              command: this.update
            },
            {
              label: msg('equipment.button.delete'),
              command: this.deleteEquipment
            },
            {
              label: msg('equipment.button.config.download.tooltip'),
              command: this.downloadTargetConfiguration
            },
            {
                label: msg('equipment.button.borne.update.tooltip'),
                command: this.updateBorne
            }]
            : [
            {
              label: msg('equipment.button.update'),
              command: this.update
            },
            {
              label: msg('equipment.button.delete'),
              command: this.deleteEquipment
            },
            {
              label: msg('equipment.button.config.download.tooltip'),
              command: this.downloadTargetConfiguration
            }]
        );
        const tabelEquipments = (
            <TableData data={equipments} titleTable={msg('equipements.admin.title')} totalPages={totalPages}
                ContextMenuContent={ContextMenuContent}
                getDataFunction={this.loadAdminequipments}
                paramnFunctionData={null}
                apply={this.loadEquipmentsByLabel}
                onQuickSearchChange={this.onQuickSearchChange}
                onSort={this.onSort}
                selectionMode={"single"}
                onSelection={this.onSelection}
                outilsBar={equipmentsOutilsBar}                
                filter={this.state.borneFilter}
                columns={columnsEquipments}
                searchInTable={false} searchByName={true} nameTxtPlaceholder={msg('equipments.quick.search.placeholder')}
                tableId={ADMIN_EQUIPMENTS_TABLE_ID}
            />)
        return rAuthorized && (
            <div className="p-grid commonClass card card-w-title">
                <div className="p-col-12">
                    <h1>{msg('equipements')}</h1>
                </div>
                <div className="p-col-12">
                    <div className="item p-col-12 p-md-1" />
                    <div className="item btnSelected">
                        <Link to="/equipment/create">
                            <Button label={(msg('equipment.button.create'))} title={(msg('equipment.button.create'))} className="button" key="create" disabled={!cAuthorized} />
                        </Link>
                    </div>
                    <div className={this.state.buttonSelectd ? "btnSelected item" : "btnNotSelected item "} style={{ marginLeft: '-5px' }}>
                        <Button label={(msg('equipment.button.update'))} title={(msg('equipment.button.update'))} 
                        onClick={this.update} key="update" disabled={!uAuthorized && !rAuthorized} />
                    </div>
                    <div className={this.state.buttonSelectd ? "btnSelected item " : "btnNotSelected item"} >
                        <Button label={(msg('equipment.button.delete'))} title={(msg('equipment.button.delete'))} onClick={() => { this.deleteEquipment() }} key="delete" disabled={!dAuthorized} />
                    </div>
                    <div className={this.state.buttonSelectd ? "btnSelected item " : "btnNotSelected item"} >
                        <Button label={(msg('equipment.button.config.download.tooltip'))} title={(msg('equipment.button.config.download.tooltip'))} onClick={() => this.downloadTargetConfiguration()}
                                    key="config_download" disabled={!rAuthorized}/>
                    </div>
                    <div className={this.state.buttonSelectd ? "btnSelected item " : "btnNotSelected item"}>
                        <Button label={(msg('equipment.button.borne.update.tooltip'))} title={(msg('equipment.button.borne.update.tooltip'))} onClick={() => this.updateBorne()}
                                    key="config_download" disabled={!this.state.equipmentUpdated || !uAuthorized} />
                    </div>
                </div>
                <div className="p-col-10">
                    <div className="">
                       {tabelEquipments}
                    </div>
                </div>

                <div className="p-col-2">

                    <BorneFilter apply={this.applyBorneFilter}
                        reset={this.applyBorneFilter}
                        loadFilter={(filter) => this.loadFilter(filter)}
                    	loadFilterStations={filter => this.loadFilterStations(filter)}
                        filterData={this.props.filterData}
                        filterStations={this.props.filterStations}
                        showChannel={true} showStation={true} showBorneType={true} showBorneSerial={true}
                        showTransactionPeriod={true}
                        showAlarmType={true} defaultAlarmType={this.state.borneFilter && this.state.borneFilter.alarmType ? this.state.borneFilter.alarmType : null}
                        title={msg('equipments.filter.title')}
                        setDataInfoBar={this.props.setDataInfoBar} applyLabel={msg('equipments.filter.apply')}
                        initFilter={this.state.borneFilter} 
                        filterId={ADMIN_EQUIPMENTS_TABLE_ID}/>


                </div>
				{/*TODO reduce the number of message dialog, it should be one dialog per by message level type */}
                <MessageDialog className={'message-dialog error-message'} visible={this.state.sessionExpired} title={msg('firmware.fields.upload.title.error')}
                    message={msg("login.unauthorized.internalauthenticationserviceexception")}
                    onHide={e => this.handleErrorExpiredSessionChange(e)} onCancel={e => this.handleErrorExpiredSessionChange(e)} />

                <MessageDialog className={'message-dialog error-message'} visible={this.state.isError && this.state.hardware} title={msg('firmware.fields.upload.title.error')}
                    message={this.state.message}
                    onHide={e => this.handleErrorChange(e)} onCancel={e => this.handleErrorChange(e)} />

                <MessageDialog className={'message-dialog warn-message'} visible={this.state.isApiError} title={msg('message.dialog.warn.title')}
                    message={this.state.isApiError && msg(this.props.error.message)}
                    onHide={() => this.setState({ isLignSelected: false, isApiError: false })} />

                <MessageDialog className={'message-dialog warn-message'} visible={this.state.isNotDownload} title={msg('message.dialog.warn.title')}
                    message={msg('error.equipment.target.configuration.cannot.be.build')}
                    onHide={() => this.setState({ isNotDownload: false })} />

                <MessageDialog className={'message-dialog warn-message'} visible={this.state.hiddenConfirmDelete} title={msg('message.dialog.warn.title')}
                    message={msg('error.equipment.delete.equipment.content.transaction', { name: this.state.equipmentName })}
                    onHide={() => this.setState({ isLignSelected: false, hiddenConfirmDelete: false })} />

                <MessageDialog confirm={true} className={'confirm-message'} visible={this.state.showConfirmDeleteBorneCampagne} title={msg('message.dialog.confirm.title')}
                    message={msg('equipements.delete.confirm.message.campagne')}
                    messagesArray={[msg('equipements.delete.confirm.message', { name: this.state.equipmentName })]}
                    key="confirm-delete-campagne"
                    onConfirm={() => { this.setState({ showConfirmDeleteBorneCampagne: false, isAssociatedBorne: false }); this.confirmDeleteEquipment() }}
                    onCancel={() => this.setState({ showConfirmDeleteBorneCampagne: false, isAssociatedBorne: false })} />

                <MessageDialog confirm={true} className={'confirm-message'} visible={this.state.showConfirmDeleteBorneAlarme} title={msg('message.dialog.confirm.title')}
                    message={msg('equipements.delete.confirm.message.alarm')}
                    messagesArray={[msg('equipements.delete.confirm.message', { name: this.state.equipmentName })]}
                    key="confirm-delete-alarm"
                    onConfirm={() => { this.setState({ showConfirmDeleteBorneAlarme: false, isAssociatedBorne: false }); this.confirmDeleteEquipment() }}
                    onCancel={() => this.setState({ showConfirmDeleteBorneAlarme: false, isAssociatedBorne: false })} />


                <MessageDialog confirm={true} className={'confirm-message'} visible={this.state.showConfirmDeleteBorne} title={msg('message.dialog.confirm.title')}
                    message={msg('equipements.delete.confirm.message.borneName', { borneName: this.state.borneName, serialNumber: this.state.serialNumber })}
                    messagesArray={[msg('equipements.delete.confirm.message.complete'), msg('equipements.delete.confirm.message', { name: this.state.equipmentName })]}
                    key="confirm-delete"
                    onConfirm={() => { this.setState({ showConfirmDeleteBorne: false, isAssociatedBorne: false }); this.confirmDeleteEquipment() }}
                    onCancel={() => this.setState({ showConfirmDeleteBorne: false, isAssociatedBorne: false })} />

                <MessageDialog confirm={true} className={'confirm-message'} visible={this.state.showConfirmDelete} title={msg('message.dialog.confirm.title')}
                    message={msg('equipements.delete.confirm.message', { name: this.state.equipmentName })}
                    onConfirm={() => { this.setState({ showConfirmDelete: false, isAssociatedBorne: false }); this.confirmDeleteEquipment() }}
                    onCancel={() => this.setState({ showConfirmDelete: false, isAssociatedBorne: false })} />

                <MessageDialog className={'message-dialog info-message'} visible={this.state.isUpdated} title={msg('equipment.borne.info')}
                    message={msg('equipment.borne.updated', { borneName: this.state.borneName, serialNumber: this.state.serialNumber })}
                    onHide={() => this.setState({ isUpdated: false })} />
            </div >
        )
    }
}

export default AdminEquipments
