// color reference :  https://www.w3schools.com/colors/colors_hex.asp

const UI_COLOR_ELEMENTS_BY_LABEL= [
    {key: 'CASH',value: '#a3a3c2'},
    {key: 'CB', value: '#164194'},
    {key: 'FREE_CODE', value: '#00a655'},
    {key: 'GR', value: '#F49600'},
    {key: 'HAPPY_HOUR', value:'#668cff'},
    {key: 'PAID_CODE', value: '#4A96D2'},
    {key: 'PJEGR', value: '#666666'},
    {key: 'TW', value: '#E4032E'} ,
    {key: 'TW/PJ/CO', value: '#E4032E'} ,
    {key: 'TW/PJE', value: '#E4032E'} , 
    {key: 'LICENSE_PLATE', value: '#00A655'},    
    {key: 'MP',value:'#164194'},
    {key: 'HP', value:'#E4032E'},
    {key: 'Acc',value:'#F49600'},
    {key: 'Kiosque',value:'#4A96D2'},
    {key: 'MAINTENANCE',value:'#1B5552'}
    
] ;

const UI_COLOR_CAMPAIGN_STATUS = [
    {key: 'PENDING', value: '#f49600'},
    {key: 'IN_PROGRESS', value: '#4a96d2'},
    {key: 'ACQUITTED', value: '#164194'},
    {key: 'FAILED', value: '#e4032e'},
    {key: 'SUCCEED', value: '#00a655'},
];

const UI_DEAFAULT_COLORS_LIST= ['#164194','#E4032E','#F49600' ,'#00a655' ,'#666666', '#FFF43B', '#e5e5e5'];

class ColorUtlis {
	
    uiColorLbel= UI_COLOR_ELEMENTS_BY_LABEL;
    defaultColorsList = UI_DEAFAULT_COLORS_LIST;
    
    colorItemByLabel( key ) {
        if(!Array.isArray(this.uiColorLbel)){
            return null;
        }
        let i = 0;
        for(i = 0; i < this.uiColorLbel.length; i++){
            if(this.uiColorLbel[i].key === key.trim()){    
                return this.uiColorLbel[i].value;
            }
        }
        return null;
    }

     defaultColorsList () {
         return this.defaultColorsList;
     }
      
     colorByStatus(key) {
        let i = 0;
        for(i = 0; i < UI_COLOR_CAMPAIGN_STATUS.length; i++) {
            if(key === UI_COLOR_CAMPAIGN_STATUS[i].key) {
                return UI_COLOR_CAMPAIGN_STATUS[i].value;
            }
        }
     }
 
};

export const  colorUtlis  =  new ColorUtlis();