
/*
    classe pour afficher tableau data
    
    data  :  les données à afficher 
    titleTable  :  titre du tableau
    rows : nombre de ligne à afficher dans une page du tableau 
    getDataFunction :  Fonction d'appel le  web service pour recevoire les donnés 
    paramnFunctionData : paramatre getDataFunction   
                           null : 0 parmetre
                           !null : un seul paramètre pour le moment     
    totalRecords=  (totalPages * rows );   
                   totalPages : nobmre de page  (a lire a pertire de json recue  )
    onSelection = fonction a appelé apres la selection d'une ligne dans tableau   
    numberElemnt={this.props.equipments.number} recupéré dans json 
   

    columns= les coloone a affiché  on a  : 
                isColWithBody : 
                     true : il ya une  fonction dans body comme affcicher une liste dans la ligne  :
                            <Column key={col.field} field={col.field} body={col.body} sortable={col.sortable} header={col.header} className={col.className}
                     false :      
                            <Column key={col.field} field={col.field} body={col.body} sortable={col.sortable} header={col.header} className={col.className}

                
    searchInTable={false}

  outilBar :  bar des outils c est un ensembles des objects de type
         let outilBar =[
            {filename : "transaction.xls" ,iconSrc:"/assets/ultima/layout/images/cartadis/excel.svg", titleIcone : "Transactions" ,tooltipTextIcone:"Export des transactions" , urlExport: urlExportXlsTransaction},
            {filename : "alarmes.xls" ,iconSrc:"/assets/ultima/layout/images/cartadis/excel.svg", titleIcone : "Alarmes" ,tooltipTextIcone:"Export des Alarmes" , urlExport: urlExportXlsTransaction}
        ]
        et chaque object représente un button dans la bar des outls dans le tableau 
*/
import { ContextMenu } from 'primereact/contextmenu';
import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/components/datatable/DataTable';
import UploadService from "../middleware/download-upload-files.service"
import { userSessionParamsHandler } from './UserSessionParamsHandler';
import msg from '../texts/msg';
import '../../src/CartadisMain.css';

const SEARCH_LENGTH = 3;
export class TableData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            first: 0,
            page: 0,
            idSearch: null,
            idValid: false,
            x: 0,
            y: 0,
        };
        this.timer = null;
        this.contextMenuRef = React.createRef();
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.reload = this.reload.bind(this);
        this.apply = this.apply.bind(this);
        this.toActivityJornal = this.toActivityJornal.bind(this);
        this.toServiceInformation = this.toServiceInformation.bind(this);
        this.onContextMenuSelection = this.onContextMenuSelection.bind(this);
        this.onContextMenu = this.onContextMenu.bind(this);
    }

    onContextMenu(e) {
        //Do not delete, it's required to display context menu
    }
    onContextMenuSelection(event) {
        if( this.contextMenuRef.current) {
           this.contextMenuRef.current.show(event.originalEvent);
           if (this.props.onSelection) { 
               this.props.onSelection(event)
                if (this.props.childDialogPosition) {
                    const yOffset = document.getElementsByClassName('p-datatable-thead')[1].clientHeight + document.getElementsByClassName('p-dialog-titlebar')[0].clientHeight;
                    this.setState({
                        x: event.originalEvent.clientX,
                        y: event.originalEvent.clientY - yOffset
                    })
                }
            }
        }
    }

    searchData = (e) => { }

    onModelsearchData = (e) => { }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.data !== this.props.data) {
            this.setState({ loading: false });
        }
    }

    onPage(event) {
        this.setState({ loading:( (this.props.loading === undefined) ? true :  this.props.loading) });
        const first = userSessionParamsHandler.getPageSize(this.props.tableId) !== event.rows ? 0 : event.first;
        const page = userSessionParamsHandler.getPageSize(this.props.tableId) !== event.rows ? 0 : event.page;
        const pageSize = event.rows;
        if(userSessionParamsHandler.getPageSize(this.props.tableId) !== pageSize){
            userSessionParamsHandler.firePageSizeChange(pageSize, this.props.tableId);
        }
        this.props.paramnFunctionData !== null ?
            this.props.filter !== null ? this.props.getDataFunction(this.props.paramnFunctionData, page, pageSize, this.props.filter) :
                this.props.getDataFunction(this.props.paramnFunctionData, page, pageSize) :
            this.props.filter !== null ? this.props.getDataFunction(page, pageSize, this.props.filter) : this.props.getDataFunction(page, pageSize);
        if (this.state.data !== this.props.data) {
            this.setState({
                first: first,
                page: page,
                data: this.props.data
            });
            userSessionParamsHandler.firePageSizeChange(pageSize, this.props.tableId);
        }
    }

    onSort(event) {
        this.props.onSort(event, this.state.page);
        if(this.props.filter){
            userSessionParamsHandler.fireFilterChange(this.props.filter, this.props.tableId);
        }
    }

    onRowDoubleClick(event) {
        if (event.value != null) {
        }
    }

    reload() {
        this.setState({ loading: true });
        const pageSize = userSessionParamsHandler.getPageSize(this.props.tableId);
        const page = this.state.page;
        this.props.paramnFunctionData !== null ?
            this.props.filter !== null ? this.props.getDataFunction(this.props.paramnFunctionData, page, pageSize, this.props.filter) :
                this.props.getDataFunction(this.props.paramnFunctionData, page, pageSize) :
            this.props.filter !== null ? this.props.getDataFunction(page, pageSize, this.props.filter) : this.props.getDataFunction(page, pageSize);
    }

    /* TODO Change this: not use custom method in common component */
    /* This method is used only by equipment component */
    toActivityJornal() {
        const page = 0;
        const pageSize = userSessionParamsHandler.getPageSize(this.props.tableId);        
        this.props.getDataFunctionActivity(this.props.paramnFunctionDataActivity, page, pageSize, this.props.defaultActiviteFilter);
        this.props.location();
    }

     /* TODO Change this: not use custom method in common component */
    /* This method is used only by equipment component */
    toServiceInformation() {
        this.props.showServiceInformation();
    }

    apply() {
        let quickSearchValue = this.getQuickSearchValue();
        if((quickSearchValue && (quickSearchValue.length >= SEARCH_LENGTH))){
            clearTimeout(this.timer)            
            this.timer = setTimeout(()=>{this.doInApply()}, 1000);             
        } else if(!quickSearchValue){
            this.doInApply();
        }
    }

    doInApply() {
        this.props.apply(this.getQuickSearchFilter());
        if(this.props.filter){
            userSessionParamsHandler.fireFilterChange(this.props.filter, this.props.tableId);
        }
    }

    getQuickSearchFilter () {
        return {
            idSearch: this.getQuickSearchValue(),
            idValid: true
        }
    };

    getQuickSearchValue() {
        let label = this.props.filter && this.props.onQuickSearchChange ? this.props.filter.label :  this.state.idSearch;
        return label ? label : '';
    }

    onQuickSearchChange(e){
        if(this.props.filter && this.props.onQuickSearchChange){
            this.props.onQuickSearchChange(e);
        } else {
            this.setState({ idSearch: e.target.value });
        }        
    }

    render() {
        const dataTableHeader =
            <div className="p-grid p-align-center vertical-container" style={{ width: '100%', padding: '0px', height: '65px', marginTop: '-25px' }}>
                <div className={this.props.ActivityJornal ? "p-col-1 p-md-1 " : "p-col-4 p-md-4 "}>
                    {this.props.searchByName && <div className="p-datatable-globalfilter-container p-col-12"  style={{ float: 'left', display: 'flex', marginTop: '14px' }}>
                        <InputText type="text" size="40" value={this.getQuickSearchValue()} onChange={(e) => this.onQuickSearchChange(e)} onKeyUp={this.apply}
                            placeholder={this.props.nameTxtPlaceholder} className= "" />
                        <i className="topbar-icon material-icons pointer p-col-2" onClick={this.apply} style={{ padding: 0}}>search</i>
                    </div>}
                    {this.props.sertchById && <div className="p-datatable-globalfilter-container" style={{ float: 'left' }}>
                        <InputText size="30" value={this.getQuickSearchValue()} onChange={(e) => {this.onQuickSearchChange(e)}} onKeyUp={this.apply}
                            placeholder={this.props.idTxtPlaceholder} />
                        <i className="topbar-icon material-icons pointer" onClick={this.apply}>search</i>
                    </div>}
                </div>
                <div className={this.props.ActivityJornal ? "p-col-7 p-md-7 " : this.props.paramnFunctionDataActivity ? "p-col-6 p-md-2 filter-container" : "p-col-6 p-md-4 filter-container"} style={{ marginTop: '14px' }}>
                    <span style={{ fontweight: 'bolder' }}> {this.props.titleTable} </span>
                </div>
                <div className={this.props.paramnFunctionDataActivity ? "p-col-6 p-md-6 filter-container" : "p-col-6 p-md-4 filter-container"}>
                    {this.props.searchInTable && <div style={{ textAlign: 'right' }}>
                        <InputText value={this.props.searchValue} onKeyUp={e => this.dv.filter(e.target.value)} onChange={this.onModelsearchDataEquipment} />
                        <i className="topbar-icon material-icons" onClick={(e) => this.searchDataEquipment(e)}>search</i>
                    </div>}
                    <ul className="ulhorizontal" >
                        <li><Button className="reloadButton" icon="pi-md-refresh" onClick={this.reload} /> <br></br></li>
                        {this.props.outilsBar &&
                            this.props.outilsBar.map((outil, i) => {
                                return (<li key={i} className="tooltip">
                                    <input id="image" type="image" src={outil.iconSrc} style={{ marginBottom: '-5px', height: '25px' }} alt={outil.filename}
                                        onClick={() => UploadService.getFileData(outil.urlExport, outil.filename, this.props.filter)
                                        } />
                                    <br />
                                    {outil.tooltipTextIcone && <span className="tooltiptext">{outil.tooltipTextIcone}</span>}
                                </li>)
                            })}
                        {this.props.outilsBar &&
                            this.props.outilsBar.map((outil, i) => {
                                return (<li key={i} className="tooltip">
                                    {outil.validate === 'EQUIPMENTS' && this.props.paramnFunctionDataActivity && !this.props.turned && <Button className="reloadButton" icon="pi-md-format-list-bulleted" onClick={this.toActivityJornal} style={{ marginBottom: '-5px', height: '25px' }} />}
                                    <br />
                                    {outil.validate === 'EQUIPMENTS' && this.props.paramnFunctionDataActivity && !this.props.turned && <span className="tooltiptext">{(msg('equipement.activity.jornal'))}</span>}
                                </li>)
                            })}
                        {this.props.outilsBar &&
                            this.props.outilsBar.map((outil, i) => {
                                return (<li key={i} className="tooltip">
                                    {outil.validate === 'EQUIPMENTS' && this.props.paramnFunctionDataActivity &&
                                        <Button className="reloadButton" icon="pi-md-my-location" onClick={this.toServiceInformation} style={{ marginBottom: '-5px', height: '25px' }} />}
                                    <br />
                                    {outil.validate === 'EQUIPMENTS' && this.props.paramnFunctionDataActivity && <span className="tooltiptext">{(msg('equipement.service.information'))}</span>}
                                </li>)
                            })}
                    </ul>
                </div>
            </div >
        const dynamicColumns = (this.props.columns) && this.props.columns.map((col, i) => {
            if (col.isColWithBody) {
                return <Column key={i} selectionMode={col.selectionMode}  field={col.field} body={col.body} sortable={col.sortable} header={col.header} className={col.className} />
            }
            else {
                return <Column key={i} selectionMode={col.selectionMode} field={col.field} header={col.header} sortable={col.sortable} className={col.className} />;
            }
        });
        let contextMenu = this.props.ContextMenuContent && (
            <ContextMenu
                style={{
                    width: "max-content",
                    top: this.props.childDialogPosition ? this.state.y - this.props.childDialogPosition.y : this.state.y,
                    left: this.props.childDialogPosition ? this.state.x - this.props.childDialogPosition.x : this.state.x
                }}
                model={this.props.ContextMenuContent} ref={this.contextMenuRef} onShow={this.props.onContextMenuShow}
            />);
        let first = this.props.first ? 0 : this.state.first;
        let pageSize = userSessionParamsHandler.getPageSize(this.props.tableId);
        let totalRecords = this.props.totalPages * pageSize;
        let dataTable = (this.props.data) && (
            <div className="tableData p-grid">
                <DataTable className="table-size-screen-642-to-1366" value={this.props.data} header={dataTableHeader} dataKey={this.props.dataKey}
                    paginator={true} paginatorPosition="both"
                    totalRecords={totalRecords} rows={pageSize} first={first} rowsPerPageOptions={userSessionParamsHandler.getRowsPerPageOptions(this.props.tableId)}
                    onPage={this.onPage}
                    loading={this.state.loading}
                    selectionMode={this.props.selectionMode ? this.props.selectionMode : ""}
                    lazy={true} responsive={true} selection={this.props.selection ? this.props.selection : this.state.dataTableSelection}
                    onSelectionChange={event => { this.setState({ dataTableSelection: event.value }); if (this.props.onSelection) { this.props.onSelection(event) } }}
                    onContextMenuSelectionChange={event => { this.setState({ dataTableSelection: event.value });  this.onContextMenuSelection(event)}}
                    onContextMenu = {this.props.ContextMenuContent ?  e => this.onContextMenu(e) :  undefined}
                    onSort={this.onSort}
                    emptyMessage={this.props.emptyMessage ? this.props.emptyMessage : msg('common.datatable.empty.msg')}
                    style={{ 'backgroundColor': 'white !important', 'color': 'black' }}
                    currentPageReportTemplate={"(" + (this.state.page + 1) + " - " + this.props.totalPages + " / " + this.props.totalPages + ")"}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport">
                    {dynamicColumns}
                </DataTable>
            </div>);
        return (<div> {dataTable} {contextMenu} </div>);
    }
}

export default TableData