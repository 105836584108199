import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { TreeTable } from 'primereact/treetable';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { AutoComplete } from 'primereact/autocomplete';
import { Checkbox } from 'primereact/checkbox';
import {EMPTY_HARDWARE, EMPTY_FIRMWARE, EMPTY_PRICEZONE, EMPTY_TYPE, EMPTY_TYPE_KEY, EMPTY_BORNE_TYPE, 
	EQUIPMENT_TYPE, STATION_TYPE, STATION_TYPE_KEY, EQUIPMENT_TYPE_KEY, emtyChar, CONSULT_MODE, BORNES_SELECTION_MODE} from'./campaignRenderModes';
import CampaignReport from './CampaignReport';
import MessageDialog from '../../common/MessageDialog';
import { toLocaleDateTimeFormat } from '../../../utils/dateTimeUtils';
import { getCalendarLocale } from '../../../texts/locales/calendar-locale';
import msg from '../../../texts/msg.js';
import { TIME_PATTERN, TIME_PATTERN0, SPECIALCARACTER_PATTERN } from '../../../utils/RegexPatterns';
import UploadService from '../../../middleware/download-upload-files.service';
import '../../common/css/common.css';
import '../Administration.css';
import './campaign.css';
import { UPDATE, rolesValidator, CREATE, CAMPAIGNS, READ, CAMPAIGNFIRMWARE, CAMPAIGNHARDWARE, CAMPAIGNPRICEZONE, EQUIPMENTS } from '../../../utils/RolesValidator';
import { userSessionParamsHandler, STATIONS_EQUIPMENT_TABLE_ID } from '../../../utils/UserSessionParamsHandler';
import { progressSpinnerHandler } from '../../../utils/ProgressSpinnerHandler';
import { ContextMenu } from 'primereact/contextmenu';
import { defaultActiviteFilter } from '../../maintenance/equipment/ActivityJornal.js';

class CampaignForm extends Component {
	constructor(props) {
		super(props);
		let authorized = rolesValidator.validate(CAMPAIGNS);
		let fwAuthorized = rolesValidator.validate(CAMPAIGNFIRMWARE);
		let hwAuthorized = rolesValidator.validate(CAMPAIGNHARDWARE);
		let pzAuthorized = rolesValidator.validate(CAMPAIGNPRICEZONE);
		let eqAuthorized = rolesValidator.validate(EQUIPMENTS);
		let lCampaignType = EMPTY_TYPE;
		let lCampaignTypes = [lCampaignType];
		let lFirmwareType = false;
		let lHardwareType = false;

		let authorizations = {
			fw: {
				create: fwAuthorized.toString().includes(CREATE),
				read: fwAuthorized.toString().includes(READ) || authorized.toString().includes(READ),
				update: fwAuthorized.toString().includes(UPDATE)
			},
			hw: {
				create: hwAuthorized.toString().includes(CREATE),
				read: hwAuthorized.toString().includes(READ) || authorized.toString().includes(READ),
				update: hwAuthorized.toString().includes(UPDATE)
			},
			pz: {
				create: pzAuthorized.toString().includes(CREATE),
				read: pzAuthorized.toString().includes(READ) || authorized.toString().includes(READ),
				update: pzAuthorized.toString().includes(UPDATE)
			},
			eq: {
				read: eqAuthorized.toString().includes(READ)
			}
		}

		if (authorizations.fw.create || authorizations.hw.create) {
			if (!authorizations.pz.create) {
				lCampaignType = EQUIPMENT_TYPE;
				lCampaignTypes = [lCampaignType];
				lFirmwareType = (authorizations.fw.create || authorizations.fw.update) ? true : false;;
				lHardwareType = (authorizations.hw.create || authorizations.hw.update) ? true : false;
			} else {
				lCampaignTypes.push(EQUIPMENT_TYPE);
			}
		}
		if (authorizations.pz.create) {
			if (!authorizations.fw.create && !authorizations.hw.create) {
				lCampaignType = STATION_TYPE;
				lCampaignTypes = [lCampaignType];
			} else {
				lCampaignTypes.push(STATION_TYPE);
			}
		}

		this.state = {
			locale: getCalendarLocale(),
			campaign: {
				id: emtyChar,
				name: emtyChar,
				description: emtyChar,
				firmware: EMPTY_FIRMWARE,
				hardware: EMPTY_HARDWARE,
				priceZone: EMPTY_PRICEZONE,
				equipments: [],
				stations: [],
				type: lCampaignType,
				borneType: EMPTY_BORNE_TYPE,
				firmwareType: lFirmwareType,
				hardwareType: lHardwareType
			},
			stationEquipments: [],
			expandedStationKeys: {},
			selectedEquipmentKeys: {},
			campaignTypes: lCampaignTypes,
			borneTypes: [EMPTY_BORNE_TYPE, { key: 'TL18', label: 'TL18' }, { key: 'TL17', label: 'TL17' }, { key: 'TL16', label: 'TL16' }, { key: 'TK2', label: 'TK2' }],
			firmwares: [],
			currentFirmwares: [],
			filteredFirmwares: [],
			filteredCurrentFirmwares: [],
			hardwares: [],
			currentHardwares: [],
			filteredHardwares: [],
			filteredCurrentHardwares: [],
			priceZones: [],
			currentPriceZones: [],
			filteredPriceZones: [],
			filteredCurrentPriceZones: [],
			query: '',
			first: 0,
			page: 0,
			filters: { 'name': { value: '' } },
			filter: {
				station: '',
				firmware: EMPTY_FIRMWARE,
				hardware: EMPTY_HARDWARE,
				priceZone: EMPTY_PRICEZONE,
			},
			allBornesSelected: false,
			mostBornesSelected: false,
			authorizations: authorizations,
			consultationPage: null,
			selectedRow: null,
			equipmentsCount: 0,
			stationsCount: 0,
			unSelectedEquipments: [],
			unSelectedStations: []
		};
		this.validate = this.validate.bind(this);
		this.loadStations = this.loadStations.bind(this);
		this.handleTypeChange = this.handleTypeChange.bind(this);
		this.handleFirmwareChange = this.handleFirmwareChange.bind(this);
		this.handleCurrentFirmwareChange = this.handleCurrentFirmwareChange.bind(this);
		this.handleHardwareChange = this.handleHardwareChange.bind(this);
		this.handleCurrentHardwareChange = this.handleCurrentHardwareChange.bind(this);
		this.handlePriceZoneChange = this.handlePriceZoneChange.bind(this);
		this.handleCurrentPriceZoneChange = this.handleCurrentPriceZoneChange.bind(this);
		this.handleEquipmentsChange = this.handleEquipmentsChange.bind(this);
		this.handleEquipmentsToggle = this.handleEquipmentsToggle.bind(this);
		this.handleStartDateChange = this.handleStartDateChange.bind(this);
		this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
		this.handleEndDateChange = this.handleEndDateChange.bind(this);
		this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
		this.confirmUnselectEquipments = this.confirmUnselectEquipments.bind(this);
		this.rowClassName = this.rowClassName.bind(this);
		this.checkApiErrors = this.checkApiErrors.bind(this);
		this.checkReloadOnChange = this.checkReloadOnChange.bind(this);
		this.initStationEquipments = this.initStationEquipments.bind(this);
		this.initCampaign = this.initCampaign.bind(this);
		this.sortArray = this.sortArray.bind(this);
		this.onPage = this.onPage.bind(this);
		this.onFilter = this.onFilter.bind(this);
		this.onSelectAllStationEquipments = this.onSelectAllStationEquipments.bind(this);
		this.contextMenuRef = React.createRef();
		this.onContextMenuSelection = this.onContextMenuSelection.bind(this);
		this.equipmentSelected = this.equipmentSelected.bind(this);
		this.onRowClick = this.onRowClick.bind(this);
		this.onDetailsClick = this.onDetailsClick.bind(this);
		this.selectionMode = this.selectionMode.bind(this);
	}

	componentDidMount() {
		if (this.props.campaignId) {
			this.props.loadCampaign(this.props.campaignId, this.props.planning);
		}
		if (!this.props.campaignId && this.state.campaign.type === STATION_TYPE) {
			this.props.loadPriceZones();
			this.loadStations(this.state.campaign.id, '', true);
		}
	}

	checkApiErrors(prevProps) {
		let prevError = prevProps.error && prevProps.error.timestamp;
		let lastError = this.props.error && this.props.error.timestamp;
		let error = (lastError && (!prevError || prevError < lastError));
		if (error) {
			let isError = this.props.error.message && this.props.error.message.startsWith('error.campaigns.') ? true : false;
			this.setState({ isError: isError, errorMessage: this.props.error.message });
			return true;
		}
		return false;
	}

	checkReloadOnChange(prevProps) {
		let reload = (this.props.lastChange && (!prevProps.lastChange || prevProps.lastChange < this.props.lastChange));
		if (reload) {
			this.props.renderList();
			return true;
		}
		return false;
	}

	initStationEquipments(prevProps) {
		if (prevProps.stationsData !== this.props.stationsData) {
			progressSpinnerHandler.show(false);
			let prevLoad = prevProps.stationsData && prevProps.stationsData.lastChange;
			let lastLoad = this.props.stationsData && this.props.stationsData.lastChange;
			let loaded = (lastLoad && (!prevLoad || prevLoad < lastLoad));
			if (loaded) {
				let totalPages = this.props.stationsData ? this.props.stationsData.totalPages : 0;
				let stationEquipments = [];
				let expandedStationKeys = {};
				if (this.props.stationsData && this.props.stationsData.stationEquipments) {
					this.props.stationsData.stationEquipments.forEach(s => {
						stationEquipments.push(s);
						expandedStationKeys[s.key] = true;
					});
				}
				if (stationEquipments.length > 0) {
					stationEquipments.sort((a, b) => this.sortArray(a, b));
					stationEquipments.forEach(s => s.children.sort((a, b) => this.sortArray(a, b)));
				}
                let stationEquipmentsKeys = this.props.stationsData.stationEquipments.map(element => [element.key, element.children.map(e => e.key)]);
                let selectedEquipmentKeys = Object.assign({}, this.state.selectedEquipmentKeys);
				for (let [stationName, equipmentNames] of stationEquipmentsKeys) {
					if (!this.state.unSelectedStations.includes(stationName) && 
						(this.state.allBornesSelected || this.state.mostBornesSelected || equipmentNames.every(e => Object.keys(this.state.selectedEquipmentKeys).includes(e)))) {
                        selectedEquipmentKeys[stationName] = { checked: true, partialChecked: false };                        
                    }
					for (let equipemntName of equipmentNames) {
						if(!this.state.unSelectedEquipments.includes(equipemntName) && 
							(this.state.allBornesSelected || this.state.mostBornesSelected || Object.keys(this.state.selectedEquipmentKeys).includes(equipemntName))) {
							selectedEquipmentKeys[equipemntName] = { checked: true, partialChecked: false };
						}
					}
				}
				let selectedEquipmentKeysTab = Object.keys(selectedEquipmentKeys);
				let nbSelectedEquipments = selectedEquipmentKeysTab.filter(e => !e.startsWith("ST_")).length;
				let nbSelectedStations = selectedEquipmentKeysTab.filter(e => e.startsWith("ST_")).length;
				let nbStations = this.props.stationsData ? this.props.stationsData.nbStations : 0;
				let nbEquipments = this.props.stationsData ? this.props.stationsData.nbEquipments : 0;
				let nbCampaignEquipments = this.props.stationsData ? this.props.stationsData.nbCampaignEquipments : 0;
				let allBornesSelected = ((!this.state.loaded && (nbEquipments === nbCampaignEquipments))
					|| (nbSelectedEquipments === nbEquipments) || (nbSelectedStations === nbStations)
					|| this.state.allBornesSelected);
				let equipments = [];
				let stations = [];				
				for (let e in selectedEquipmentKeys) {
					if ((this.equipmentSelected()) && !e.startsWith('ST')) {
						equipments.push(e);
					} else if (this.stationSelected() && e.startsWith('ST')) {
						stations.push(e)
					}
				}
				let mostBornesSelected = allBornesSelected ? false : this.state.mostBornesSelected;
				this.setState({
                    selectedEquipmentKeys: selectedEquipmentKeys,
					stationEquipments: stationEquipments,
					expandedStationKeys: expandedStationKeys,
					campaign: Object.assign({}, this.state.campaign, { equipments: equipments, stations: stations }),
					totalPages: totalPages,
					allBornesSelected: allBornesSelected,
					mostBornesSelected: mostBornesSelected,
					equipmentsCount: allBornesSelected ? nbEquipments : (mostBornesSelected ? nbEquipments - this.state.unSelectedEquipments.length : nbSelectedEquipments),
					stationsCount: allBornesSelected ? nbStations : (mostBornesSelected ? nbStations - this.state.unSelectedStations.length : nbSelectedStations),
					loaded : true
				});
			}
		}
	}

	initCampaign(prevProps) {
		if (!prevProps.campaign && this.props.campaign) {
			let lEquipments = {};
			this.props.campaign.equipments.forEach(e => lEquipments[e] = { checked: true, partialChecked: false });
			this.props.campaign.stations.forEach(s => lEquipments[s] = { checked: true, partialChecked: false });
			let lFirmware = EMPTY_FIRMWARE;
			let lHardware = EMPTY_HARDWARE;
			let lPriceZone = EMPTY_PRICEZONE;
			let type = EMPTY_TYPE;
			let borneType = EMPTY_BORNE_TYPE;
			if (this.props.campaign.firmware || this.props.campaign.hardware) {
				lFirmware = this.props.campaign.firmware ? this.props.campaign.firmware : EMPTY_FIRMWARE;
				lHardware = this.props.campaign.hardware ? this.props.campaign.hardware : EMPTY_HARDWARE;
				type = EQUIPMENT_TYPE;
				borneType = this.props.campaign.borneType ? { key: this.props.campaign.borneType, label: this.props.campaign.borneType } : EMPTY_BORNE_TYPE;
			} else if (this.props.campaign.priceZone) {
				lPriceZone = this.props.campaign.priceZone ? this.props.campaign.priceZone : EMPTY_PRICEZONE;
				type = STATION_TYPE;
			}
			let campaignId = this.props.mode === CONSULT_MODE ? this.props.campaign.id : '';
			let readOnly = this.props.mode === CONSULT_MODE ?
				(this.props.campaign.readOnly || (campaignId && this.props.planning)) : false;
			let lCampaign = {
				id: campaignId,
				name: this.props.campaign.name,
				description: this.props.campaign.description,
				type: type,
				borneType: borneType,
				firmwareType: (lFirmware && lFirmware.key && lFirmware.key.trim()) ? true : false,
				hardwareType: (lHardware && lHardware.key && lHardware.key.trim()) ? true : false,
				firmware: lFirmware,
				hardware: lHardware,
				priceZone: lPriceZone,
				stations: this.props.campaign.stations,
				equipments: this.props.campaign.equipments,
				readOnly: readOnly,
				restartOnFailed: this.props.campaign.restartOnFailed,
				scheduledStartDate: this.props.campaign.scheduledStartDate ? new Date(this.props.campaign.scheduledStartDate) : new Date(),
				scheduledEndDate: this.props.campaign.scheduledEndDate ? new Date(this.props.campaign.scheduledEndDate) : new Date(),
				status: this.props.campaign.status,
				events: this.props.campaign.events,
				progressions: this.props.campaign.progressions
			};
			this.setState({
				campaign: lCampaign,
				scheduledStartTime: lCampaign.scheduledStartDate,
				scheduledEndTime: lCampaign.scheduledEndDate,
				selectedEquipmentKeys: lEquipments,
			});
			if (!this.state.campaignTypes.includes(type)) {
				let lCampaignTypes = Object.assign([], this.state.campaignTypes);
				lCampaignTypes.push(type);
				this.setState({ campaignTypes: lCampaignTypes });
			}
			if (readOnly) {
				this.props.loadCampaignEvents(campaignId);
				this.setState({ consultationPage: this.props.mode === CONSULT_MODE && !this.props.planning })
			}
			return lCampaign;
		}
		return null;
	}

	componentDidUpdate(prevProps) {
		if (this.checkApiErrors(prevProps)) {
			return;
		}
		if (this.checkReloadOnChange(prevProps)) {
			return;
		}
		let lCampaign = this.initCampaign(prevProps);
		this.initStationEquipments(prevProps);		
		let campaignId = lCampaign ? lCampaign.id : '';
		let lType = lCampaign ? lCampaign.type : null;
		let borneType = lCampaign && lCampaign.borneType ? lCampaign.borneType.key : emtyChar;
		let stationLevel = false;
		if (lType) {
			if (lType.key === STATION_TYPE_KEY) {
				stationLevel = true;
				this.props.loadPriceZones();
			} else if (lType.key === EQUIPMENT_TYPE_KEY) {
				this.props.loadFirmwares(borneType);
				this.props.loadHardwares(borneType);
			}
			this.loadStations(campaignId, borneType, stationLevel);
		}
		if (prevProps.firmwares !== this.props.firmwares) {
			let firmwares = [];
			this.props.firmwares.forEach(f => firmwares.push(f));
			firmwares.sort((f1, f2) => f2.key.localeCompare(f1.key));
			this.setState({ firmwares: firmwares, filtredFirmwares: firmwares, currentFirmwares: firmwares, filtredCurrentFirmwares: firmwares });
		}
		if (prevProps.hardwares !== this.props.hardwares) {
			let hardwares = [];
			this.props.hardwares.forEach(h => hardwares.push(h));
			hardwares.sort((h1, h2) => h2.key.localeCompare(h1.key));
			this.setState({ hardwares: hardwares, filtredHardwares: hardwares, curentHardwares: hardwares, filtredCurrentHardwares: hardwares });
		}
		if (prevProps.priceZones !== this.props.priceZones) {
			let priceZones = [];
			this.props.priceZones.forEach(p => priceZones.push(p));
			priceZones.sort((p1, p2) => p2.key.localeCompare(p1.key));
			this.setState({ priceZones: priceZones, filtredPriceZones: priceZones, currentPriceZones: priceZones, filtredCurrentPriceZones: priceZones });
		}
	}

	loadStations(campaignId, borneType, stationLevel, filter, page, pageSize) {
		progressSpinnerHandler.show(true);
		let id = campaignId ? campaignId : this.state.campaign.id;
		let level = stationLevel ? true : false;
		let type = level ? '' : borneType;
		let planning = id && this.props.planning ? true : false;
		let lPage = page ? page : 0;
		let lPageSize = pageSize ? pageSize : userSessionParamsHandler.getPageSize(STATIONS_EQUIPMENT_TABLE_ID);
		let lFilter = filter ? filter : this.state.filter;
		this.props.loadStations(id, type, level, planning, lFilter, lPage, lPageSize);
	}

	sortArray(a, b) {
		let aa = a.data.name.replace(SPECIALCARACTER_PATTERN, '')
		let bb = b.data.name.replace(SPECIALCARACTER_PATTERN, '')
		if (aa < bb) {
			return -1;
		}
		if (aa > bb) {
			return 1;
		}
		return 0;
	}

	onPage(event) {
		this.setState({ loading: true });
		const first = userSessionParamsHandler.getPageSize(STATIONS_EQUIPMENT_TABLE_ID) !== event.rows ? 0 : event.first;
		const page = userSessionParamsHandler.getPageSize(STATIONS_EQUIPMENT_TABLE_ID) !== event.rows ? 0 : event.page;
		const pageSize = event.rows;
		let lType = this.state.campaign ? this.state.campaign.type : null;
		let stationLevel = lType.key === STATION_TYPE_KEY ? true : false;
		this.loadStations(this.state.campaign.id, this.state.campaign.borneType.key, stationLevel, this.state.filter, page, pageSize);
		this.setState({ first: first, page: page });
		if (userSessionParamsHandler.getPageSize(STATIONS_EQUIPMENT_TABLE_ID) !== pageSize) {
			userSessionParamsHandler.firePageSizeChange(pageSize, STATIONS_EQUIPMENT_TABLE_ID);
		}
	}

	onFilter(event) {
		let filter = Object.assign({}, this.state.filter, { station: event.filters && event.filters.name ? event.filters.name.value : emtyChar })
		let lType = this.state.campaign ? this.state.campaign.type : null;
		let stationLevel = lType.key === STATION_TYPE_KEY ? true : false;
		let page = 0;
		let pageSize = userSessionParamsHandler.getPageSize(STATIONS_EQUIPMENT_TABLE_ID);
		this.loadStations(this.state.campaign.id, this.state.campaign.borneType.key, stationLevel, filter, page, pageSize);
		this.setState({ filters: event.filters, filter: filter, page: 0 });
	}

	handleTypeChange(e) {
		let type = e.value;
		let lCampaign = Object.assign({}, this.state.campaign, { type: type });
		if (type) {
			if (type.key === EQUIPMENT_TYPE_KEY) {
				lCampaign = Object.assign({}, lCampaign, {
					firmwareType: true,
					hardwareType: true,
					equipments: [],
					stations: [],
					firmware: EMPTY_FIRMWARE,
					hardware: EMPTY_HARDWARE,
					borneType: EMPTY_BORNE_TYPE
				});
			} else if (type.key === STATION_TYPE_KEY) {
				this.props.loadPriceZones();
				this.loadStations(this.state.campaign.id, '', true);
				lCampaign = Object.assign({}, lCampaign, {
					firmwareType: false,
					hardwareType: false,
					equipments: [],
					stations: [],
					priceZone: EMPTY_PRICEZONE,
					firmware: EMPTY_FIRMWARE,
					hardware: EMPTY_HARDWARE,
				});
			}
		}
		this.setState({
			campaign: lCampaign,
			typeInputError: false,
			selectedEquipmentKeys: {},
			allBornesSelected: false,
			stationsCount: 0,
			equipmentsCount: 0,
		});
	}

	handleBorneTypeChange(e) {
		let type = e.value;
		if (type === this.state.borneType) {
			return;
		}
		if (!type.key && this.state.campaign.equipments.length > 0) {
			this.setState({
				campaign: Object.assign({}, this.state.campaign, { borneType: type, equipments: [] }),
				typeBorneInputError: false,
				selectedEquipmentKeys: {}
			});
			return;
		}
		this.setState({
			campaign: Object.assign({}, this.state.campaign, {
				borneType: type,
				equipments: this.state.campaign.equipments.length > 0 ? this.state.campaign.equipments.filter(eq => eq.key && eq.key.startsWith(type)) : this.state.campaign.equipments,
				firmware: EMPTY_FIRMWARE,
				hardware: EMPTY_HARDWARE
			}),
			typeBorneInputError: false,
			selectedEquipmentKeys: Object.keys(this.state.selectedEquipmentKeys).filter(e => e.startsWith(type)),

		});
		if (type && type.key) {
			this.props.loadFirmwares(type.key);
			this.props.loadHardwares(type.key);
			this.loadStations(this.state.campaign.id, type.key);
		}
	}

	handleFirmwareChange(e) {
		let fw = (e.target.value.length === 0) ? EMPTY_FIRMWARE : e.target.value;
		if (!this.checkSelectedTypes(fw)) {
			return;
		}
		if (fw !== EMPTY_FIRMWARE && fw === this.state.filter.firmware) {
			this.setState({ isError: true, errorMessage: msg('campaign.form.input.firmware.same.as.current') });
			return;
		}
		this.setState({ filteredFirmwares: null, campaign: Object.assign({}, this.state.campaign, { firmware: fw }), firmwareInputError: false });
	}

	handleCurrentFirmwareChange(e, selected) {
		let value = e.target ? e.target.value : e.value;
		let fw = (value.length === 0) ? EMPTY_FIRMWARE : value;
		if (fw !== EMPTY_FIRMWARE && fw === this.state.campaign.firmware) {
			this.setState({ isError: true, errorMessage: msg('campaign.form.input.firmware.same.as.current') });
			return;
		}
		this.setState({ filteredCurrentFirmwares: null, filter: Object.assign({}, this.state.filter, { firmware: fw }), currentFirmwareInputError: false });
		if (selected || !fw.key) {
			let filter = Object.assign({}, this.state.filter, { firmware: fw })
			let lType = this.state.campaign ? this.state.campaign.type : null;
			let stationLevel = lType.key === STATION_TYPE_KEY ? true : false;
			let page = 0;
			let pageSize = userSessionParamsHandler.getPageSize(STATIONS_EQUIPMENT_TABLE_ID);
			this.loadStations(this.state.campaign.id, this.state.campaign.borneType.key, stationLevel, filter, page, pageSize);
		}
	}

	handleHardwareChange(e) {
		let value = e.target ? e.target.value : e.value;
		let hw = (value.length === 0) ? EMPTY_HARDWARE : value;
		if (!this.checkSelectedTypes(hw)) {
			return;
		}
		if (hw !== EMPTY_HARDWARE && hw === this.state.filter.hardware) {
			this.setState({ isError: true, errorMessage: msg('campaign.form.input.hardware.same.as.current') });
			return;
		}
		this.setState({ filteredHardwares: null, campaign: Object.assign({}, this.state.campaign, { hardware: hw }), hardwareInputError: false });
	}

	handleCurrentHardwareChange(e, selected) {
		let value = e.target ? e.target.value : e.value;
		let hw = (value.length === 0) ? EMPTY_HARDWARE : value;
		if (hw !== EMPTY_HARDWARE && hw === this.state.campaign.hardware) {
			this.setState({ isError: true, errorMessage: msg('campaign.form.input.hardware.same.as.current') });
			return;
		}
		this.setState({ filteredCurrentHardwares: null, filter: Object.assign({}, this.state.filter, { hardware: hw }), curentHardwareInputError: false });
		if (selected || !hw.key) {
			let filter = Object.assign({}, this.state.filter, { hardware: hw });
			let lType = this.state.campaign ? this.state.campaign.type : null;
			let stationLevel = lType.key === STATION_TYPE_KEY ? true : false;
			let page = 0;
			let pageSize = userSessionParamsHandler.getPageSize(STATIONS_EQUIPMENT_TABLE_ID);
			this.loadStations(this.state.campaign.id, this.state.campaign.borneType.key, stationLevel, filter, page, pageSize);
		}
	}

	handlePriceZoneChange(e) {
		let pz = (e.target.value.length === 0) ? EMPTY_PRICEZONE : e.target.value;
		if (pz !== EMPTY_PRICEZONE && pz === this.state.filter.priceZone) {
			this.setState({ isError: true, errorMessage: msg('campaign.form.input.pricezone.same.as.current') });
			return;
		}
		this.setState({ filteredpriceZones: null, campaign: Object.assign({}, this.state.campaign, { priceZone: pz }), priceZoneInputError: false });
	}

	handleCurrentPriceZoneChange(e, selected) {
		let value = e.target ? e.target.value : e.value;
		let pz = (value.length === 0) ? EMPTY_PRICEZONE : value;
		if (pz !== EMPTY_PRICEZONE && pz === this.state.campaign.priceZone) {
			this.setState({ isError: true, errorMessage: msg('campaign.form.input.pricezone.same.as.current') });
			return;
		}
		this.setState({ filteredCurrentPriceZones: null, filter: Object.assign({}, this.state.filter, { priceZone: pz }), currentPriceZoneInputError: false });
		if (selected || !pz.key) {
			let filter = Object.assign({}, this.state.filter, { priceZone: pz });
			let lType = this.state.campaign ? this.state.campaign.type : null;
			let stationLevel = lType.key === STATION_TYPE_KEY ? true : false;
			let page = 0;
			let pageSize = userSessionParamsHandler.getPageSize(STATIONS_EQUIPMENT_TABLE_ID);
			this.loadStations(this.state.campaign.id, this.state.campaign.borneType.key, stationLevel, filter, page, pageSize);
		}
	}

	getFilterQuery(event, isDropdownClick) {
		let query = event.query.toLowerCase();
		if (!isDropdownClick) {
			if (!query || query === this.state.query) {
				return null;
			}
		} else if (query && (query === this.state.query)) {
			return null;
		}
		return query;
	}

	filterFirmwares(event, isDropdownClick) {
		let query = this.getFilterQuery(event, isDropdownClick);
		if (query === null) {
			return;
		}
		let filteredFirmwares = this.state.firmwares.filter(f => f.key.toLowerCase().startsWith(query.toLowerCase()));
		this.setState({ filteredFirmwares: filteredFirmwares, query: query });
	}

	filterCurrentFirmwares(event, isDropdownClick) {
		let query = this.getFilterQuery(event, isDropdownClick);
		if (query === null) {
			return;
		}
		let filteredFirmwares = this.state.currentFirmwares.filter(f => f.key.toLowerCase().startsWith(query.toLowerCase()));
		this.setState({ filteredCurrentFirmwares: filteredFirmwares, query: query });
	}

	filterHardwares(event, isDropdownClick) {
		let query = this.getFilterQuery(event, isDropdownClick);
		if (query === null) {
			return;
		}
		let filteredHardwares = this.state.hardwares.filter(f => f.key.toLowerCase().startsWith(query.toLowerCase()));
		this.setState({ filteredHardwares: filteredHardwares, query: query });
	}

	filterCurrentHardwares(event, isDropdownClick) {
		let query = this.getFilterQuery(event, isDropdownClick);
		if (query === null) {
			return;
		}
		let filteredHardwares = this.state.hardwares.filter(f => f.key.toLowerCase().startsWith(query.toLowerCase()));
		this.setState({ filteredCurrentHardwares: filteredHardwares, query: query });
	}

	filterPriceZones(event, isDropdownClick) {
		let query = this.getFilterQuery(event, isDropdownClick);
		if (query === null) {
			return;
		}
		let filteredPriceZones = this.state.priceZones.filter(f => f.key.toLowerCase().startsWith(query.toLowerCase()));
		this.setState({ filteredPriceZones: filteredPriceZones, query: query });
	}

	filterCurrentPriceZones(event, isDropdownClick) {
		let query = this.getFilterQuery(event, isDropdownClick);
		if (query === null) {
			return;
		}
		let filteredPriceZones = this.state.priceZones.filter(f => f.key.toLowerCase().startsWith(query.toLowerCase()));
		this.setState({ filteredCurrentPriceZones: filteredPriceZones, query: query });
	}

	checkSelectedTypes(w) {
		if (this.state.selectedEquipmentKeys) {
			let invalids = [];
			for (let e in this.state.selectedEquipmentKeys) {
				if (!e.startsWith('ST')) {
					let t = e.split("_");
					if (t.length > 1 && t[0] !== this.state.campaign.borneType.key) {
						invalids.push(e);
					}
				}
			}
			if (invalids.length > 0) {
				this.setState({
					showConfirm: true,
					confirmMessage: 'campaign.form.equipments.bornetypes.invalids',
					messageParams: { 'equipments': invalids },
					selectedConfig: w
				});
				return false;
			}
		}
		return true;
	}

	confirmUnselectEquipments() {
		if (this.state.selectedEquipmentKeys) {
			let w = this.state.selectedConfig;
			this.setState({ selectedEquipmentKeys: {} });
			this.loadStations(this.state.campaign.id, this.state.campaign.borneType.key);
			if (this.equipmentSelected()) {
				this.setState({ campaign: Object.assign({}, this.state.campaign, { firmware: w }), firmwareInputError: false });
				this.setState({ campaign: Object.assign({}, this.state.campaign, { hardware: w }), hardwareInputError: false });
			}
		}
	}

	handleEquipmentsChange(e) {
		let selectedEquipmentKeys = e.value;
		let equipments = [];
		let stations = [];
		if (selectedEquipmentKeys) {
			for (let e in selectedEquipmentKeys) {
				if ((this.equipmentSelected()) && !e.startsWith('ST')) {
					equipments.push(e);
				} else if (this.stationSelected() && e.startsWith('ST')) {
					stations.push(e)
				}				
			}
		}		
		if (!this.state.consultationPage) {
			let stationEquipmentsKeys = this.state.stationEquipments.map(element => [element.key, element.children.map(e => e.key)]);
			let selectedEquipmentKeysCopie = Object.assign({}, selectedEquipmentKeys);
			let selectedEquipmentKeysTab = Object.keys(selectedEquipmentKeys);
			for (let [stationName, equipmentNames] of stationEquipmentsKeys) {
				if (equipmentNames.every(e => selectedEquipmentKeysTab.includes(e))) {
                	selectedEquipmentKeysCopie[stationName] =  { checked: true, partialChecked: false };
				}
			}
			let nbStations = this.props.stationsData ? this.props.stationsData.nbStations : 0;
			let nbEquipments = this.props.stationsData ? this.props.stationsData.nbEquipments : 0;
						
			let unSelectedEquipments = this.state.unSelectedEquipments  ? Object.assign([], this.state.unSelectedEquipments) : [];
			let unSelectedStations = this.state.unSelectedStations  ? Object.assign([], this.state.unSelectedStations) : [];
			let mostBornesSelected = this.state.allBornesSelected || this.state.mostBornesSelected;

			let prevEquipments = [];
			let prevStations = [];
			if (this.state.selectedEquipmentKeys) {
				for (let e in this.state.selectedEquipmentKeys) {
					if ((this.equipmentSelected()) && !e.startsWith('ST')) {
						prevEquipments.push(e);
					} else if (this.stationSelected() && e.startsWith('ST')) {
						prevStations.push(e)
					}				
				}
			}

			if(mostBornesSelected) {
				let eUnselecteds = prevEquipments ? prevEquipments.filter(e => !equipments.includes(e)) : [];
				unSelectedEquipments = unSelectedEquipments.filter(e => !equipments.includes(e));
				eUnselecteds.forEach(e => unSelectedEquipments.push(e));
				let sUnselecteds = prevStations ? prevStations.filter(e => !stations.includes(e)) : [];
				unSelectedStations = unSelectedStations.filter(e => !stations.includes(e));
				sUnselecteds.forEach(e => unSelectedStations.push(e));
				mostBornesSelected = (unSelectedEquipments.length > 0 || unSelectedStations.length > 0);
			}			
			let allBornesSelected  = (this.state.mostBornesSelected && (unSelectedEquipments.length === 0 && unSelectedStations.length === 0)) 
										||
									(stations.length === nbStations || equipments.length === nbEquipments);			
			this.setState({
				selectedEquipmentKeys: selectedEquipmentKeysCopie,
				campaign: Object.assign({}, this.state.campaign, { equipments: equipments, stations: stations }),
				equipmentsInputError: false,
				allBornesSelected: allBornesSelected,
				mostBornesSelected : mostBornesSelected,
				unSelectedEquipments: allBornesSelected || !mostBornesSelected ? [] : unSelectedEquipments,
				unSelectedStations: allBornesSelected || !mostBornesSelected ? []: unSelectedStations,
				equipmentsCount: allBornesSelected ? nbEquipments : (mostBornesSelected ? nbEquipments - unSelectedEquipments.length : equipments.length),
				stationsCount: allBornesSelected ? nbStations : (mostBornesSelected ? nbStations - unSelectedStations.length : stations.length)
			});
		}
	}

	onSelectAllStationEquipments(e) {
		let allBornesSelected = e.checked;
		this.setState({ allBornesSelected: allBornesSelected, mostBornesSelected: false, unSelectedEquipments : [], unSelectedStations : []});
		if (e.checked) {
			let stationEquipmentsKeys = this.state.stationEquipments.map(element => [element.key, element.children.map(e => e.key)]);
            let selectedEquipmentKeys = Object.assign( {}, this.state.selectedEquipmentKeys);
			for (let [stationName, equipemntNames] of stationEquipmentsKeys) {
                selectedEquipmentKeys[stationName] =  { checked: true, partialChecked: false };
				for (let equipemntName of equipemntNames) {
                    selectedEquipmentKeys[equipemntName] =  { checked: true, partialChecked: false };
				}
			}
			let equipments = stationEquipmentsKeys.map(eq => eq[1]).flat();
			let stations = stationEquipmentsKeys.map(st => st[0]);
			this.setState({
                selectedEquipmentKeys : selectedEquipmentKeys,
				campaign: Object.assign({}, this.state.campaign, {
					equipments: equipments,
					stations: stations
				}),
				equipmentsCount: this.props.stationsData.nbEquipments ? this.props.stationsData.nbEquipments : 0,
				stationsCount: this.props.stationsData.nbStations ? this.props.stationsData.nbStations : 0,
				
			});
		} else {
			this.setState({
				selectedEquipmentKeys: {},
				campaign: Object.assign({}, this.state.campaign, { equipments: [], stations: [] }),
				equipmentsCount: 0,
				stationsCount: 0
			});
		}
	}

	handleEquipmentsToggle(e) {
		this.setState({ expandedStationKeys: e.value });
	}

	handleStartDateChange(e) {
		let d = new Date(e.value);
		if (d && !Number.isNaN(d.valueOf())) {
			let dd = new Date(this.state.scheduledStartTime);
			if (dd && !Number.isNaN(dd.valueOf())) {
				d.setHours(dd.getHours());
				d.setMinutes(dd.getMinutes())
			}
			this.setState({ campaign: Object.assign({}, this.state.campaign, { scheduledStartDate: d }), scheduledDatesError: false });
		}
	}

	handleEndDateChange(e) {
		let d = new Date(e.value);
		if (d && !Number.isNaN(d.valueOf())) {
			let dd = new Date(this.state.scheduledEndTime);
			if (dd && !Number.isNaN(dd.valueOf())) {
				d.setHours(dd.getHours());
				d.setMinutes(dd.getMinutes())
			}
			this.setState({ campaign: Object.assign({}, this.state.campaign, { scheduledEndDate: d }), scheduledDatesError: false });
		}
	}

	handleStartTimeChange(e) {
		this.setState({ scheduledStartTimeError: false, scheduledDatesError: false });
		if (e.value) {
			if (e.value.length < 5 && !TIME_PATTERN0.test(e.value)) {
				return;
			}
			if (e.value.length >= 5 && !TIME_PATTERN.test(e.value)) {
				this.setState({ scheduledStartTime: this.state.campaign.scheduledStartDate });
				return;
			}
		}
		let d = new Date(e.value);
		if (d && !Number.isNaN(d.valueOf())) {
			let dd = new Date(this.state.campaign.scheduledStartDate);
			dd.setHours(d.getHours());
			dd.setMinutes(d.getMinutes());
			if (new Date().getTime() > dd.getTime()) {
				this.setState({ scheduledStartTime: this.state.campaign.scheduledStartDate });
				return;
			} else {
				this.setState({ campaign: Object.assign({}, this.state.campaign, { scheduledStartDate: dd }) });
			}
		}
		this.setState({ scheduledStartTime: e.value });
	}

	handleEndTimeChange(e) {
		this.setState({ scheduledEndTimeError: false, scheduledDatesError: false });
		if (e.value) {
			if (e.value.length < 5 && !TIME_PATTERN0.test(e.value)) {
				return;
			}
			if (e.value.length >= 5 && !TIME_PATTERN.test(e.value)) {
				this.setState({ scheduledEndTime: this.state.campaign.scheduledEndDate });
				return;
			}
		}
		let d = new Date(e.value);
		if (d && !Number.isNaN(d.valueOf())) {
			let dd = new Date(this.state.campaign.scheduledEndDate);
			dd.setHours(d.getHours());
			dd.setMinutes(d.getMinutes());
			if (this.state.campaign.scheduledStartDate.getTime() > dd.getTime()) {
				this.setState({ scheduledEndTime: this.state.campaign.scheduledEndDate });
				return;
			} else {
				this.setState({ campaign: Object.assign({}, this.state.campaign, { scheduledEndDate: dd }) });
			}
		}
		this.setState({ scheduledEndTime: e.value });
	}

	validate() {
		let valid = true;
		if (this.props.planning) {
			let d = this.state.scheduledStartTime;
			if (!d || (d.length <= 5 && (!TIME_PATTERN0.test(d) || !TIME_PATTERN.test(d)))) {
				valid = false;
				this.setState({ scheduledStartTimeError: true });
			} else {
				this.setState({ scheduledStartTimeError: false });
			}
			d = this.state.scheduledEndTime;
			if (!d || (d.length <= 5 && (!TIME_PATTERN0.test(d) || !TIME_PATTERN.test(d)))) {
				valid = false;
				this.setState({ scheduledEndTimeError: true });
			} else {
				this.setState({ scheduledEndTimeError: false });
			}
			if (this.state.campaign.scheduledStartDate.getTime() > this.state.campaign.scheduledEndDate.getTime()) {
				valid = false;
				this.setState({ scheduledDatesError: true });
			} else {
				this.setState({ scheduledDatesError: false });
			}
		}
		if (!this.state.campaign.name || !this.state.campaign.name.trim()) {
			this.setState({ nameInputError: true });
			valid = false;
		} else {
			this.setState({ nameInputError: false });
		}
		if (!this.state.campaign.type || !this.state.campaign.type.value || this.state.campaign.type.value === EMPTY_TYPE_KEY) {
			this.setState({ typeInputError: true });
			valid = false;
		} else {
			this.setState({ typeInputError: false });
		}
		if (this.equipmentSelected()) {
			if (!this.state.campaign.borneType || !this.state.campaign.borneType.key) {
				this.setState({ borneTypeInputError: true });
				valid = false;
			} else {
				this.setState({ borneTypeInputError: false });
			}
			if (!this.state.campaign.firmwareType && !this.state.campaign.hardwareType) {
				this.setState({ fwhwTypeInputError: true });
				valid = false;
			} else {
				this.setState({ fwhwTypeInputError: false });
			}
			if (this.state.campaign.firmwareType && !(this.state.campaign.firmware && this.state.campaign.firmware.key)) {
				this.setState({ firmwareInputError: true });
				valid = false;
			} else {
				this.setState({ firmwareInputError: false });
			}
			if (this.state.campaign.hardwareType && !(this.state.campaign.hardware && this.state.campaign.hardware.key)) {
				this.setState({ hardwareInputError: true });
				valid = false;
			} else {
				this.setState({ hardwareInputError: false });
			}
		} else if (this.stationSelected()) {
			if (!(this.state.campaign.priceZone && this.state.campaign.priceZone.key)) {
				this.setState({ priceZoneInputError: true });
				valid = false;
			} else {
				this.setState({ priceZoneInputError: false });
			}
		}
		let equipments = [];
		let stations = [];
		if (this.state.selectedEquipmentKeys) {
			for (let e in this.state.selectedEquipmentKeys) {
				if ((this.equipmentSelected()) && !e.startsWith('ST')) {
					equipments.push(e);
				} else if (this.stationSelected() && e.startsWith('ST')) {
					stations.push(e)
				}
			}
		}
		if (this.equipmentSelected()) {
			if (equipments.length === 0) {
				this.setState({ equipmentsInputError: true });
				valid = false;
			} else {
				this.setState({ equipmentsInputError: false });
			}
		}
		if (this.stationSelected()) {
			if (stations.length === 0) {
				this.setState({ stationsInputError: true });
				valid = false;
			} else {
				this.setState({ stationsInputError: false });
			}
		}
		if (!valid) {
			return;
		}
		let mostBornesSelected = this.state.allBornesSelected ? false : this.state.mostBornesSelected;
		let vCampaign = {
			id: this.props.mode === CONSULT_MODE ? this.state.campaign.id : '',
			name: this.state.campaign.name,
			description: this.state.campaign.description,
			borneType: this.state.campaign.borneType.key,
			firmware: this.state.campaign.firmwareType && this.state.campaign.firmware && this.state.campaign.firmware.key ? this.state.campaign.firmware : null,
			hardware: this.state.campaign.hardwareType && this.state.campaign.hardware && this.state.campaign.hardware.key ? this.state.campaign.hardware : null,
			priceZone: this.state.campaign.priceZone && this.state.campaign.priceZone.key ? this.state.campaign.priceZone : null,
			equipments: mostBornesSelected ? this.state.unSelectedEquipments : equipments,
			stations: mostBornesSelected ? this.state.unSelectedStations : stations,			
			scheduledStartDate: this.state.campaign.scheduledStartDate,
			scheduledEndDate: this.state.campaign.scheduledEndDate,
			restartOnFailed: this.state.campaign.restartOnFailed
		};
		let campaignFilter = {
			page:0, pageSize: this.props.stationsData ? this.props.stationsData.nbStations : 0,
			borneType: this.state.campaign.borneType.key,
			hardware: this.state.filter.hardware.key,
			firmware: this.state.filter.firmware.key,
			priceZone: this.state.filter.priceZone.key,
			station: this.state.filter.station,
			planning: this.props.planning
		};
		let campaignForm = {
			campaign: vCampaign,
			campaignFilter: campaignFilter,
			allBornesSelected: this.state.allBornesSelected,
			mostBornesSelected: mostBornesSelected,
		}
		this.props.validate(campaignForm);
	}

	rowClassName(node) {
		return { 'equipment-node': (node.children && node.children.length === 0) };
	}

	getFormTitle() {
		if (this.props.campaign && this.props.mode === CONSULT_MODE) {
			let scheduledStartDate = toLocaleDateTimeFormat(this.props.campaign.scheduledStartDate);
			let scheduledEndDate = toLocaleDateTimeFormat(this.props.campaign.scheduledEndDate);
			if (this.props.campaign.readOnly) {
				return msg('campaign.form.started.title', { startDate: scheduledStartDate, endDate: scheduledEndDate });
			} else if (this.props.campaign.planned) {
				return msg('campaign.form.planned.title', { startDate: scheduledStartDate, endDate: scheduledEndDate });
			} else {
				return msg('campaign.form.update.title', { campaignName: this.props.campaign.name });
			}
		} else {
			return msg('campaign.form.new.title', { campaignName: (this.state.campaign ? this.state.campaign.name : '') });
		}
	}

	nameBody(row) {
		return <div className="hasTooltip p-ellipsis"> {row.data.name} <span>{row.data.name}</span> </div>;
	}

	firmwareBody(row) {
		return <div className="hasTooltip p-ellipsis"> {row.data.firmware}<span>{row.data.firmware}</span> </div>
	}

	hardwareBody(row) {
		return <div className="hasTooltip p-ellipsis"> {row.data.hardware}<span>{row.data.hardware}</span> </div>
	}

	priceZoneBody(row) {
		return <div className="hasTooltip p-ellipsis"> {row.data.priceZone}<span>{row.data.priceZone}</span> </div>
	}

	statusBody(row) {
		let statusDesc = '';
		if (row.data.status) {
			statusDesc = msg('campaign.equipment.status.' + row.data.status);
			return <div className="hasTooltip p-ellipsis"> {statusDesc}<span>{statusDesc}</span> </div>
		}
		return statusDesc;
	}

	statusDetailBody(row) {
		if (row.data.status) {
			let acquittment = row.data.serialNumber ?
				(row.data.status !== 'pending' ? msg('campaign.equipment.status.desc.acquit') + ' : ' + (row.data.acquitted ?
					msg('campaign.equipment.status.desc.acquit.ok') : msg('campaign.equipment.status.desc.acquit.ko')) : '') : '';
			let cause = row.data.status === 'failed' ? msg('campaign.equipment.status.desc.cause') + ' : ' + (row.data.statusMessage ?
				msg(row.data.statusMessage) : msg('campaign.equipment.status.desc.cause.unknown')) : '';
			if (acquittment || cause) {
				return (
					<ul style={{ padding: '0 10px 0', margin: 0 }}>
						{acquittment && <li><div className="hasTooltip p-ellipsis"> {acquittment}<span>{acquittment}</span> </div></li>}
						{cause && <li><div className="hasTooltip p-ellipsis"> {cause}<span>{cause}</span> </div></li>}
					</ul>);
			}
		}
		return '';
	}

	typeBody(row) {
		return (<span>{!row.data.type || row.data.type === 'ST' ? '' : row.data.type} </span>);
	}

	startupDateBody(row) {
		return (<span>
			{row.data.type !== 'ST' ? (!row.data.startupDate && row.data.status === 'failed'
				? msg('campaign.form.equipments.date.nodef') : toLocaleDateTimeFormat(row.data.startupDate))
				: ""}
		</span>);
	}

	reportDateBody(row) {
		return (<span>
			{row.data.type !== 'ST' ? (row.data.startupDate && row.data.status === 'failed'
				? msg('campaign.form.equipments.date.nodef') : toLocaleDateTimeFormat(row.data.reportDate))
				: ""}
		</span>);
	}

	equipmentSelected() {
		return this.state.campaign.type && (this.state.campaign.type.value === EQUIPMENT_TYPE_KEY);
	}

	firmwareTypeSelected() {
		return this.equipmentSelected() && this.state.campaign.borneType.key && this.state.campaign.firmwareType
	}

	hardwareTypeSelected() {
		return this.equipmentSelected() && this.state.campaign.borneType.key && this.state.campaign.hardwareType
	}

	stationSelected() {
		return this.state.campaign.type && (this.state.campaign.type.value === STATION_TYPE_KEY);
	}

	getStationsEquipmentsColumns() {
		let cols = [<Column field="name" header={msg('campaign.form.equipments.name')} body={this.nameBody}
			expander filter={true} filterMatchMode="contains" filterPlaceholder={msg('campaign.form.equipments.quick.search.placeholder')} className="p-md-3 p-col-12" key="name" />,
		<Column field="type" header={msg('campaign.form.equipments.type')} body={this.typeBody} className="p-md-1 p-col-12" key="type" />,
		<Column field="serialNumber" header={msg('campaign.form.equipments.serial')} className="p-md-1 p-col-12" key="serial" />
		];
		if (this.equipmentSelected()) {
			if (this.firmwareTypeSelected()) {
				cols.push(<Column field="firmware" header={msg('campaign.form.equipments.firmware')} body={this.firmwareBody} className="p-md-2 p-col-12" key="fw" />);
			}
			if (this.hardwareTypeSelected()) {
				cols.push(<Column field="hardware" header={msg('campaign.form.equipments.hardware')} body={this.hardwareBody} className="p-md-2 p-col-12" key="hw" />);
			}
		}
		if (this.stationSelected()) {
			cols.push(<Column field="priceZone" header={msg('campaign.form.equipments.pricezone')} body={this.priceZoneBody} className="p-md-2 p-col-12" key="pz" />);
		}
		if (this.state.campaign.id) {
			cols.push(<Column field="status" header={msg('campaign.form.equipments.status')} body={this.statusBody} className="p-md-1 p-col-12" key="status" />);
			cols.push(<Column field="statusDesc" header={msg('campaign.form.equipments.status.desc')} body={this.statusDetailBody} className="p-md-2 p-col-12" key="desc" />);
			cols.push(<Column field="startupDate" header={msg('campaign.form.equipments.startupdate')} body={this.startupDateBody} className="p-md-2 p-col-12" key="start" />);
			cols.push(<Column field="reportDate" header={msg('campaign.form.equipments.reportdate')} body={this.reportDateBody} className="p-md-2 p-col-12" key="report" />);
		}
		return cols;
	}

	isFirmwareDisabled() {
		return !((this.state.authorizations.fw.create && !this.state.campaign.id) || (this.state.authorizations.fw.update && this.state.campaign.id));
	}

	isHardwareDisabled() {
		return !((this.state.authorizations.hw.create && !this.state.campaign.id) || (this.state.authorizations.hw.update && this.state.campaign.id));
	}

	isPriceZoneDisabled() {
		return !((this.state.authorizations.pz.create && !this.state.campaign.id) || (this.state.authorizations.pz.update && this.state.campaign.id));
	}

	isPlanningButtonDisabled() {
		let fwUpdate = this.state.authorizations.fw.update && this.state.campaign.id;
		let hwUpdate = this.state.authorizations.hw.update && this.state.campaign.id;
		let pzUpdate = this.state.authorizations.pz.update && this.state.campaign.id;
		let enabled = ((this.state.campaign.firmwareType || this.state.campaign.hardwareType) &&
			((this.state.campaign.firmwareType && fwUpdate) || (this.state.campaign.hardwareType && hwUpdate))) ||
			(this.state.campaign.firmwareType && this.state.campaign.hardwareType && (fwUpdate && hwUpdate)) ||
			((this.state.campaign.type === STATION_TYPE) && pzUpdate);
		return !enabled;
	}

	isValidateButtonDisabled() {
		let fwCreate = this.state.authorizations.fw.create && !this.state.campaign.id;
		let fwUpdate = this.state.authorizations.fw.update && this.state.campaign.id;
		let hwCreate = this.state.authorizations.hw.create && !this.state.campaign.id;
		let hwUpdate = this.state.authorizations.hw.update && this.state.campaign.id;
		let pzCreate = this.state.authorizations.pz.create && !this.state.campaign.id;
		let pzUpdate = this.state.authorizations.pz.update && this.state.campaign.id;
		let enabled = (
			(this.state.campaign.firmwareType || this.state.campaign.hardwareType) &&
			((this.state.campaign.firmwareType && (fwCreate || fwUpdate)) || (this.state.campaign.hardwareType && (hwCreate || hwUpdate)))) ||
			(this.state.campaign.firmwareType && this.state.campaign.hardwareType && ((fwCreate && hwCreate) || (fwUpdate && hwUpdate))) ||
			((this.state.campaign.type === STATION_TYPE) && (pzCreate || pzUpdate));
		return this.state.campaign.readOnly || !(this.equipmentSelected() || this.stationSelected()) || !enabled;
	}

	onContextMenuSelection(event) {
		if (this.contextMenuRef.current && !event.value.startsWith('ST')) {
			this.contextMenuRef.current.show(event.originalEvent);
		}
	}

	onDetailsClick() {
		if (!this.state.authorizations.eq.read) {
			this.setState({ isWarning: true });
			return;
		}
		const equipmentId = this.state.dataTableSelection.split('_')[1];
		this.props.history.push('/actitvityJournal?equipmentId=' + equipmentId);
		defaultActiviteFilter.startDate = null;
		this.props.loadEquipmentById(equipmentId, 0, userSessionParamsHandler.getPageSize(), defaultActiviteFilter)
	}
	onRowClick(event) {
		if (event.node.key.startsWith('ST') || !this.state.consultationPage) {
			return;
		}
		if (this.state.selectedRow) {
			this.setState({ selectedRow: Object.assign(this.state.selectedRow, this.state.selectedRow, { className: 'equipment-node' }) })
		}
		this.setState({ selectedRow: event.originalEvent.currentTarget });
		event.originalEvent.currentTarget.className = 'equipment-node p-highlight';
	}

	selectionMode() {
		if (this.state.consultationPage) {
			return 'single';
		} else if (this.state.campaign.readOnly) {
			return '';
		} else {
			return 'checkbox';
		}
	}
	render() {
		let today = new Date();
		let read = this.state.authorizations.fw.read || this.state.authorizations.hw.read || this.state.authorizations.pz.read;
		let first = this.state.first;
		let pageSize = userSessionParamsHandler.getPageSize(STATIONS_EQUIPMENT_TABLE_ID);
		let totalRecords = this.state.totalPages ? this.state.totalPages * pageSize : 0;
		const ContextMenuContent = this.state.consultationPage ? [{label: msg('equipement.activity.jornal'), command: this.onDetailsClick}] : [];
		const contextMenu = ContextMenuContent && this.state.consultationPage && (<ContextMenu model={ContextMenuContent} ref={this.contextMenuRef} />);

		return read && (
			<div className="p-grid commonClass campaign card card-w-title">
				<div className="p-col-12 campaign-title"><h2>{this.getFormTitle()}</h2></div>
				<div className="p-col-12 p-grid">
					<div className="p-col-6">
						{this.state.campaign.readOnly &&
							<span className="infos">{msg('campaign.form.message.not.update', { campaignName: (this.state.campaign ? this.state.campaign.name : '') })} </span>}
					</div>
					<div className="p-col-6 campaign-excel-btn">
						{this.props.mode &&
							<input id="image" type="image" src={"/assets/ultima/layout/images/cartadis/excel.svg"} style={{ height: '2.5em' }} alt={"Excel"}
								onClick={() => { UploadService.getFileData('campaigns/' + this.state.campaign.id + '/report', 'campaign-report.xlsx', {}) }} />}
					</div>
				</div>
				{!this.state.campaign.readOnly &&
					<div className={"card card-w-title p-col-12 "} >
						<div className="p-grid form-group p-col-12">
							<div className="p-col-12 p-grid">
								<div className="p-col-4"><label>{msg('campaign.form.input.name')} <span className="obligatoire">*</span> :</label></div>
								<div className="p-col-8">
									<span className="md-inputfield">
										<InputText value={this.state.campaign.name} className="inputClass" placeholder={msg('campaign.form.input.name')}
											size="30" maxLength="50" id="name"
											onChange={e => this.setState({ campaign: Object.assign({}, this.state.campaign, { name: e.target.value }), nameInputError: false })} key="name" />
									</span>
									{this.state.nameInputError && <Message severity="error" text={msg('campaign.form.input.name.required')} key="nameErr" />}
								</div>
							</div>
							<div className="p-col-12 p-grid">
								<div className="p-col-4"><label>{msg('campaign.form.input.description')} :</label></div>
								<div className="p-col-8">
									<span className="md-inputfield">
										<InputText value={this.state.campaign.description} className="inputClass" placeholder={msg('campaign.form.input.description')}
											size="60" maxLength="150" id="description"
											onChange={e => this.setState({ campaign: Object.assign({}, this.state.campaign, { description: e.target.value }) })} key="description" />
									</span>
								</div>
							</div>
							<div className="p-col-12 p-grid">
								<div className="p-col-4"><label>{msg('campaign.form.input.type')} <span className="obligatoire">*</span>  :</label></div>
								<div className="p-col-4">
									<Dropdown value={this.state.campaign.type} options={this.state.campaignTypes} optionLabel="label"
										onChange={e => this.handleTypeChange(e)} disabled={this.state.campaign.id ? true : false} />
									{this.state.typeInputError && <Message severity="error" text={msg('campaign.form.input.type.required')} key="campaignTypesErr" />}
								</div>
								<div className="p-col-4">{this.stationSelected() && <span>{msg('campaign.form.input.station.type.note')}</span>}</div>
							</div>
							{this.equipmentSelected() &&
								<div className="p-col-12 p-grid">
									<div className="p-col-4"><label>{msg('campaign.form.input.borne.type')} <span className="obligatoire">*</span>  :</label></div>
									<div className="p-col-8">
										<Dropdown value={this.state.campaign.borneType} options={this.state.borneTypes} optionLabel="label"
											onChange={e => this.handleBorneTypeChange(e)} disabled={this.state.campaign.id ? true : false} dataKey="key" />
										{this.state.borneTypeInputError && <Message severity="error" text={msg('campaign.form.input.bone.type.required')} key="borneTypesErr" />}
									</div>
								</div>}
							{this.equipmentSelected() &&
								<div className="p-col-12 p-grid">
									<div className="p-col-4"><label>{msg('campaign.form.input.update.type')} <span className="obligatoire">*</span>  :</label></div>
									<div className="p-col-8 p-grid">
										<div className="p-col-6">
											<Checkbox value={this.state.campaign.firmwareType} checked={this.state.campaign.firmwareType} key="firmwareTypeChbx"
												onChange={e => this.setState({ campaign: Object.assign({}, this.state.campaign, { firmwareType: e.checked }) })}
												disabled={this.isFirmwareDisabled()} />
											<span className={this.isFirmwareDisabled() ? "disabled-label" : ""}>{msg('campaign.form.input.type.fw')}</span>
										</div>
										<div className="p-col-6">
											<Checkbox value={this.state.campaign.hardwareType} checked={this.state.campaign.hardwareType} key="hardwareTypeChbx"
												onChange={e => this.setState({ campaign: Object.assign({}, this.state.campaign, { hardwareType: e.checked }) })}
												disabled={this.isHardwareDisabled()} />
											<span className={this.isHardwareDisabled() ? "disabled-label" : ""}>{msg('campaign.form.input.type.hw')}</span>
										</div>
										{this.state.fwhwTypeInputError && <Message severity="error" text={msg('campaign.form.input.fwhw.type.required')} key="fwhwTypesErr" />}
									</div>
								</div>}
							{(this.firmwareTypeSelected() && this.state.campaign.firmware &&
								<div className="p-col-12 p-grid">
									<div className="p-col-4"><label>{msg('campaign.form.firmware.title')} <span className="obligatoire">*</span> :</label></div>
									<div className="p-col-4 st-placeholder">
										<AutoComplete value={this.state.campaign.firmware} suggestions={this.state.filteredFirmwares}
											completeMethod={e => this.filterFirmwares(e)} onDropdownClick={e => this.filterFirmwares(e, true)}
											onChange={e => this.handleFirmwareChange(e)} placeholder={msg('campaign.form.firmware.placeholder')}
											minLength={0} size={30} dropdown={true} dataKey="key" field="key" delay={100} key="firmwares"
											disabled={this.isFirmwareDisabled()} />
										{this.state.firmwareInputError && <Message severity="error" text={msg('campaign.form.input.firmware.required')} key="firmwareErr" />}
									</div>
									<div className="p-col-4"><i className={(this.isFirmwareDisabled() ? "disabled-label" : "") + " note"}>{this.state.campaign.firmware.label}</i></div>
								</div>)}
							{this.hardwareTypeSelected() && this.state.campaign.hardware &&
								<div className="p-col-12 p-grid">
									<div className="p-col-4"><label>{msg('campaign.form.hardware.title')} <span className="obligatoire">*</span> :</label></div>
									<div className="p-col-4 st-placeholder">
										<AutoComplete value={this.state.campaign.hardware} suggestions={this.state.filteredHardwares}
											completeMethod={e => this.filterHardwares(e)} onDropdownClick={e => this.filterHardwares(e, true)}
											onChange={e => this.handleHardwareChange(e)} placeholder={msg('campaign.form.hardware.placeholder')}
											minLength={0} size={30} dropdown={true} dataKey="key" field="key" delay={100} key="hardwares"
											disabled={this.isHardwareDisabled()} />
										{this.state.hardwareInputError && <Message severity="error" text={msg('campaign.form.input.hardware.required')} key="hardwaresErr" />}
									</div>
									<div className="p-col-4"><i className={(this.isHardwareDisabled() ? "disabled-label" : "") + " note"}>{this.state.campaign.hardware.label}</i></div>
								</div>}
							{this.stationSelected() && this.state.campaign.priceZone &&
								<div className="p-col-12 p-grid">
									<div className="p-col-4"><label>{msg('campaign.form.pricezone.title')} <span className="obligatoire">*</span> :</label></div>
									<div className="p-col-4 st-placeholder">
										<AutoComplete value={this.state.campaign.priceZone} suggestions={this.state.filteredPriceZones}
											completeMethod={e => this.filterPriceZones(e)} onDropdownClick={e => this.filterPriceZones(e, true)}
											onChange={e => this.handlePriceZoneChange(e)} placeholder={msg('campaign.form.pricezone.placeholder')}
											minLength={0} size={30} dropdown={true} dataKey="key" field="key" delay={100} key="priceZones"
											disabled={this.isPriceZoneDisabled()} />
										{this.state.priceZoneInputError && <Message severity="error" text={msg('campaign.form.input.pricezone.required')} key="priceZoneErr" />}
									</div>
									<div className="p-col-4"><i className={(this.isPriceZoneDisabled() ? "disabled-label" : "") + " note"}>{this.state.campaign.priceZone.label}</i></div>
								</div>}
							{this.state.campaign.status &&
								<div className="p-col-12 p-grid">
									<div className="p-col-4"><label>{msg('campaign.form.status.label')} :</label></div>
									<div className="p-col-8"> {msg('campaign.status.' + this.state.campaign.status)} </div>
								</div>}
							<div className="p-col-12 p-grid">
								<div className="p-col-4"><label>{this.stationSelected() ? msg('campaign.form.stations.count') : msg('campaign.form.equipments.count')} :</label></div>
								<div className="p-col-8">{this.stationSelected() ? this.state.stationsCount : this.state.equipmentsCount}</div>
							</div>
						</div>
					</div>}
				{this.state.campaign.readOnly &&
					<CampaignReport campaign={this.state.campaign} campaignEvents={this.props.campaignEvents} />}
				{this.props.planning &&
					<div className="card card-w-title p-col-12 planning">
						<div className="p-col-12 campaign-title"><h3>{msg('campaign.form.planning.title')}</h3></div>
						<div className="p-col-12 p-grid form-group">
							<div className="p-col-12 p-md-2" />
							<div className="p-col-12 p-md-3">
								<i className="note">{msg('campaign.form.planning.note')}</i>
							</div>
							<div className="p-col-12 p-md-7" />
							<div className="p-col-12">
								{this.state.scheduledDatesError && <Message severity="error" text={msg('campaign.form.input.scheduled.dates.error')} key="datesError" />}
								{this.state.scheduledStartTimeError && <Message severity="error" text={msg('campaign.form.input.scheduled.starttime.error')} key="startTimeError" />}
								{this.state.scheduledEndTimeError && <Message severity="error" text={msg('campaign.form.input.scheduled.endtime.error')} key="endTimeError" />}
							</div>
							<div className="p-col-12 p-md-2 calendar-label"><label htmlFor="dtStart">{msg('campaign.form.startdate')}:</label></div>
							<div className="p-col-12 p-md-4 p-grid">
								<div className="p-col-12 p-md-4">
									<Calendar value={this.state.campaign.scheduledStartDate} dateFormat={'dd/mm/yy'} hourFormat={'24'}
										selectOtherMonths={true} hideOnDateTimeSelect={true} readOnlyInput={true}
										minDate={today} maxDate={this.state.campaign.scheduledEndDate} locale={this.state.locale}
										onChange={e => this.handleStartDateChange(e)}
										inputClassName="date-input" id={'dtStart'} key={'dtStart'} />
								</div>
								<div className="p-col-12 p-md-4">
									<Calendar value={this.state.scheduledStartTime} dateFormat={'dd/mm/yy'} hourFormat={'24'} timeOnly showTime
										showOnFocus={true} keepInvalid={true} locale={this.state.locale}
										onChange={e => this.handleStartTimeChange(e)}
										inputClassName="time-input" id={'timeStart'} key={'timeStart'} />
								</div>
							</div>
							<div className="p-col-12 p-md-2 calendar-label"><label htmlFor="dtEnd">{msg('campaign.form.enddate')}:</label></div>
							<div className="p-col-12 p-md-4 p-grid">
								<div className="p-col-12 p-md-4">
									<Calendar value={this.state.campaign.scheduledEndDate} dateFormat={'dd/mm/yy'} hourFormat={'24'}
										selectOtherMonths={true} hideOnDateTimeSelect={true} readOnlyInput={true}
										minDate={this.state.campaign.scheduledStartDate} locale={this.state.locale}
										onChange={e => this.handleEndDateChange(e)}
										inputClassName="date-input" id={'dtEnd'} key={'dtEnd'} size="10" maxLength="10" />
								</div>
								<div className="p-col-12 p-md-4">
									<Calendar value={this.state.scheduledEndTime} dateFormat={'dd/mm/yy'} hourFormat={'24'} timeOnly showTime
										showOnFocus={true} keepInvalid={true} locale={this.state.locale}
										onChange={e => this.handleEndTimeChange(e)}
										inputClassName="time-input" id={'timeEnd'} key={'timeEnd'} />
								</div>
							</div>
							<div className="p-col-12 plan-buttons">
								<div className="item p-col-12 p-md-4" />
								<div className="item">
									<Button label={msg('campaign.form.button.planning.confirm')} title={msg('campaign.form.button.validate')} key="confirm" onClick={this.validate} disabled={this.isPlanningButtonDisabled()} />
								</div>
								<div className="item">
									<Button label={msg('campaign.form.button.planning.return')} title={msg('campaign.form.button.cancel')} key="return" onClick={this.props.cancel} />
								</div>
							</div>
						</div>
					</div>}
				{(this.equipmentSelected() || this.stationSelected()) &&
					<div className="card card-w-title p-col-12">
						<div className="p-col-12 campaign-title">
							<h3>{this.stationSelected() ? msg('campaign.form.stations.title') : msg('campaign.form.equipments.title')}</h3>
						</div>
						<div className="p-col-12 p-grid">
							{(!(this.state.campaign.readOnly && this.props.mode === CONSULT_MODE) && ((this.equipmentSelected() && (this.firmwareTypeSelected() || this.hardwareTypeSelected()))
								|| this.stationSelected()))
								&&
								<div className="p-col-2" style={{ textAlign: 'left', minWidth: 'fit-content' }}>
									<Checkbox value={this.state.allBornesSelected} checked={this.state.allBornesSelected} key="SelectionBornesChbx"
										onChange={e => this.onSelectAllStationEquipments(e)} />
									<span> {this.state.allBornesSelected ? BORNES_SELECTION_MODE[1].key : BORNES_SELECTION_MODE[0].key}</span>
								</div>
							}
							{((this.equipmentSelected() && (this.firmwareTypeSelected() || this.hardwareTypeSelected())) || this.stationSelected()) &&
								<div className="p-md-1 p-col-12" style={{ textAlign: 'center' }} >Filtre :</div>
							}
							{this.equipmentSelected() && this.firmwareTypeSelected() &&
								<div className="p-md-4 p-col-12 st-placeholder">
									<AutoComplete value={this.state.filter.firmware} suggestions={this.state.filteredCurrentFirmwares}
										completeMethod={e => this.filterCurrentFirmwares(e)} onDropdownClick={e => this.filterCurrentFirmwares(e, true)}
										onChange={e => this.handleCurrentFirmwareChange(e)} onSelect={e => this.handleCurrentFirmwareChange(e, true)}
										placeholder={msg('campaign.form.current.firmware.placeholder')}
										minLength={0} size={30} dropdown={true} field="key" delay={100} key="currentFirmwares" />
								</div>}
							{this.equipmentSelected() && this.hardwareTypeSelected() &&
								<div className="p-md-4 p-col-12 st-placeholder">
									<AutoComplete value={this.state.filter.hardware} suggestions={this.state.filteredCurrentHardwares}
										completeMethod={e => this.filterCurrentHardwares(e)} onDropdownClick={e => this.filterCurrentHardwares(e, true)}
										onChange={e => this.handleCurrentHardwareChange(e)} onSelect={e => this.handleCurrentHardwareChange(e, true)}
										placeholder={msg('campaign.form.current.hardware.placeholder')}
										minLength={0} size={35} dropdown={true} dataKey="key" field="key" delay={100} key="currentHhardwares" />
								</div>}
							{this.stationSelected() &&
								<div className="p-md-4 p-col-12 st-placeholder">
									<AutoComplete value={this.state.filter.priceZone} suggestions={this.state.filteredCurrentPriceZones}
										completeMethod={e => this.filterCurrentPriceZones(e)} onDropdownClick={e => this.filterCurrentPriceZones(e, true)}
										onChange={e => this.handleCurrentPriceZoneChange(e)} onSelect={e => this.handleCurrentPriceZoneChange(e, true)}
										placeholder={msg('campaign.form.current.pricezone.placeholder')}
										minLength={0} size={30} dropdown={true} dataKey="key" field="key" delay={100} key="currentPriceZones" />
								</div>}
						</div>
						{this.state.stationsInputError && <div className="p-col-12"><Message severity="error" text={msg('campaign.form.input.stations.required')} key="stations" /></div>}
						{this.state.equipmentsInputError && <div className="p-col-12"><Message severity="error" text={msg('campaign.form.input.equipments.required')} key="equipments" /></div>}
						<div className="p-col-12 equipments-list">
							<TreeTable value={this.state.stationEquipments} selectionMode={this.selectionMode()}
								paginator={true} paginatorPosition="both" rows={pageSize} first={first} totalRecords={totalRecords} lazy={true}
								rowsPerPageOptions={userSessionParamsHandler.getRowsPerPageOptions(STATIONS_EQUIPMENT_TABLE_ID)} onPage={this.onPage}
								filters={this.state.filters} filterMode="custom" onFilter={this.onFilter}
								expandedKeys={this.state.expandedStationKeys} onToggle={e => this.handleEquipmentsToggle(e)}
								selectionKeys={this.state.selectedEquipmentKeys} onSelectionChange={e => this.handleEquipmentsChange(e)}
								emptyMessage={msg('campaign.form.equipments.empty')} rowClassName={this.rowClassName}
								tableClassName="equipments-list" className="equipments-list"
								onContextMenu={ContextMenuContent && this.state.consultationPage ? e => this.onRowClick(e) : undefined} ContextMenuContent={ContextMenuContent}
								onContextMenuSelectionChange={event => { this.setState({ dataTableSelection: event.value }); this.onContextMenuSelection(event) }}
								onRowClick={this.onRowClick}
							>
								{this.getStationsEquipmentsColumns()}
							</TreeTable>
						</div>
					</div>}

				{!this.props.planning &&
					<div className="p-grid p-col-12">
						<div className="item p-col-8 p-md-8" />
						<div className="p-col-2 p-md-4 campaign-buttons">
							<div className="item">
								<Button label={msg('campaign.form.button.validate')} title={msg('campaign.form.button.validate')} key="validate" onClick={this.validate}
									disabled={this.isValidateButtonDisabled()} />
							</div>
							<div className="item">
								<Button label={this.state.campaign.readOnly ? msg('campaign.form.button.planning.return') : msg('campaign.form.button.cancel')}
									title={msg('campaign.form.button.cancel')} key="cancel" onClick={e => { this.setState({ campaign: null }); this.props.cancel(); }} />
							</div>
						</div>
					</div>
				}
				<MessageDialog confirm={true} className={'message-dialog confirm-message'} visible={this.state.showConfirm} title={msg('message.dialog.confirm.title')}
					message={this.state.showConfirm && this.state.confirmMessage} messageParams={this.state.showConfirm && this.state.messageParams}
					key="confirm-dlg"
					onConfirm={() => { this.setState({ showConfirm: false }); this.confirmUnselectEquipments() }} onCancel={() => this.setState({ showConfirm: false })} />
				<MessageDialog className={'message-dialog error-message'} visible={this.state.isError} title={msg('message.dialog.error.title')}
					message={this.state.isError && msg(this.state.errorMessage)}
					onHide={() => this.setState({ isError: false, errorMessage: '' })} />
				<MessageDialog className={'message-dialog warn-message'} visible={this.state.isWarning && !this.state.authorizations.eq.read}
					title={msg('message.dialog.warn.title')}
					messagesArray={this.state.isWarning && [
						msg("error.campaigns.consult.no.right.view.activity.journal.line1"),
						msg("error.campaigns.consult.no.right.view.activity.journal.line2")
					]}
					onHide={() => this.setState({ isWarning: false })} />
				{this.state.consultationPage && contextMenu}
			</div>);
	}
};

export default CampaignForm;