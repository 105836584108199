import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import TableData from '../../../utils/TableData';
import MessageDialog from '../../common/MessageDialog.js';
import { rolesValidator, NOTIFICATIONS, CREATE, READ, UPDATE, DELETE } from '../../../utils/RolesValidator.js';
import { userSessionParamsHandler } from '../../../utils/UserSessionParamsHandler';
import { progressSpinnerHandler } from '../../../utils/ProgressSpinnerHandler';
import msg from '../../../texts/msg';
import '../../common/css/common.css';
import './Notification.css';

class Notifications extends Component {

	constructor(props) {
		super(props);
		this.state = {
			first: 0,
			rowSelection: [],
			buttonSupprimeSelectd: false,
			buttonUpdateSelectd: false,
			display: false,
			isSystemeAbonnement: false,
			isBornAbonnement: false,
			stationData: null,
			notificationByIdData: null,
			loading: false,
			keyword: null,
			notificationExist: false,
			closable: false,
			borneFilter: {
				notificationIds: []
			},
			rechecheObject: {
				idSearch: null,
				idValid: false,
			},
			authorized: rolesValidator.validate(NOTIFICATIONS)
		};

		this.onSelection = this.onSelection.bind(this);
		this.loadNotifications = this.loadNotifications.bind(this);
		this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
		this.update = this.update.bind(this);
		this.deleteNotification = this.deleteNotification.bind(this);
		this.searcheById = this.searcheById.bind(this);
		this.createSubscriptionBorne = this.createSubscriptionBorne.bind(this);
		this.createSubscriptionSysteme = this.createSubscriptionSysteme.bind(this);
		this.notificationSearch = this.notificationSearch.bind(this);
	}

	componentDidMount() {
		progressSpinnerHandler.show(true); 
		this.props.setDataInfoBar(null);
		this.loadNotifications(0, userSessionParamsHandler.getPageSize());
	}

	componentDidUpdate(prevProps) {
		if(this.props.match.params.submenu !== prevProps.match.params.submenu){
			this.loadNotifications(0, userSessionParamsHandler.getPageSize());
		}
		let prevDelete = prevProps.deleteNotificationData && prevProps.deleteNotificationData.lastChange;
		let lastDelete = this.props.deleteNotificationData && this.props.deleteNotificationData.lastChange;
		let prevCreate = prevProps.createNotificationData && prevProps.createNotificationData.lastChange;
		let lastCreate = this.props.createNotificationData && this.props.createNotificationData.lastChange;
		let prevUpdate = prevProps.updateNotificationData && prevProps.updateNotificationData.lastChange;
		let lastUpdate = this.props.updateNotificationData && this.props.updateNotificationData.lastChange;		
		let prevNotification = prevProps.notifications && prevProps.notifications.lastChange;
		let lastNotification = this.props.notifications && this.props.notifications.lastChange;
		let reload = (lastDelete !== prevDelete || lastCreate !== prevCreate || lastUpdate !== prevUpdate);
		if (reload) {
			this.loadNotifications(0, userSessionParamsHandler.getPageSize());
		}
		if ((lastNotification && (!prevNotification || prevNotification < lastNotification)) && this.props.notifications.notifications.length === 0) {
				this.setState({ notificationExist: true });
		}
		if(this.props.notifications && (this.props.notifications  !== prevProps.notifications)){
            progressSpinnerHandler.show(false);
        }
		let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            progressSpinnerHandler.show(false);
        }
	}

	loadNotifications(page, pageSize) {
		progressSpinnerHandler.show(true); 
		this.props.loadNotifications(page, pageSize, this.state.keyword, this.props.match.params.submenu);
	}

	searcheById(rechecheObject) {
		progressSpinnerHandler.show(true);
		let page = 0;
		let pageSize = userSessionParamsHandler.getPageSize();
		this.setState({ rechecheObject: rechecheObject });
		let keyword = rechecheObject.idSearch ? rechecheObject.idSearch : this.state.keyword;
		this.props.loadNotifications(page, pageSize, keyword, this.props.match.params.submenu);
	}

	onSelection(event) {
		let notificationIds = [];
		let notificationNames = '';
		let lfilter = [];
		if (event.value) {
			this.setState({ rowSelection: event.value });
			event.value.forEach(n => {
				notificationIds.push(n.id);
				notificationNames += (notificationNames ? ', ' + n.name : n.name);
				lfilter.push({ key: n.id, value: n.id });
			});
			lfilter = Object.assign({}, this.state.borneFilter, { notificationIds: lfilter });
		}
		if (notificationIds.length > 0) {

			this.setState({ borneFilter: lfilter });
			if (notificationIds.length === 1) {
				this.setState({ buttonUpdateSelectd: true, notificationId: notificationIds[0] });
			} else if (notificationIds.length >= 1) {
				this.setState({ buttonUpdateSelectd: false, notificationId: null });
			}
			let message = notificationIds.length === 1 ? msg('subscription.delete.confirm.message', { subscription: notificationNames })
				: msg('subscriptions.delete.confirm.message', { subscription: notificationNames });
			this.setState({ buttonSupprimeSelectd: true, notificationIds: notificationIds, confirmDeleteMessage: message });
		} else {
			this.setState({ buttonUpdateSelectd: false, buttonSupprimeSelectd: false, notificationIds: null, notificationId: null });
		}
	}

	update() {
		if (this.state.notificationId) {
			this.props.loadNotificationById(this.state.notificationId);
			this.props.loadSubscribers(this.props.match.params.submenu, true);
			this.props.history.push('/notificationUpdate/'  + this.props.match.params.submenu);
		} else {
			this.setState({ showWarn: true });
		}
	}

	deleteNotification() {
		if (this.state.notificationIds && this.state.notificationIds.length > 0) {
			this.setState({ showConfirm: true });
		} else {
			this.setState({ showWarn: true });
		}
	}

	confirmDelete() {
		if (this.state.notificationIds && this.state.notificationIds.length > 0) {
			this.props.deleteNotification(this.state.notificationIds);
			this.setState({ buttonSupprimeSelectd: false });
		} else {
			this.setState({ showWarn: false });
		}
	}

	actionBodyTemplate(rowData, column) {
        let uAuthorized = this.state.authorized.toString().includes(UPDATE);
		return (
			<Button type="button" onClick={() => this.sendTest(rowData, column)} icon="pi-md-send" tooltip={msg('notification.send.email')} disabled={!uAuthorized}></Button>
		);
	}

	sendTest(rowData, column) {
		this.props.sendNotification(rowData.id, null);
	}

	UNSAFE_componentWillReceiveProps(nextProps, prevProps) {

		if (nextProps.data !== this.props.data) {

			this.setState({
				stationData: this.props.stationData
			});
			this.setState({
				notificationByIdData: this.props.notificationByIdData
			});
		}
	}

	createSubscriptionBorne() {
		this.props.loadSubscribers(this.props.match.params.submenu, true);
	}
	createSubscriptionSysteme() {
		this.props.loadSubscribers(this.props.match.params.submenu, false);
	}

	renderFooter() {
		return (
			<div>
				<Button label="Ok" icon="pi pi-check" onClick={this.notificationSearch} />
			</div>
		);
	}

	notificationSearch() {
		this.setState({ notificationExist: false });
	}

	getDescription(rowData, column) {
		let description = rowData.description ? rowData.description : "";
        if (description != null && description.length > 24)
            return <div className="hasTooltip p-ellipsis"> {description} <span> {description}  </span></div>;
        else
            return <div> {description} </div>

	}

	getName(rowData, column) {
		let name = rowData.name ? rowData.name : "";
        if (name != null && name.length > 24)
            return <div className="hasTooltip p-ellipsis"> {name} <span> {name}  </span></div>;
        else
            return <div> {name} </div>

	}

	getId(rowData, column) {
		return <div> {rowData.ownerId} - {rowData.id} </div>
	}

	render() {		
		let cAuthorized = this.state.authorized.toString().includes(CREATE);
        let rAuthorized = this.state.authorized.toString().includes(READ);
        let uAuthorized = this.state.authorized.toString().includes(UPDATE);
        let dAuthorized = this.state.authorized.toString().includes(DELETE);
		let notificationsColumns = [
			{ isColWithBody: false, field: "selectItems", header: '', selectionMode: "multiple", sortable: false, className: "p-col-checkbox text-align-center" },
			{ isColWithBody: true, field: "id", header: msg('notification.id'), sortable: false, body: this.getId, className: "p-col-2" },
			{ isColWithBody: true, field: "name", header: msg('notification.name'), body: this.getName, sortable: false, className: "p-col-2" },
			{ isColWithBody: true, field: "description", header: msg('notification.description'), body: this.getDescription, sortable: false, className: "p-col-4" },
			{ isColWithBody: false, field: "diffusion", header: msg('notification.diffusion'), sortable: false, className: "p-col-5" }
		];
		if(this.props.match.params.submenu !== 'application'){
			notificationsColumns.push({ isColWithBody: true, field: "send_test", header: msg('notification.send.test'), body: this.actionBodyTemplate, sortable: false, className: "p-col-1 text-align-center" });
		}

		let equipmentsOutilsBar = [
			{ filename: "Liste des abonnements.xlsx", iconSrc: "/assets/ultima/layout/images/cartadis/excel.svg", titleIcone: "Abonnements", tooltipTextIcone: msg('notification.tooltip.export'), urlExport: "notifications/notification-xls" }
		]

		let notifications = this.props.notifications && this.props.notifications.notifications ? this.props.notifications.notifications : [];
		let totalPages = this.props.notifications ? this.props.notifications.totalPages : 0;
		let notificationsTable = (
			<div className="table-first-checkbox-column">
				<TableData data={notifications} titleTable={msg('notification.title')} totalPages={totalPages}
					selection={this.state.rowSelection}
					loading={this.state.loading}
					onSelection={this.onSelection}
					getDataFunction={this.loadNotifications} paramnFunctionData={null}
					columns={notificationsColumns} outilsBar={equipmentsOutilsBar} filter={this.state.borneFilter}
					searchInTable={false} sertchById={true} idTxtPlaceholder={msg('subscription.maintenance.input.placeholder')}
					apply={this.searcheById} emptyMessage={msg('subscription.maintenance.searche.notification')} />
			</div>);

		return rAuthorized && (

			<div className="p-grid card commonClass">
				<div className="p-col-12">
					<div className="p-col-2 p-md-4 item">
						<div className="p-col-12"><h2 className="title">{msg('notification.event.abonnement')}</h2></div>
					</div>
					<div className="p-col-8 item">
						<div className={this.state.buttonUpdateSelectd ? "btnSelected item" 
						: "btnNotSelected item "} style={{ marginLeft: '-5px' }}>
							<Button label={msg('notification.modify')} title={msg('notification.modify')} onClick={this.update} key="modifier" disabled={!uAuthorized && !rAuthorized} />
						</div>
						<div className={this.state.buttonSupprimeSelectd ? "btnSelected item " : "btnNotSelected item"} >
							<Button label={msg('notification.delete')} title={msg('notification.delete')} onClick={this.deleteNotification} key="delete" disabled={!dAuthorized}  />
						</div>
						<div className="item btnSelected">
							<Link to={"/notificationCreate/" + this.props.match.params.submenu}>
								<Button label={msg('notification.new.subscription')} title={msg('notification.new.subscription')} onClick={() => { this.createSubscriptionBorne() }} className="button" disabled={!cAuthorized}/>
							</Link>
						</div>
						<div className="item btnSelected">
							{this.props.match.params.submenu !== 'application' && <Link to={"/notificationCreate/" + this.props.match.params.submenu}>
								<Button label={msg('notification.new.system.subscription')} title={msg('notification.new.system.subscription')} onClick={() => { this.createSubscriptionSysteme() }} className="button" disabled={!cAuthorized}/>
							</Link>}
						</div>
					</div>
					{notificationsTable}
				</div>
				<MessageDialog confirm={true} className={'message-dialog confirm-message'} visible={this.state.showConfirm} title={msg('message.dialog.confirm.title')}
					message={this.state.showConfirm && this.state.confirmDeleteMessage} key="confirm-delete"
					onConfirm={() => { this.setState({ showConfirm: false, confirmDeleteMessage: '' }); this.confirmDelete() }}
					onCancel={() => this.setState({ showConfirm: false, confirmDeleteMessage: '' })} />
				<MessageDialog className={'message-dialog warn-message'} visible={this.state.showWarn} title={msg('message.dialog.warn.title')}
					message={this.state.showWarn && msg('notification.select.line')} key="warn-select"
					onHide={() => { this.setState({ showWarn: false }) }} />
			</div >);
	}
}

export default withRouter (Notifications);