import React, { Component } from 'react';
import CommonAnalysisChart from '../../common/CommonAnalysisChart';
import { colorUtlis } from '../../../utils/ColorUtlis';
import msg from '../../../texts/msg';
import { BlueTooltip } from '../../common/ColorTooltip';
export default class BorneAnalysis extends Component {

    render() {
        return (
        <div className={"p-grid"}>
            <AnalysisHeader headers={this.props.headers}/>
            <AnalysisContent contentHeaders={this.props.contentHeaders} charts={this.props.charts} title ={this.props.title}/>
        </div>
        );
    }

}

 class AnalysisHeader extends Component {

    render() {
        return (
        <div className={"p-col-12 p-grid analysis-header"}>
            <div className={"p-col-8"}>
                <span className="header-title"><h2>{this.props.headers.title}</h2></span>
            </div>
            <div className={"p-col-4"}></div>
            <div className={"p-col-12"}>                
                <span className="header-value">{this.props.headers.usage.value}</span>
                <span className="header-label">{' ' + this.props.headers.usage.label}</span>
            </div>
        </div>
        );
    }
}

class AnalysisContentHeader extends Component {

    render() {

        let contentHeaders = (this.props.contentHeaders && this.props.contentHeaders.length > 0) && this.props.contentHeaders.map((h, i) => {

           return ( 
                <div className={"p-col-6 p-grid"} key={i}>
                    <div className={"p-col-12 cheader-label"} style={{'flexDirection': 'row', 'display': 'flex', 'width': 'auto'}}>
                        {h.label}
                        {(h.label === msg('analysis.averageBasket') || h.label === msg('analysis.hp.averageBasket')) &&
                           <BlueTooltip title={msg('analysis.averageBasket.tooltip')} placement='right' arrow >
                               <span className="pi pi-info-circle " style={{ 'marginTop': '-5px', 'paddingLeft': '5px', 'color': 'rgb(75, 153, 211)', zIndex: 98 }}></span>
                           </BlueTooltip>
                       }
                   </div>

                    <div className={"p-col-12"}>
                        <span className={ h.unit? "average":""}> {h.value + ' ' + (h.unit? h.unit : '')}</span>
                    </div>
                </div>
            );
        });

        return (
            <div className={"p-col-12 p-grid content-header"}>
                {contentHeaders}
            </div>
        );
    }
}

class AnalysisContent extends Component {

    render() {
        let show = false;
        let charts = (this.props.charts && this.props.charts.length > 0) && this.props.charts.map((chart, i) => {
            if(!show){
                show = chart.data && chart.data.length > 0;
            }
            return <AnalysisChart type={chart.type} title={chart.title ? chart.title : this.props.title} data={chart.data} key={i}/>
        });

        return (
            <div className={"p-col-12"}>
                {show && (<AnalysisContentHeader contentHeaders={this.props.contentHeaders}/>)}
                {show && charts}
            </div>
        );
    }
}

class AnalysisChart extends Component {

    render() {
        let items = this.props.data ? this.props.data : [];
		let chartLabels = [];
        let chartData = [];
        let backgroundColorData=[];
		let lengthItemsArray=items.length;
		if (lengthItemsArray > 6) {
			let t = [];
			let i = 0;
			let m = 0.0;
			for (i = 0; i < lengthItemsArray; i++) {
				if (i < 5 || (i === 5 && lengthItemsArray === 6)) {
					t.push(items[i]);
				} else {
					m = m + items[i].value;
				}
			}
            t.push({ label: msg('analysis.other'), value: m, unit: " €" });
            items = t;
        }
        
        items.forEach((e,index) => {
            let value = (e.value  ? e.value.toFixed(2) : '0.00' );
            chartData.push(value); 
            chartLabels.push({label : e.label, value: value + (e.unit != null ? " " + e.unit : "")});
            let color = e.color ? msg(e.color) : colorUtlis.colorItemByLabel(e.label);
            if(index<5) {
                backgroundColorData.push( color !=null? color : colorUtlis.defaultColorsList[index%5] ) ;		
            } else {
                backgroundColorData.push(lengthItemsArray > 6 ? colorUtlis.defaultColorsList[6] : ( color !=null? color : colorUtlis.defaultColorsList[index ] ));
            } 
        });

        
		let chartModel = {
			labels: chartLabels,
			datasets: [
			{
				data: chartData,
                backgroundColor: backgroundColorData,
                hoverBackgroundColor: backgroundColorData
			}]
		};
  
		return (
            <CommonAnalysisChart type={this.props.type} chartModel={chartModel} title={this.props.title} className="p-col-12 analyse" />
        );
	}
}
