import { combineEpics } from 'redux-observable';

import { loginEpic, logoutEpic, redirectToProviderEpic, redirectToApiServerEpic, loadRoleFeaturesEpic } from './login';
import {
        loadFilterEpic, loadFilterStationsEpic, loadFilterStationsWithContractsEpic, loadSystemEventsFilterEpic,
        loadFilterExploitantsReportsEpic, loadFilterDodoStationsEpic
} from './filter';
import { commerceDashbordEpic, commerceAnalysisEpic, commerceExploitAnalysisEpic } from './commerce';
import {
        equipmentsEpic, equipmentByIdEpic, closeAlarmEpic, qrCodeSendByIdEpic, loadServiceInformationsEpic, loadOpenAlarmsEpic
} from './equipments';
import {
        adminequipmentsEpic, createEquipmentEpic, equipmentDeleteByIdEpic,
        equipmentUpdateEpic, equipmentEpic, updateBorneEpic
} from './adminequipments';
import { systemEventsEpic } from './server';
import {
        usersEpic, userEpic, addUserEpic, loadGroupsEpic, userDeleteByIdEpic,
        passwordUpdateEpic, userUpdateEpic, myPasswordUpdateEpic, generateBorneAccessCodeEpic
} from './user';
import {
        notificationsLoadEpic, notificationLoadByIdEpic, notificationSendEpic, notificationCreateEpic, notificationUpdateEpic, notificationDeleteByIdEpic,
        loadCampaignsEpic, createCampaignEpic, updateCampaignEpic, loadFirmwaresEpic, loadHardwaresEpic, loadPriceZonesEpic, loadStationEquipmentsEpic,
        loadCampaignEpic, launchCampaignEpic, deleteCampaignEpic, loadBornesStatusEpic, loadBornesAnalysisEpic, loadDefaultSubscribersEpic, loadCampaignEventsEpic
} from './maintenance';

import {
        loadStationsEpic, addStationEpic, stationUpdateEpic,
        stationDeleteByIdEpic, stationEpic, loadStationsMapEpic, updateConfigStationEpic, changeStationContractEpic, loadStationPriceZonesEpic, loadStationCommissionsEpic
} from './station';

import { groupsEpic, groupByIdEpic, addGroupEpic, groupDeleteByIdEpic, groupEpic, groupUpdateEpic } from './group';
import { pricezonesEpic, pricezoneByIdEpic, deletePricezoneEpic } from './pricezone';
import {
        authorisationsEpic, addRoleEpic, loadRoleEpic, rolesEpic, roleDeleteByIdEpic, roleEpic,
        roleUpdateEpic
} from './role';
import {
        loadCommissionsEpic, loadCommissionEpic, createCommissionEpic, updateCommissionEpic,
        deleteCommissionEpic
} from './commission';
import { configMaterielsEpic, configMaterielEpic, configMaterielDeleteByIdEpic } from './hardwares';
import {
        loadStationGroupsEpic, loadStationGroupEpic, loadStationGroupStationsEpic,
        createStationGroupEpic, updateStationGroupEpic, deleteStationGroupEpic
} from './stationgroup';
import {firmwareDeleteByIdEpic, firmwareEpic, firmwaresEpic} from './firmwares';
import { rebootBorneEpic, getBorneLogsEpic, loadBornesEpic, checkNetworkSpeedEpic, disassociateBorneEpic } from './borne';

export const rootEpic = combineEpics(
        loginEpic,
        logoutEpic,
        loadRoleFeaturesEpic,
        redirectToProviderEpic,
        redirectToApiServerEpic,
        commerceDashbordEpic,
        commerceAnalysisEpic,
        commerceExploitAnalysisEpic,
        loadFilterExploitantsReportsEpic,
        loadFilterEpic,
        loadFilterStationsEpic,
        loadFilterStationsWithContractsEpic,
        loadFilterDodoStationsEpic,
        loadSystemEventsFilterEpic,
        equipmentsEpic,
        equipmentByIdEpic,
        loadBornesEpic,
        disassociateBorneEpic,
        checkNetworkSpeedEpic,
        systemEventsEpic,
        notificationsLoadEpic,
        notificationLoadByIdEpic,
        notificationSendEpic,
        notificationCreateEpic,
        notificationUpdateEpic,
        notificationDeleteByIdEpic,
        loadDefaultSubscribersEpic,
        usersEpic,
        userEpic,
        addUserEpic,
        loadGroupsEpic,
        userDeleteByIdEpic,
        passwordUpdateEpic,
        myPasswordUpdateEpic,
        userUpdateEpic,
        generateBorneAccessCodeEpic,
        loadCampaignsEpic,
        loadCampaignEpic,
        loadFirmwaresEpic,
        loadHardwaresEpic,
        loadPriceZonesEpic,
        loadStationEquipmentsEpic,
        loadCampaignEventsEpic,
        createCampaignEpic,
        updateCampaignEpic,
        launchCampaignEpic,
        deleteCampaignEpic,
        loadCommissionsEpic,
        loadCommissionEpic,
        createCommissionEpic,
        updateCommissionEpic,
        deleteCommissionEpic,
        groupsEpic,
        groupByIdEpic,
        authorisationsEpic,
        addRoleEpic,
        closeAlarmEpic,
        loadRoleEpic,
        addGroupEpic,
        groupEpic,
        groupUpdateEpic,
        groupDeleteByIdEpic,
        rolesEpic,
        roleDeleteByIdEpic,
        roleEpic,
        roleUpdateEpic,
        groupDeleteByIdEpic,
        pricezonesEpic,
        pricezoneByIdEpic,
        deletePricezoneEpic,
        adminequipmentsEpic,
        createEquipmentEpic,
        equipmentDeleteByIdEpic,
        loadBornesStatusEpic,
        loadBornesAnalysisEpic,
        equipmentUpdateEpic,
        equipmentEpic,
        loadStationsMapEpic,
        loadStationsEpic,
        loadStationPriceZonesEpic,
        loadStationCommissionsEpic,
        loadStationGroupsEpic,
        loadStationGroupEpic,
        loadStationGroupStationsEpic,
        createStationGroupEpic,
        updateStationGroupEpic,
        deleteStationGroupEpic,
        configMaterielsEpic,
        configMaterielEpic,
        configMaterielDeleteByIdEpic,
        addStationEpic,
        stationUpdateEpic,
        changeStationContractEpic,
        stationDeleteByIdEpic,
        stationEpic,
        updateBorneEpic,
        firmwaresEpic,
        firmwareEpic,
        firmwareDeleteByIdEpic,
        rebootBorneEpic,
        getBorneLogsEpic,
        qrCodeSendByIdEpic,
        loadServiceInformationsEpic,
        loadOpenAlarmsEpic,
        updateConfigStationEpic
);
