import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import PasswordUpdate from './PasswordUpdate';
import TableData from '../../../utils/TableData';
import { rolesValidator, USERS, READ, CREATE, UPDATE, DELETE, ADMINISTRATION } from '../../../utils/RolesValidator';
import { userSessionParamsHandler } from '../../../utils/UserSessionParamsHandler';
import MessageDialog from '../../common/MessageDialog';
import msg from '../../../texts/msg';
import '../../common/css/common.css';
import './User.css';
import UploadService from "../../../middleware/download-upload-files.service"
import { toLocaleDateTimeFormat } from '../../../utils/dateTimeUtils';
import { progressSpinnerHandler } from '../../../utils/ProgressSpinnerHandler'

const emtyChar = '';

export class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastChangeDateFile: 0,
            totalRejectedUsers: 0,
            totalAcceptedUsers: 0,
            isImportUsersFinish: false,
            sessionExpired: false,
            message: "",
            first: 0,
            buttonSelectd: false,
            display: false,
            closable: false,
            isLignSelected: false,
            isApiError: false,
            userId: null,
            userExist: false,
            keyword: null,
            userPassword: {
                id: null,
                password: emtyChar,
                passwordConfirm: emtyChar
            },
            userFilter: {},
            ssoUser: false,
            rechecheObject: {
                idSearch: null,
                idValid: false,
            },
            sortProprety: { 'key': 'name', 'value': "ASC" },
            sortDirection: "ASC",
            isFileInValid: false
        }

        this.loadUsers = this.loadUsers.bind(this);
        this.onSelection = this.onSelection.bind(this);
        this.onSort = this.onSort.bind(this);        
        this.update = this.update.bind(this);
        this.displayed = this.displayed.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.cancelUpdatePassword = this.cancelUpdatePassword.bind(this);
        this.onHide = this.onHide.bind(this);
        this.searcheByKeyword = this.searcheByKeyword.bind(this);
        this.login = this.login.bind(this);
        this.profile = this.profile.bind(this);
        this.email = this.email.bind(this);
        this.name = this.name.bind(this);
        this.lastConnectionDate = this.lastConnectionDate.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.handleFinishImport = this.handleFinishImport.bind(this);
        this.buildErrDialogParam= this.buildErrDialogParam.bind(this);
        this.reinitParameters = this.reinitParameters.bind(this);
    }

    componentDidMount() {
        this.loadUsers(0, userSessionParamsHandler.getPageSize(), this.state.userFilter);
        this.props.setDataInfoBar(null);
    }

    componentDidUpdate(prevProps) {
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            progressSpinnerHandler.show(false);
            let isError = this.props.error.message && this.props.error.message.startsWith('error.users.') ? true : false;
            this.setState({ isApiError: isError, message : this.props.error.message });
        }
        if(this.props.users && this.props.users !== prevProps.users){
                progressSpinnerHandler.show(false);
        }
        let prevDelete = prevProps.userDeleteById && prevProps.userDeleteById.lastChange;
        let lastDelete = this.props.userDeleteById && this.props.userDeleteById.lastChange;
        let reload = (lastDelete && (!prevDelete || prevDelete < lastDelete));
        let prevUser = prevProps.users && prevProps.users.lastChange;
        let lastUser = this.props.users && this.props.users.lastChange;
        if (reload) {
            this.loadUsers(0, userSessionParamsHandler.getPageSize(), this.state.userFilter);
        }

        if ((lastUser && (!prevUser || prevUser < lastUser))) {
            if (this.props.users.users.length === 0) {
                this.setState({ userExist: true });
            }
        }
    }

    searcheByKeyword(rechecheObject) {
        progressSpinnerHandler.show(true);
        let lFilter = Object.assign({}, this.state.userFilter);
        this.setState({ rechecheObject: rechecheObject })
        let keyword = rechecheObject.idSearch ? rechecheObject.idSearch : this.state.keyword;
        lFilter.label = keyword
        this.loadUsers(0, userSessionParamsHandler.getPageSize(),lFilter);
    }

    loadUsers(page, pageSize, filter) {
        progressSpinnerHandler.show(true);
        filter.page = page;
        filter.pageSize = pageSize
        this.props.loadUsers(page, pageSize, filter);
        this.setState({ userFilter: filter });
    }

    onSelection(event) {
        if (event.value) {
            this.setState({ userId: event.value.id, username: event.value.name });
            this.setState({ buttonSelectd: true });
            this.setState({ ssoUser: event.value.authProvider && event.value.authProvider.toLowerCase() !== "undefined" &&  event.value.authProvider.toLowerCase() !== "washadmin" });
        }
    }

    isenabled(rowData) {
        return <Checkbox checked={rowData.enabled} />;
    }

    isBorneAccessEnabled(rowData) {
        return <Checkbox checked={rowData.borneAccessEnabled} />;
    }

    login(rowData) {

        let login = rowData.login ? rowData.login : "";
        if (login != null && login.length > 25)
            return <div className="hasTooltip p-ellipsis"> {login} <span> {login}  </span></div>;
        else
            return <div> {login} </div>

    }

    profile(rowData) {
        let profile = rowData.profile ? rowData.profile : "";
        if (profile != null && profile.length > 25)
            return <div className="hasTooltip p-ellipsis"> {profile} {rowData.groupAdmin ? '*' : '' } <span> {profile} {rowData.groupAdmin ? (<span className="obligatoire">(ADMIN)</span>) : '' } </span></div>;
        else
            return <div> {profile} {rowData.groupAdmin ? (<span className="obligatoire">(ADMIN)</span>)  : '' }</div>
    }

    email(rowData) {
        let email = rowData.email ? rowData.email : "";
        if (email != null && email.length > 25)
            return <div className="hasTooltip p-ellipsis"> {email} <span> {email}  </span></div>;
        else
            return <div> {email} </div>

    }

    name(rowData) {
        let name = rowData.name ? rowData.name : "";
        if (name != null && name.length > 15)
            return <div className="hasTooltip p-ellipsis"> {name} <span> {name}  </span></div>;
        else
            return <div> {name} </div>
    }

    lastConnectionDate(rowData) {
        let lastConnectionDate = toLocaleDateTimeFormat(rowData.lastCommonConnectionDate);
        if (lastConnectionDate != null && lastConnectionDate.length > 15)
            return <div className="hasTooltip p-ellipsis"> {lastConnectionDate} <span> {lastConnectionDate}  </span></div>;
        else
            return <div> {lastConnectionDate} </div>
    }

    reinitParameters(e){
        this.setState({ isFileInValid: false, message: null,isError: false, sessionExpired : false,isLignSelected: false, isApiError: false })
    }

    handleFinishImport(e) {
        this.setState({isImportUsersFinish: false, totalRejectedUsers: 0, totalAcceptedUsers :0, lastChangeDateFile:0});
    }

    onFileChange(e) {
     let valid = true;
     let file = e.files[0];
     let name = file ? file.name : emtyChar;
     let pos = name.lastIndexOf(".") ;
     //size in bytes
     if( (name === emtyChar) || (pos < 1)  || !file || (file.size > this.props.uploadMaxFileSize*1000000) ){
        this.setState({ isFileInValid: true });
        valid = false;
        return;
     }else {
        this.setState({ isFileInValid: false });
     }

     if(valid){
        UploadService.uploadImportUsersFile(file).then((response) => {
            this.setState({ file: undefined, isImportUsersFinish : true, totalAcceptedUsers : response.data.listAcceptedUsers.length, 
                totalRejectedUsers : response.data.listRejectedUsers.length, lastChangeDateFile: response.data.lastChangeDateFile
               });
        }).catch((error) => {
            if (error.response && (error.response.status === "401" || error.response.status === 401)) {
                this.setState({ sessionExpired: true });
            } else {
                this.setState({ isError: true,  message: error.response ? error.response.data.message : "Unknown error" });
            }
        });
     }
    }

    displayed() {
        if (this.state.userId) {
            this.setState({ display: true });
        } else {
            this.setState({ isLignSelected: true });
        }
    }

    update() {
        if (this.state.userId && this.state.buttonSelectd) {
            this.props.loadUser(this.state.userId);
            this.setState({ buttonSelectd: false });
            this.props.history.push('/updateUser');
        } else {
            this.setState({ isLignSelected: true });
        }
    }

    deleteUser() {
        if (this.state.userId) {
            this.setState({ showConfirm: true });
        } else {
            this.setState({ isLignSelected: true });
        }
    }

    confirmDeleteUser() {
        if (this.state.userId) {
            this.props.dUser(this.state.userId);
            this.setState({ buttonSelectd: false });
        } else {
            this.setState({ isLignSelected: true });
        }
        this.setState({ buttonSelectd: false });
    }

    updatePassword(userPassword) {
        this.setState({ userPassword: userPassword });
        this.props.updatePassword(this.state.userId, userPassword);
        this.props.history.push('/users');
        this.setState({ display: false });
    }

    cancelUpdatePassword(userPassword) {
        this.setState({ userPassword: userPassword });
        this.props.history.push('/users');
        this.setState({ display: false });
    }

    onHide(name) {
        this.setState({
            [`${name}`]: false
        });
        this.setState({ isLignSelected: false })
    }

    renderFooter() {
        return (
            <div>
                <Button label="Ok" icon="pi pi-check" onClick={() => this.setState({ isLignSelected: false, isApiError: false })} />
            </div>
        );
    }

    userFooter() {
        return (
            <div>
                <Button label="Ok" icon="pi pi-check" onClick={() => this.setState({ userExist: false })} />
            </div>
        );
    }

    onSort = (e, page) => {
        progressSpinnerHandler.show(true);
        let userFilter = Object.assign({}, this.state.userFilter);
        let sortDirection = this.state.sortDirection === "ASC" ? "DESC" : "ASC";
        userFilter.sortProperty = {key: e.sortField, value: sortDirection};
        userFilter.label = this.state.rechecheObject ? this.state.rechecheObject.idSearch : "";
        this.props.loadUsers(page, userSessionParamsHandler.getPageSize(), userFilter);
        this.setState({ sortDirection: sortDirection, userFilter: userFilter });
    }

    buildErrDialogParam(){
        let parameters = {
            visibility: false,
            title: emtyChar,
            message:emtyChar
        }
        if (this.state.isFileInValid){
            parameters.visibility = this.state.isFileInValid;
            parameters.title = msg('error.users.import.file.dialogue.title');
            parameters.message = msg("error.users.import.file.dialogue.features.required", {size: this.props.uploadMaxFileSize});
        } else if (this.state.isError){
            parameters.visibility = this.state.isError;
            parameters.title = msg('error.users.import.file.dialogue.title')
            parameters.message = this.state.message;
        } else if(this.state.isApiError){
            parameters.visibility = this.state.isApiError;
            parameters.title = msg('message.dialog.error.title')
            parameters.message = this.props.error && msg(this.props.error.message);
        } else if(this.state.sessionExpired) {
            parameters.visibility = this.state.sessionExpired;
            parameters.title = msg('firmware.fields.upload.title.error')
            parameters.message = msg("login.unauthorized.internalauthenticationserviceexception");
        }
        return parameters
    }
    render() {
        let parameters = this.buildErrDialogParam();
        let authorized = rolesValidator.validate([USERS]);
        let cAuthorized = authorized.toString().includes(CREATE);
        let rAuthorized = authorized.toString().includes(READ);
        let uAuthorized = authorized.toString().includes(UPDATE);
        let dAuthorized = authorized.toString().includes(DELETE); 
        let isAdminGroup = (this.props.adminUser && this.props.adminUser.groupAdmin) || rolesValidator.validate([ADMINISTRATION]);

        let usersColumns = [
            { isColWithBody: false, field: "id", header: "id", sortable: true, className: "visibilityHidden" },
            { isColWithBody: true, field: "name", header: msg('user.name'), body: this.name, sortable: true, className: "p-col-3" },
            { isColWithBody: true, field: "login", header: msg('user.login'), body: this.login, sortable: true, className: "p-col-2" },
            { isColWithBody: true, field: "email", header: msg('user.email'), body: this.email, sortable: true, className: "p-col-3" },
            { isColWithBody: true, field: "profile", header: msg('user.profile'), body: this.profile, sortable: true, className: "p-col-3" },
            { isColWithBody: true, field: "lastCommonConnectionDate", header: msg('user.lastConnection'), body: this.lastConnectionDate, sortable: true, className: "p-col-2" },
            { isColWithBody: true, field: "enabled", header: msg('user.enabled'), body: this.isenabled, sortable: false, className: "p-col-1" },
            { isColWithBody: true, field: "borneAccessEnabled", header: msg('user.borneAccessEnabled'), body: this.isBorneAccessEnabled, sortable: false, className: "p-col-1" }
        ];

        let usersOutilsBar = [
            { filename: msg('user.list.xlsx'), iconSrc: "/assets/ultima/layout/images/cartadis/excel.svg", titleIcone: msg('user.list'), tooltipTextIcone: msg('user.export'), urlExport: "users/users-excel" }
        ]

        let lignSelected = (
            <MessageDialog className={'message-dialog warn-message'} visible={this.state.isLignSelected} title={msg('message.dialog.warn.title')}
                message={this.state.isLignSelected && msg('user.message.when.not.selected.colomn')} key="warn-select"
                onHide={() => { this.setState({ isLignSelected: false }) }} />
        );

        let iniPassword = (
            <Dialog visible={this.state.display && uAuthorized} style={{ width: '80%' }} closable={this.state.closable} onHide={() => this.onHide('display')}
                modal={false}>
                {this.state.display && uAuthorized && <PasswordUpdate username={this.state.username} apply={this.updatePassword} cancel={this.cancelUpdatePassword} showDate={true} />}
            </Dialog>
        );
        
        let users = this.props.users && this.props.users.users ? this.props.users.users : [];
        let totalPages = this.props.users && this.props.users.users ? this.props.users.totalPages : 0;
        let ContextMenuContent = this.state.userId && [
            {
              label: msg('user.update'),
              command: this.update
            },
            {
              label: msg('user.delete'),
              command: this.deleteUser
            },
            {
              label: msg('user.initialise.password'),
              command: this.displayed
            }];
            let ContextMenuContentForSSOUser = this.state.userId && this.state.ssoUser && ContextMenuContent.slice(0, ContextMenuContent.length -1);
        let usersTable = (
            <TableData data={users} titleTable={msg('user.table.title')} totalPages={totalPages} ContextMenuContent={this.state.ssoUser ? ContextMenuContentForSSOUser : ContextMenuContent}
                selectionMode={"single"} selection={this.state.rowSelection}
                onSelection={this.onSelection}
                getDataFunction={this.loadUsers} paramnFunctionData={null}
                outilsBar={usersOutilsBar}
                filter={this.state.userFilter}
                columns={usersColumns}
                searchInTable={false} searchByName={true} nameTxtPlaceholder={msg('user.search.by.name')}
                apply={this.searcheByKeyword} 
                onSort={this.onSort}
                />);
        return rAuthorized && (
            <div className="p-grid card commonClass">
                <div className="p-col-12">
                    <div className="p-col-12" style={{ display: 'flex' }}>
                        <div className="p-col-2 p-md-4 item">
                            <div className="p-col-12"><h2 className="title">{msg('user.title')}</h2></div>
                        </div>
                        <div className="p-col-10 item" style={{ marginLeft: '-10%' }}>
                            <div className={this.state.buttonSelectd ? "btnSelected item" : "btnNotSelected item "} style={{ marginLeft: '-5px' }}>
                                <Button label={(msg('user.update'))} title={(msg('user.update'))} onClick={this.update} key="update" disabled={!(uAuthorized || rAuthorized)} />
                            </div>
                            <div className={this.state.buttonSelectd ? "btnSelected item " : "btnNotSelected item"} >
                                <Button label={(msg('user.delete'))} title={(msg('user.delete'))} onClick={() => { this.deleteUser() }} key="delete" disabled={!dAuthorized || !isAdminGroup} />
                            </div>
                            <div className={this.state.buttonSelectd ? "btnSelected item" : "btnNotSelected item "} >
                                <Button label={(msg('user.initialise.password'))} title={(msg('user.initialise.password'))} onClick={this.displayed} key="password" 
                                    disabled={!uAuthorized || this.state.ssoUser || !isAdminGroup} />
                            </div>
                            <div className="item btnSelected">
                                <Link to="/creationUser">
                                    <Button label={(msg('user.create.user'))} title={(msg('user.create.user'))} className="button" key="create" disabled={!cAuthorized || !isAdminGroup} />
                                </Link>
                            </div>
                            <div className="item btnSelected" style={{ marginTop: '4px' }}>
                                <FileUpload auto mode="basic" chooseLabel={msg('user.import.users.btnlabel')}
                                    onSelect={this.onFileChange} accept=".xlsx"
                                    maxFileSize={this.props.uploadImportUsersFile} emptyTemplate={<p className="p-m-0"></p>} className="button" disabled={!cAuthorized || !isAdminGroup} />
                            </div>
                        </div>
                    </div>
                    <MessageDialog className={'message-dialog error-message'} visible={parameters.visibility} title={parameters.title}
                        message={parameters.message}
                        onHide={e => this.reinitParameters(e)}  onCancel={e => this.reinitParameters(e)}/>

                    <MessageDialog className={(this.state.totalRejectedUsers === 0) ? 'message-dialog info-message' : 'message-dialog warn-message'  }
                    visible={this.state.isImportUsersFinish} title={msg('users.import.finish.title')}
                        message={(this.state.totalRejectedUsers > 0) ? msg( "users.import.partial.finish.texte",{totalAcceptedUsers: this.state.totalAcceptedUsers, totalRejectedUsers: this.state.totalRejectedUsers})
                        : msg( "users.import.success.finish.texte")}
                        onHide={e => this.handleFinishImport(e)} onCancel={e => this.handleFinishImport(e)}  loadFile={this.state.totalRejectedUsers > 0 ? true : false}
                        lastChangeDateFile= {this.state.totalRejectedUsers > 0 ? this.state.lastChangeDateFile : 0}/>

                    <MessageDialog confirm={true} className={'message-dialog confirm-message'} visible={this.state.showConfirm} title={msg('message.dialog.confirm.title')}
                        message={this.state.showConfirm && msg('user.delete.confirm.message', { username: this.state.username })} key="confirm-delete"
                        onConfirm={() => { this.setState({ showConfirm: false }); this.confirmDeleteUser() }} onCancel={() => this.setState({ showConfirm: false })} />
                   
                    {iniPassword}
                    {usersTable}
                    {lignSelected}
                </div>
            </div >);
    }
}

