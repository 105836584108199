export const NOTIFICATIONS_LOAD ='NOTIFICATIONS_LOAD';
export const NOTIFICATIONS_LOAD_SUCCESS = 'NOTIFICATIONS_LOAD_SUCCESS';
export const NOTIFICATION_LOAD_BY_ID = 'NOTIFICATION_LOAD_BY_ID';
export const NOTIFICATION_LOAD_BY_ID_SUCCESS = 'NOTIFICATION_LOAD_BY_ID_SUCCESS';
export const NOTIFICATION_DELETE_BY_ID = 'NOTIFICATION_DELETE_BY_ID';
export const NOTIFICATION_DELETE_BY_ID_SUCCESS = 'NOTIFICATION_DELETE_BY_ID_SUCCESS';
export const NOTIFICATION_CREATE ='NOTIFICATION_CREATE';
export const NOTIFICATION_CREATE_SUCCESS = 'NOTIFICATION_CREATE_SUCCESS';
export const NOTIFICATION_UPDATE ='NOTIFICATION_UPDATE';
export const NOTIFICATION_UPDATE_SUCCESS = 'NOTIFICATION_UPDATE_SUCCESS';
export const LOAD_STATION = "LOAD_STATION";
export const LOAD_STATION_SUCCESS = "LOAD_STATION_SUCCESS";
export const NOTIFICATION_SEND = 'NOTIFICATION_SEND';
export const NOTIFICATION_SEND_SUCCESS = 'NOTIFICATION_SEND_SUCCESS';
export const NOTIFICATION_SEND_BY_ID = 'NOTIFICATION_SEND_BY_ID';
export const NOTIFICATION_SEND_BY_ID_SUCCESS = 'NOTIFICATION_SEND_BY_ID_SUCCESS';
export const LOAD_CAMPAIGNS = 'LOAD_CAMPAIGNS';
export const LOAD_CAMPAIGNS_SUCCESS = 'LOAD_CAMPAIGNS_SUCCESS';
export const LOAD_CAMPAIGN = 'LOAD_CAMPAIGN';
export const LOAD_CAMPAIGN_SUCCESS = 'LOAD_CAMPAIGN_SUCCESS';
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS';
export const LAUNCH_CAMPAIGN = 'LAUNCH_CAMPAIGN';
export const LAUNCH_CAMPAIGN_SUCCESS = 'LAUNCH_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
export const LOAD_FIRMWARES = 'LOAD_FIRMWARES';
export const LOAD_FIRMWARES_SUCCESS = 'LOAD_FIRMWARES_SUCCESS';
export const LOAD_HARDWARES = 'LOAD_HARDWARES';
export const LOAD_HARDWARES_SUCCESS = 'LOAD_HARDWARES_SUCCESS';
export const LOAD_PRICE_ZONES = 'LOAD_PRICE_ZONES';
export const LOAD_PRICE_ZONES_SUCCESS = 'LOAD_PRICE_ZONES_SUCCESS';
export const LOAD_STATION_EQUIPMENTS = 'LOAD_STATION_EQUIPMENTS';
export const LOAD_STATION_EQUIPMENTS_SUCCESS = 'LOAD_STATION_EQUIPMENTS_SUCCESS';
export const LOAD_CAMPAIGN_EVENTS = 'LOAD_CAMPAIGN_EVENTS';
export const LOAD_CAMPAIGN_EVENTS_SUCCESS = 'LOAD_CAMPAIGN_EVENTS_SUCCESS';
export const LOAD_BORNES_STATUS = 'LOAD_BORNES_STATUS';
export const LOAD_BORNES_STATUS_SUCCESS = 'LOAD_BORNES_STATUS_SUCCESS';
export const LOAD_BORNES_ANALYSIS = 'LOAD_BORNES_ANALYSIS';
export const LOAD_BORNES_ANALYSIS_SUCCESS = 'LOAD_BORNES_ANALYSIS_SUCCESS';
export const LOAD_DEFAULT_SUBSCRIBERS = 'LOAD_DEFAULT_SUBSCRIBERS';
export const LOAD_DEFAULT_SUBSCRIBERS_SUCCESS = 'LOAD_DEFAULT_SUBSCRIBERS_SUCCESS';

export const notificationsLoad = (page,sizePage, idSearch, notificationType) => {
	return ({
		type: NOTIFICATIONS_LOAD,
		data: {page: page,  sizePage : sizePage, idSearch : idSearch, notificationType},
		errorMessage: null,
		errorStatus: null
	});
};

export const notificationsLoadSuccess = (r) => {
	return ({
		type: NOTIFICATIONS_LOAD_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const notificationLoadById = (notificationId) => { 
	return ({
		type: NOTIFICATION_LOAD_BY_ID,
		data: {notificationId: notificationId},
		errorMessage: null,
		errorStatus: null
	});
};
export const notificationLoadByIdSuccess = (r) => {
	return ({
		type: NOTIFICATION_LOAD_BY_ID_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const notificationDeleteById = (notificationDeleteId) => { 
	return ({
		type: NOTIFICATION_DELETE_BY_ID,
		data: {notificationDeleteId: notificationDeleteId},
		errorMessage: null,
		errorStatus: null
	});
};

export const notificationDeleteByIdSuccess = (r) => {
	return ({
		type: NOTIFICATION_DELETE_BY_ID_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const notificationCreate = (abonnement) => { 
	return ({
		type: NOTIFICATION_CREATE,
		data: {abonnement: abonnement},
		errorMessage: null,
		errorStatus: null
	});
};

export const notificationCreateSuccess = (r) => {
	return ({
		type: NOTIFICATION_CREATE_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const notificationUpdate = (notificationId, abonnement) => { 
	return ({
		type: NOTIFICATION_UPDATE,
		data: {notificationId:notificationId, abonnement: abonnement},
		errorMessage: null,
		errorStatus: null
	});
};

export const notificationUpdateSuccess = (r) => {
	return ({
		type: NOTIFICATION_UPDATE_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const notificationSend = (notificationId, notification) => { 
	return ({
		type: NOTIFICATION_SEND,
		data: {notificationId: notificationId, notification: notification},
		errorMessage: null,
		errorStatus: null
	});
};

export const notificationSendSuccess = (r) => {
	return ({
		type: NOTIFICATION_SEND_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadDefaultSubscribers = (notificationType, isEventBorne) => { 
	return ({
		type: LOAD_DEFAULT_SUBSCRIBERS, 
		data: {notificationType: notificationType, isEventBorne: isEventBorne},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadDefaultSubscribersSuccess = (r) => {
	return ({
		type: LOAD_DEFAULT_SUBSCRIBERS_SUCCESS,
		data: r.response ,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadCampaigns = (page, pageSize) => {
	return ( {
		type : LOAD_CAMPAIGNS,
		data : {page: page, pageSize: pageSize},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadCampaignsSuccess = (r) => {
	return ( {
		type : LOAD_CAMPAIGNS_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadCampaign = (campaignId, planning) => {
	return ( {
		type : LOAD_CAMPAIGN,
		data : {campaignId: campaignId, planning: planning},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadCampaignSuccess = (r) => {
	return ( {
		type : LOAD_CAMPAIGN_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const createCampaign = (campaign) => {
	return ( {
		type : CREATE_CAMPAIGN,
		data : {campaign: campaign},
		errorMessage: null,
		errorStatus: null
	});
};

export const createCampaignSuccess = (r) => {
	return ( {
		type : CREATE_CAMPAIGN_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const updateCampaign = (campaign) => {
	return ( {
		type : UPDATE_CAMPAIGN,
		data : {campaign: campaign},
		errorMessage: null,
		errorStatus: null
	});
};

export const updateCampaignSuccess = (r) => {
	return ( {
		type : UPDATE_CAMPAIGN_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const launchCampaign = (campaign) => {
	return ( {
		type : LAUNCH_CAMPAIGN,
		data : {campaign: campaign},
		errorMessage: null,
		errorStatus: null
	});
};

export const launchCampaignSuccess = (r) => {
	return ( {
		type : LAUNCH_CAMPAIGN_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const deleteCampaign = (campaignId) => {
	return ( {
		type : DELETE_CAMPAIGN,
		data : {campaignId: campaignId},
		errorMessage: null,
		errorStatus: null
	});
};

export const deleteCampaignSuccess = (r) => {
	return ( {
		type : DELETE_CAMPAIGN_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadFirmwares = (borneType) => {
	return ( {
		type : LOAD_FIRMWARES,
		data : {borneType: borneType},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadFirmwaresSuccess = (r) => {
	return ( {
		type : LOAD_FIRMWARES_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadHardwares = (borneType) => {
	return ( {
		type : LOAD_HARDWARES,
		data : {borneType: borneType},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadHardwaresSuccess = (r) => {
	return ( {
		type : LOAD_HARDWARES_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadPriceZones = () => {
	return ( {
		type : LOAD_PRICE_ZONES,
		data : {},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadPriceZonesSuccess = (r) => {
	return ( {
		type : LOAD_PRICE_ZONES_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadStationEquipments = (campaignId, borneType, stationLevel, planning, filter, page, pageSize) => {
	return ( {
		type : LOAD_STATION_EQUIPMENTS,
		data : {
				campaignId: campaignId, 
				borneType: borneType, 
				stationLevel: stationLevel, 
				planning: planning,
				station: filter && filter.station ? filter.station : '',
				firmware: filter && filter.firmware && filter.firmware.key ? filter.firmware.key : '',
				hardware: filter && filter.hardware && filter.hardware.key ? filter.hardware.key : '',
				priceZone: filter && filter.priceZone && filter.priceZone.key ? filter.priceZone.key : '',
				page: page,
				pageSize: pageSize
			},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadStationEquipmentsSuccess = (r) => {
	return ( {
		type : LOAD_STATION_EQUIPMENTS_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadCampaignEvents = (campaignId) => {
	return ( {
		type : LOAD_CAMPAIGN_EVENTS,
		data : {campaignId: campaignId},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadCampaignEventsSuccess = (r) => {
	return ( {
		type : LOAD_CAMPAIGN_EVENTS_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadBornesStatus = (filter) => {
	return ( {
		type: LOAD_BORNES_STATUS,
		data: {filter: filter},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadBornesStatusSuccess = (r) => {
	return ( {
		type : LOAD_BORNES_STATUS_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadBornesAnalysis = (filter) => {
	return ( {
		type: LOAD_BORNES_ANALYSIS,
		data: {filter: filter},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadBornesAnalysisSuccess = (r) => {
	return ( {
		type : LOAD_BORNES_ANALYSIS_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

